import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LiveAnnouncer } from "@angular/cdk/a11y";

import { Sensor } from "src/app/_shared/models/sensor";
import { LotGetModel } from "src/app/_shared/models/lot";
import { LotSensors } from "src/app/_shared/models/lot-sensors";

import { AuthenticationService } from "src/app/_services/authentication.service";
import { ParkingService } from "src/app/_services/parking.service";
import { SensorService } from "src/app/_services/sensor.service";
import { TypeService } from "src/app/_services/type.service";
import { LotService } from "src/app/_services/lot.service";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import * as XLSX from 'xlsx';

@Component({
  selector: "app-sensor-status",
  templateUrl: "./sensor-status.component.html",
  styleUrls: ["./sensor-status.component.scss"],
})
export class SensorStatusComponent implements OnInit {
  
  @Input() parkingId: string;
  @Input() parkingName: string;
  features: any[];
  id: any;
  lots: any;
  display = false;
  LotsSensorsDataSource: any;
  status: string[];
  tags: string[];
  data: string;
  sensor: string;
  lotsSensors: LotSensors[];
  maxItemPaginator: Number = 100;
  isAdmin: boolean;
  displayedColumns: string[] = ['id', 'status', 'type', 'authlevel',
    'timestamp','installation_timestamp', 'sensor_id', 'tags', 'firmware', 'rssi',
    'battery_level'];
  dataSource = new MatTableDataSource();
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  lot: string;
  Lots: LotGetModel[];
  sensorsInfo: any;
  lotsInfo: any;
  
  constructor(
    public http: HttpClient,
    private parkingService: ParkingService,
    public auth: AuthenticationService,
    public types: TypeService,
    public sensorService: SensorService, // Injecting the Services
    public lotService: LotService,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    this.features = [];
  }
  
  ngOnDestroy() {
    console.log("unsuscribe polling");
  }
  
  async ngOnInit() {
    
    await this.parkingService.retriveLotsInfo(this.parkingId).subscribe((lots: any) => {
      this.lotsSensors = lots;
      this.lotsSensors = this.lotsSensors.filter(lot => lot.sensor_id !== '');
      if(lots.length < 100) {
        this.maxItemPaginator = lots.length;
      }
    });
    
    await this.sensorService.retriveSensorInfo(this.parkingId)
      .then((data: any) => {
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          let index = this.lotsSensors.findIndex(
            (sensorElement) => sensorElement.sensor_id === data[i].id
          );
          if (index === -1) {
            // Handle sensor not assigned to any lot
            data[i].sensor_id = data[i].id;
            delete data[i].id;
            let lotNoSensor = data[i];
            this.lotsSensors.push(lotNoSensor);
          } else {
            this.lotsSensors[index].tags = data[i].tags;
            this.lotsSensors[index].firmware = data[i].firmware;
            this.lotsSensors[index].rssi = data[i].rssi;
            this.lotsSensors[index].battery_level = data[i].battery_level;
          }
        }
        for (let i=0; i<this.lotsSensors.length; i++) {
          delete this.lotsSensors[i].location;
          delete this.lotsSensors[i].area;
          delete this.lotsSensors[i].groups;
          delete this.lotsSensors[i].booked;
          delete this.lotsSensors[i].x;
          delete this.lotsSensors[i].y;
          delete this.lotsSensors[i].config_status;
          delete this.lotsSensors[i].partner_id;
          delete this.lotsSensors[i].has_access;
          delete this.lotsSensors[i].temperature;
          delete this.lotsSensors[i].datarate;
          delete this.lotsSensors[i].app_eui;
          delete this.lotsSensors[i].app_key;
          delete this.lotsSensors[i].temp_requested;
          delete this.lotsSensors[i].datarate_set;
          delete this.lotsSensors[i].join_timestamp;
          delete this.lotsSensors[i].sum_temperature;
          delete this.lotsSensors[i].sum_battery_level;
          delete this.lotsSensors[i].temperature_measurements;
          delete this.lotsSensors[i].battery_level_measurements;
          delete this.lotsSensors[i].temperature_transaction_count;
          delete this.lotsSensors[i].battery_level_transaction_count;
          delete this.lotsSensors[i].counter_up;
        }
        this.LotsSensorsDataSource = new MatTableDataSource(this.lotsSensors);
        this.LotsSensorsDataSource.paginator = this.paginator;
        this.LotsSensorsDataSource.sort = this.sort;
      });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.LotsSensorsDataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.LotsSensorsDataSource.paginator) {
      this.LotsSensorsDataSource.paginator.firstPage();
    }
    
  }
  
  statusStyle(status) {
    let res = { color: "red" };
    switch (status) {
      case "free": {
        res.color = '#70BF54';
        break;
      }
      case "busy": {
        res.color = '#E11F26';
        break;
      }
      case "alert": {
        res.color = '#FCAF17';
        break;
      }
      default: {
        res.color = '#BFC0C2';
        break;
      }
    }
    return res;
  }
  
  exportAsExcel() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.LotsSensorsDataSource.data, {header: ['id', 'status', 'type', 'authlevel', 'timestamp','installation_timestamp', 'sensor_id', 'tags', 'firmware', 'rssi', 'battery_level']});
    delete ws['A1'].w; ws['A1'].v = "Parking Space ID";
    delete ws['B1'].w; ws['B1'].v = "Status";
    delete ws['C1'].w; ws['C1'].v = "Type";
    delete ws['D1'].w; ws['D1'].v = "Authlevel";
    delete ws['E1'].w; ws['E1'].v = "Last activity";
    delete ws['F1'].w; ws['F1'].v = "Installation";
    delete ws['G1'].w; ws['G1'].v = "Sensor ID";
    delete ws['H1'].w; ws['H1'].v = "Tags";
    delete ws['I1'].w; ws['I1'].v = "Firmware";
    delete ws['J1'].w; ws['J1'].v = "Rssi";
    delete ws['K1'].w; ws['K1'].v = "Battery Level";
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, yyyy + "-" + mm + "-" + dd + " " + this.parkingId + ' Sensor Status ' + '.xlsx');
    
  }
  
}