import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

import { ImageInfo } from 'src/app/_shared/models/image';

import { ParkingService } from 'src/app/_services/parking.service';
import { ImagesService } from 'src/app/_services/images.service';

@Component({
  selector: 'app-section-info',
  templateUrl: './section-info.component.html',
  styleUrls: ['./section-info.component.scss']
})
export class SectionInfoComponent implements OnInit {
  
  id: any;
  parsubs: any;
  subs: Subscription;
  section: any;
  lots: any;
  info: ImageInfo;
  parkSpaces: any = [];
  list: any;
  parkspaces: any = [];
  parkinfo: any;
  displaymap: boolean = true;
  parkSpaceY: any;
  display: boolean = false;
  parkImagesId: string;
  @Input() parking_id: string
  @Input() sectionName: any;
  
  currentItemsToShow: any;
  pageSize: number = 36;
  initialParking: any;
  constructor(
    private park: ParkingService,
    public imagesService: ImagesService,
    private route: ActivatedRoute,
    public router: Router,
  ) {}
  
  ngOnDestroy() {
    console.log('unsuscribe polling');
    this.subs.unsubscribe();
  }
  
  async ngOnInit() {
    this.section = this.route.snapshot.queryParams.section;
    
    //Retrieve list of lots by parking_id and section
    await this.park.retriveAllInfo(this.parking_id).then(list => {
      this.list = list;
      this.parkspaces = this.list.section.filter(x => x.name == this.section);
    });
    
    //Retrieve list of lots by current section
    await this.park.retriveLotsInfo(this.parking_id).toPromise().then(info => {
      this.parkinfo = info as any;
      for (let i = 0; i < this.parkinfo.length; i++) {
        for ( let j = 0; j < this.parkspaces[0].lots.length; j++)
        {
          if(this.parkinfo[i].id == this.parkspaces[0].lots[j]) {
            this.parkSpaces.push(this.parkinfo[i]);
          }
        }
      }
      
      for(let i=0; i<this.parkSpaces.length; i++) {
        var lastDash = this.parkSpaces[i].id.lastIndexOf('-'); // find the last -
        var id = this.parkSpaces[i].id.substr(0, lastDash);
        if(this.section.includes('-')) {
          var lotNumber = this.parkSpaces[i].id.replace(id.concat(this.section), '')
        } else {
          var lotNumber = this.parkSpaces[i].id.replace(id.concat('-').concat(this.section), '')
        }
        var name = this.section.concat(lotNumber)
        this.parkSpaces[i].name = name;
      }
      
      let sub2 = this.subs = timer(0, 3000).subscribe(() => {
        console.log('polling lots and areas info');
        let sub3 = this.park.retriveLotsInfo(this.parking_id).subscribe((lots: any) => {
          this.lots = lots;
        });
        this.subs.add(sub3);
      });
      this.subs.add(sub2);
    });
    
    for(let j=0; j<this.parkSpaces.length; j++) {
      if(this.parkSpaces[j].sensor_id === '') {
        this.parkSpaces.splice(j, 1);
        j--;
      }
    }
    
    this.currentItemsToShow = this.parkSpaces;
    this.currentItemsToShow = this.parkSpaces.slice(0, this.pageSize);
    
    this.initialParking = this.route.snapshot.queryParams.lot;
    this.section = this.route.snapshot.queryParams.section;
    
    if(this.initialParking !== undefined) {
      setTimeout(()=>{
        this.parkSpaceY = this.parkSpaces.filter(x => x.id == this.initialParking)
        this.viewLotInfo(this.parkSpaceY[0]);
      }, 1000);
    } else {
      this.router.navigate([], { queryParams: { lot: this.parkSpaces[0].id }, queryParamsHandling: 'merge' });
      this.parkSpaceY = this.parkSpaces[0]
      this.viewLotInfo(this.parkSpaceY);
    }
    
    this.parkImagesId =  this.parking_id + "-" + this.section;
    await this.imagesService.retriveImagesExists(this.parkImagesId)
      .then(
        (data) => {
        },
        (err) => {
          this.displaymap = false
        }
      );    
  }
  
  /* generateRandomNumberMaxMin_toNumber(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1)));
  }; */
  
  styleObject(parkSpace) {
    let res;
    res = { cursor: "pointer", border: "1px solid black", margin: "5px 5px 0 0", };
    res.backgroundColor = '#BFC0C2';
    
    if (this.lots !== undefined) {
    /* if (parkSpace.type === "lot") { */
      const lot = this.lots.filter(function (l) {
        return (l.id == parkSpace.id);
      });
      if (lot.length !== 0) {
        switch (lot[0].status) {
          case "free": {
            res.backgroundColor = '#70BF54';
            break;
          }
          case "busy": {
            res.backgroundColor = '#E11F26';
            break;
          }          
          case "alert": {
            // res.backgroundColor='#FCAF17';
            res.animation = "colorChange 1s infinite";
            break;
          }
          default: {
            res.backgroundColor = '#BFC0C2';
            break;
          }
        }
      }
    /* } */
    return res;
    }
  }
  
  viewLotInfo(parkSpaceX) {
    this.display = true;
    this.parkSpaceY = parkSpaceX;
  }
  
  onPageChange($event) {
    this.currentItemsToShow =  this.parkSpaces.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }
  
}