import { OnInit, Component, QueryList, ViewChildren, HostListener, ViewChild, Input,  Output, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { of, Subscription, throwError } from "rxjs";

import { Collection, Feature, Graticule, View } from "ol";
import Map from "ol/Map";
import Polygon from "ol/geom/Polygon";
import Point from "ol/geom/Point";
import Geometry from "ol/geom/Geometry";
import GeometryType from "ol/geom/GeometryType";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import { DragBox, DragPan, Draw, Select, Translate } from "ol/interaction";
import { mouseOnly, noModifierKeys, platformModifierKeyOnly } from "ol/events/condition";

import { ImageInfo } from 'src/app/_shared/models/image';
import { LotGetModel, LotPutModel } from "src/app/_shared/models/lot";
import { DisplayGetModel, DisplayPostModel, DisplayPutModel } from "src/app/_shared/models/display";

import { ParkingService } from 'src/app/_services/parking.service';
import { TypeService } from 'src/app/_services/type.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from "src/app/_services/user.service";
import { ImagesService } from "src/app/_services/images.service";
import { StyleService } from "src/app/_services/style.service";
import { LotService } from "src/app/_services/lot.service";
import { DisplaysService } from "src/app/_services/displays.service";

import { ModalComponent, ModalDirective } from "@inst-iot/bosch-angular-ui-components";
import { MatSidenav } from "@angular/material/sidenav";

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-map-edit',
  templateUrl: './map-edit.component.html',
  styleUrls: ['./map-edit.component.scss']
})
export class MapEditComponent implements OnInit {
  @ViewChildren("popUpInfo", {read: ModalDirective}) ngbPopoverInfo!: QueryList<ModalComponent>;
  @ViewChildren("popUpInfoDisplay", {read: ModalDirective}) ngbPopoverInfoDisplay!: QueryList<ModalComponent>;
  @Input() parkingdrawer: MatSidenav;
  
  //Input Variables from the map layer
  @Input() id: string;
  @Input() section: string;
  @Input() map: Map;
  @Input() vectorSource: VectorSource;
  @Input() info: ImageInfo;
  @Input() lots: any;
  @Input() features: any;
  @Input() vectorSourceDisplay: VectorSource;
  @Input() displays: DisplayGetModel[];
  
  //Output Variables (selectedFeatures)
  @Output() newItemEvent = new EventEmitter<any>();
  @Output() newItemEventDisplay = new EventEmitter<any>();
  
  userRole: any;
  tool = "none";
  type: any;
  
  vectorLayer: VectorLayer;
  translate: Translate;
  drawPolygon: Draw;
  AxisLayer: VectorLayer;
  graticule: Graticule;
  select: Select;
  xCoordClick;
  yCoordClick;
  pointFeatureRadius = 18;
  pointFeatureOffset = 46;
  
  vectorLayerDisplay: VectorLayer;
  allDisplays: DisplayGetModel[];
  
  // Variables for Undo and Modal
  registerAllActions: any[] = [];
  registerSingleAction: any[] = [];
  registerModalActions: any[] = [];
  moveActionArray: any[] = [];
  selectedFeatures: Collection<Feature<Geometry>>;
  copiedFeature: Feature[] = [];
  
  // Form to Add dot
  addDotForm: UntypedFormGroup;
  parkingIdForm = new UntypedFormControl();
  sectionNameForm = new UntypedFormControl();
  lotNumberForm = new UntypedFormControl('')
  letterNameForm = new UntypedFormControl("");
  letterName: string = '';
  
  // Form to Add dot
  addDisplayForm: UntypedFormGroup;
  parkingIdDisplayForm = new UntypedFormControl();
  sectionNameDisplayForm = new UntypedFormControl();
  letterNameDisplayForm = new UntypedFormControl('');
  letterNameDisplay: string = '';
  displayNumberDisplayForm = new UntypedFormControl('')
  
  // Model to send new dot
  newDotModel: any[] = [];
  modalReference = null;
  
  display: boolean = false;
  displayEmpty: boolean = false;
  name: any;
  
  displayConfirm: boolean = false;
  displayEmptyConfirm: boolean = false;
  nameDisplay: any;
  
  //Variables to enable group management functions
  displaybutton: boolean = false;
  displayselector: boolean = false;
  
  //Reposition Variables
  repoDot: any;
  repositionDot: any;
  repositionForm: UntypedFormGroup;
  dotNameRepositionForm = new UntypedFormControl();
  displayReposition: boolean = false;
  updateDotModel: any[];
  searchFunc;
  filteredDotList = [];
  
  //Move All Dot Variables
  translateAll: Translate;
  
  //Change Type
  typeList: any = ["regular", "handicap", "management", "guest", "e-vehicle", "short-term"];
  typeGroupForm: UntypedFormGroup;
  typeNameForm = new UntypedFormControl();
  selectedStatesType: any;
  selectedItemType: any;
  keys: any;
  selectedListType: any;
  
  // Variables to replace Map Image 
  selectedFile = null;
  confirmationMessageReplaceImage: SafeHtml;
  displayConfirmReplaceImage: boolean = false;
  radiusLot: number = 9;
  radiusArea: number = 56;
  radiusLotMobile: number = 9;
  clusterDistance: number = 20;
  
  constructor(
    private park: ParkingService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitized: DomSanitizer,
    public auth: AuthenticationService,
    public userService: UserService,
    private loc: Location,
    public imagesService: ImagesService,
    private style: StyleService,
    public t: TypeService,
    private toastrService: ToastrService,
    public form: UntypedFormBuilder,
    public lotService: LotService,
    public displayService: DisplaysService,
  ) {
    this.addDotForm = form.group({
      parkingIdForm: this.parkingIdForm,
      sectionNameForm: this.sectionNameForm,
      lotNumberForm: this.lotNumberForm,
      letterNameForm: this.letterNameForm
    });
    
    this.addDisplayForm = form.group({
      parkingIdDisplayForm: this.parkingIdDisplayForm,
      sectionNameDisplayForm: this.sectionNameDisplayForm,
      displayNumberDisplayForm: this.displayNumberDisplayForm,
      letterNameDisplayForm: this.letterNameDisplayForm
    });
    
    this.repositionForm = form.group({
      dotNameRepositionForm: this.dotNameRepositionForm
    });
    
    this.typeGroupForm = form.group({
      typeNameForm: this.typeNameForm
    });
    
    this.features = [];
    
    this.translate = new Translate({
      filter: function (feature) {
        //avoid translate on grid axis
        return feature.getGeometry().getType() !== "LineString";
      },
    });
    
    this.drawPolygon = new Draw({
      source: this.vectorSource,
      type: 'Polygon' as GeometryType,
    });
    
    this.info = new ImageInfo();
  }
  
  ngOnDestroy() {
    this.undoAllEvent();
    this.map.removeInteraction(this.select);
    this.map.removeInteraction(this.translate);
    this.map.removeLayer(this.vectorLayer);
    this.map.removeLayer(this.vectorLayerDisplay);
    this.map.removeLayer(this.graticule);
  }
  
  async ngOnInit() {
    
    await this.userService.userCanEditByParkingID(this.id).then(
      (data) => {
        this.userRole = data['role'];
      },
      () => {
        this.userRole = null;
      }
    )
    
    if (this.userRole == "admin" || this.userRole == "owner") {
      this.type = "lot";
      
      this.allDisplays = this.displays;
      
      //Setup a Vector Layer
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: this.styleObject,
      });
      
      //Setup a Vector Layer
      this.vectorLayerDisplay = new VectorLayer({
        source: this.vectorSourceDisplay,
        style: this.styleObjectDisplay,
      });
      
      //Axis Definition
      const axisStyle = new Style({
        stroke: new Stroke({
          color: "rgba(0,0,0,0.1)",
          width: 1,
        }),
      });
      
      this.AxisLayer = new VectorLayer({
        source: new VectorSource(),
      });
      
      this.AxisLayer.setStyle(axisStyle);
      
      this.AxisLayer.setMap(this.map);
      
      //Layer Order Definition
      this.AxisLayer.setZIndex(1);
      this.vectorLayer.setZIndex(5);
      this.vectorLayerDisplay.setZIndex(5);
      
      //Create and Add Graticule to Map
      this.graticule = new Graticule({
        strokeStyle: new Stroke({
          color: "lightgrey",
          width: 1,
        }),
        showLabels: false,
        wrapX: false,
        targetSize: 30,
      });
      
      this.map.addLayer(this.graticule);
      
      //Add VectorLayer to Map
      this.map.addLayer(this.vectorLayer);
      this.map.addLayer(this.vectorLayerDisplay);
      
      //Compute ID in form of next dot that could be added
      const progressiveNum = this.generateDotId(this.info);
      this.addDotForm.controls.lotNumberForm.setValue(progressiveNum);
      
      //Initialization form to add lots
      this.computeAddForm(this.id, this.section, this.info);
      
      //Initialization form to add lots
      this.computeAddDisplayForm(this.id, this.section, this.info);
      
      //Initialization Reposition Feature
      this.park.retriveParkingSub(this.id).subscribe((data: any) => {
        this.repoDot = data;
        for (let i = 0; i < this.repoDot.section.length; i++) {
          if (this.repoDot.section[i].name == this.section) {
            this.repositionDot = this.repoDot.section[i].lots;
          }
        }
        this.repositionForm.controls.dotNameRepositionForm.setValue(
          this.repositionDot[0]
        );
      });
      
      /*
      on map click:
      - if is feature
        - move Parking Space / Display or delete Parking Space / Display or modify Parking Space / Display
      - if not
        - create new feature (create new Parking Space / Display)
      */
      this.map.on("click", (event) => {
        var feature = this.map.forEachFeatureAtPixel(
          event.pixel,
          function (feature) {
            return feature;
          }
        );
        if (feature) {
          if (this.tool === "move") {
            //Move Function
            this.translate.on("translatestart", (event) => {});
            this.translate.on("translateend", (event) => {});
          } else if (this.tool === "mod") {
            //Modify Function
            var lotID = feature.get("description");
            var featGeometry = feature.getGeometry();
            
            if (featGeometry instanceof Point) {
              var popOverElements = [];
              popOverElements = this.ngbPopoverInfo.toArray();
              var popOverComponent = popOverElements.find(
                (x) =>
                  x.content.elementRef.nativeElement.previousSibling.id == lotID
              );
              popOverComponent.modalService.open(popOverComponent.content);
            }
          } else if (this.tool === "modDisplay") {
            //Modify Function
            var displayID = feature.get("description");
            var featGeometry = feature.getGeometry();
            
            if (featGeometry instanceof Point) {
              var popOverElements = [];
              popOverElements = this.ngbPopoverInfoDisplay.toArray();
              var popOverComponent = popOverElements.find(
                (x) =>
                  x.content.elementRef.nativeElement.previousSibling.id == displayID
              );
              popOverComponent.modalService.open(popOverComponent.content);
            }
          } else if (this.tool === "delete") {
            // Delete Function
            this.deleteEvent(feature);
          }
        } else {
          if (this.tool === "reposition") {
            // Reposition Function
            let dotName = this.selectDotToMove();
            
            if (!this.isLocated(dotName)) {
              var newFeature = new Feature({
                geometry: new Point(event.coordinate),
                description: dotName,
                status: "",
                type: "",
              });
              
              this.vectorSource.addFeature(newFeature);
              this.vectorLayer.setStyle(this.styleObject);
              
              this.info.dot_coordinate.push({
                name: dotName,
                x: event.coordinate[0],
                y: event.coordinate[1],
                type: "lot",
              });
              
              // Registro l'evento di REPOSITION in un vettore e chiamo la funzione update
              this.registerSingleAction[0] = "REPOSITION";
              this.registerSingleAction[1] = newFeature;
              
              this.updateEvent();
              
              this.toast("success", dotName, "Dot repositioned successfully");
              
              for (let i = 0; i < this.registerAllActions.length; i++) {
                if (this.registerAllActions[i][0] == "REPOSITION") {
                  for (let j = 0; j < this.registerAllActions.length; j++) {
                    if (
                      this.registerAllActions[j][0] == "DELETE" &&
                      this.registerAllActions[j][1].values_.description ==
                        this.registerAllActions[i][1].values_.description
                    )
                      this.registerAllActions.splice(
                        this.registerAllActions.indexOf(
                          this.registerAllActions[j]
                        ),
                        1
                      );
                  }
                }
              }
            } else {
              this.toast("error", dotName, "Dot is already placed");
            }
          } else if (this.tool === "crosshair") {
            // ADD new point
            this.buttonState();
            document.body.style.cursor = "crosshair";
            
            this.letterName = this.addDotForm.controls.letterNameForm.value;
            const progressiveNum = this.generateDotId(this.info);
            this.addDotForm.controls.lotNumberForm.setValue(progressiveNum);
            console.log("NEW INFO PARKING ID ", this.info);
            var newLotName =
              this.info.parking_id +
              "-" +
              this.section +
              this.letterName +
              progressiveNum.toString(); // NEW NAME: "parking id"-"section""letter""progressiveNum"
            var newFeature = new Feature({
              geometry: new Point(event.coordinate),
              description: newLotName,
              status: "",
              type: "",
            });
            
            this.vectorSource.addFeature(newFeature);
            this.vectorLayer.setStyle(this.styleObject);
            
            this.info.dot_coordinate.push({
              name: newLotName,
              x: Math.round(event.coordinate[0]),
              y: Math.round(event.coordinate[1]),
              type: "lot",
            });
            
            // Registro l'evento di ADD in un vettore e chiamo la funzione update
            this.registerSingleAction[0] = "ADD";
            this.registerSingleAction[1] = newFeature;
            
            this.updateEvent();
            if (this.checkExistAdd(newLotName) && this.checkEmpty()) {
              this.toast("success", newLotName, "Dot added successfully");
            } else if (!this.checkEmpty()) {
              var lastFeature = this.registerAllActions.pop()[1];
              this.vectorSource.removeFeature(lastFeature);
              this.toast(
                "error",
                newLotName,
                "Please enter a valid Parking Space name"
              );
            } else {
              var lastFeature = this.registerAllActions.pop()[1];
              this.vectorSource.removeFeature(lastFeature);
              this.toast(
                "error",
                newLotName,
                "Parking Space name already exists"
              );
            }
          } else if (this.tool === "addDisplay") {
            // Add New display
            this.buttonState();
            document.body.style.cursor = "crosshair";
            
            this.letterNameDisplay = this.addDisplayForm.controls.letterNameDisplayForm.value;
            const progressiveNum = this.generateDisplayNumber(this.info);
            this.addDisplayForm.controls.displayNumberDisplayForm.setValue(progressiveNum);
            
            var newDisplayName =
              this.info.parking_id +
              "-display-" +
              this.section +
              this.letterNameDisplay +
              progressiveNum.toString(); // NEW DISPLAY: "parkingId"+"-display"+"section"+"letter"+"progressiveNum"
            
              var newDisplayFeature = new Feature({
              geometry: new Point(event.coordinate),
              description: newDisplayName,
              status: "",
              type: "",
              featureType: 'display',
            });
            
            this.vectorSourceDisplay.addFeature(newDisplayFeature);
            this.vectorLayerDisplay.setStyle(this.styleObjectDisplay);
            
            this.allDisplays.push({
              name: newDisplayName,
              url: "",
              layout: "",
              username: "",
              password: "",
              id: "",
              expirationDate: "",
              sessionId: "",
              status: "",
              pubStatus: "",
              loginStatus: "",
              groups: [],
              mac: "",
              btia: "",
              x: Math.round(event.coordinate[0]),
              y: Math.round(event.coordinate[1]),
              parkingId: "",
              value: 0
            });
            
            // Registro l'evento di ADD in un vettore e chiamo la funzione update
            this.registerSingleAction[0] = "ADD DISPLAY";
            this.registerSingleAction[1] = newDisplayFeature;
            
            this.updateEvent();
            
            if (this.checkExistDisplayAdd(newDisplayName) && this.checkEmptyDisplay()) {
              this.toast("success", newDisplayName, "Display added successfully");
            } else if (!this.checkEmptyDisplay()) {
              var lastFeature = this.registerAllActions.pop()[1];
              this.vectorSourceDisplay.removeFeature(lastFeature);
              this.toast(
                "error",
                newDisplayName,
                "Please enter a valid Display name"
              );
            } else {
              var lastFeature = this.registerAllActions.pop()[1];
              this.vectorSourceDisplay.removeFeature(lastFeature);
              this.toast(
                "error",
                newDisplayName,
                "Display name already exists"
              );
            }
            
          } else if (this.tool === "Polygon") {
            var newLotName = this.info.parking_id + "-0000new";
          }
        }
      });
      
      this.translate.on("translateend", (event) => {
        console.log(event.features.getArray()[0].get('featureType'))
        if(event.features.getArray()[0].get('featureType') == 'display') {
          this.registerSingleAction[0] = "MOVE DISPLAY";
          this.registerSingleAction[1] = event.features.getArray()[0];
          
          this.updateEvent();
          this.toast("success",(event.features.getArray()[0] as any).values_.description, "Display moved successfully");
        } else {
          this.registerSingleAction[0] = "MOVE";
          this.registerSingleAction[1] = event.features.getArray()[0];
          
          this.updateEvent();
          this.toast("success",(event.features.getArray()[0] as any).values_.description, "Dot moved successfully");
        }
        
      });
      
      this.drawPolygon.on("drawend", (event) => {
        var newPolygonFeature = event.feature;
        this.vectorSource.addFeature(newPolygonFeature);
        this.vectorLayer.setStyle(this.styleObject);
      });
    } else {
      this.toast(
        "error",
        "You do not have permission to access this page.",
        "Access Denied"
      );
      setTimeout(() => {
        this.loc.back();
      }, 3000);
    }
    
    // Set Style of selected features
    var iconStyle = new Style({
      image: new Circle({
        radius: this.pointFeatureRadius,
        fill: new Fill({
          color: "rgb(63, 170, 219)",
        }),
        stroke: new Stroke({
          color: "rgb(0, 86, 145)",
          width: 2,
        }),
      }),
    });
    
    // Select interaction to handle click
    this.select = new Select({ style: iconStyle });
    this.map.addInteraction(this.select);
    this.selectedFeatures = this.select.getFeatures();
    this.addNewItem(this.selectedFeatures)
    this.addNewItemDisplay(this.selectedFeatures)
    
    // a DragBox interaction used to select features by drawing boxes. Mouse left click
    const dragBox = new DragBox({
      condition: platformModifierKeyOnly
    });
    this.map.addInteraction(dragBox);
    
    // a DragPan to move map
    const dragPan = new DragPan({
      condition: mouseOnly && noModifierKeys
      
    })
    this.map.addInteraction(dragPan);
    
    // clear selection when drawing a new box and when clicking on the map
    dragBox.on("boxstart", () => {
      document.body.style.cursor = "default";
      this.selectedFeatures.clear();
    });
    
    dragBox.on("boxend", (event) => {
      const extent = dragBox.getGeometry().getExtent();
      this.vectorSource.forEachFeatureIntersectingExtent(extent, (feature) => {
        this.selectedFeatures.push(feature);
      });
      this.addNewItem(this.selectedFeatures)
      this.addNewItemDisplay(this.selectedFeatures)
      /* this.toast("info",'',"Right Click to open the menu") */
    });
    
    this.addDotForm.valueChanges.subscribe(changes=>{
      this.display = false;
      this.displayEmpty = false;
      this.checkEmpty();
      this.checkExist();
    });
    
    this.addDisplayForm.valueChanges.subscribe(changes=>{
      this.checkEmptyDisplay();
      this.checkExistDisplay();
    });
    
    this.repositionForm.get('dotNameRepositionForm').valueChanges.subscribe(changes => {
      this.isLocated(changes)
    })
    
    this.filteredDotList = this.repositionDot;
    this.searchFunc = this.search.bind(this);
    
    this.style.computeMinHeight();
  }
  
  //Check if the next Parking Space name is empty
  checkEmpty() {
    if(this.addDotForm.controls.lotNumberForm.value === null) {
      this.displayEmpty = true;
      return false;
    } else {
      this.displayEmpty = false;
      return true;
    }
  }
  
  //Check if the next Parking Space name is empty
  checkEmptyDisplay() {
    if(this.addDisplayForm.controls.displayNumberDisplayForm.value === null) {
      this.displayEmptyConfirm = false;
      return false;
    } else {
      this.displayEmptyConfirm = true;
      return true;
    }
  }
  
  //Check if the initial Parking Space name exists
  checkExist() {
    this.name = this.addDotForm.controls.parkingIdForm.value + '-' + this.addDotForm.controls.sectionNameForm.value + this.addDotForm.controls.letterNameForm.value + this.addDotForm.controls.lotNumberForm.value;
    for(let i=0; i<this.lots.length; i++) {
      if(this.lots[i].id === this.name) {
        this.display = true;
        return false;
      }
    }
    let countAdd = 0;
    let countDelete = 0;
    for (let i = 0; i < this.registerAllActions.length; i++) {
      if (this.registerAllActions[i][0] === 'ADD' && this.registerAllActions[i][1].values_.description === this.name) {
        countAdd++;
      }
    }
    for (let j = 0; j < this.registerAllActions.length; j++) {
      if(this.registerAllActions[j][0] === 'DELETE' && this.registerAllActions[j][1].values_.description === this.name) {
        countDelete++;
      }
    }
    if (countAdd === countDelete) {
      this.display = false;
      return true;
    } else {
      this.display = true;
      return false;
    }
  }
  
  //Check if the initial Display name exists
  checkExistDisplay() {
    this.nameDisplay = this.addDisplayForm.controls.parkingIdDisplayForm.value + '-display-' + this.addDisplayForm.controls.sectionNameDisplayForm.value + this.addDisplayForm.controls.letterNameDisplayForm.value + this.addDisplayForm.controls.displayNumberDisplayForm.value;
    for(let i=0; i<this.allDisplays.length-1; i++) {
      if(this.allDisplays[i].name === this.nameDisplay) {
        this.displayConfirm = false;
        return false;
      }
    }
    let countAdd = 0;
    let countDelete = 0;
    for (let i = 0; i < this.registerAllActions.length; i++) {
      if (this.registerAllActions[i][0] === 'ADD DISPLAY' && this.registerAllActions[i][1].values_.description === this.nameDisplay) {
        countAdd++;
      }
    }
    for (let j = 0; j < this.registerAllActions.length; j++) {
      if(this.registerAllActions[j][0] === 'DELETE DISPLAY' && this.registerAllActions[j][1].values_.description === this.nameDisplay) {
        countDelete++;
      }
    }
    if (countAdd === countDelete) {
      this.displayConfirm = true;
      return true;
    } else {
      this.displayConfirm = false;
      return false;
    }
  }
  
  //Check if the next Parking Space name exists
  checkExistAdd(name) {
    for(let i=0; i<this.lots.length; i++) {
      if(this.lots[i].id === name) {
        this.display = true;
        return false;
      }
    }
    let countAdd = 0;
    let countDelete = 0;
    for (let i = 0; i < this.registerAllActions.length-1; i++) {
      if (this.registerAllActions[i][0] === 'ADD' && this.registerAllActions[i][1].values_.description === name) {
        countAdd++;
      }
    }
    for (let j = 0; j < this.registerAllActions.length; j++) {
      if(this.registerAllActions[j][0] === 'DELETE' && this.registerAllActions[j][1].values_.description === name) {
        countDelete++;
      }
    }
    if (countAdd === countDelete) {
      this.display = false;
      return true;
    } else {
      this.display = true;
      return false;
    }
  }
  
  //Check if the next Display name exists
  checkExistDisplayAdd(name) {
    for(let i=0; i<this.allDisplays.length-1; i++) {
      if(this.allDisplays[i].name === name) {
        this.displayConfirm = false;
        return false;
      }
    }
    let countAdd = 0;
    let countDelete = 0;
    for (let i = 0; i < this.registerAllActions.length-1; i++) {
      if (this.registerAllActions[i][0] === 'ADD DISPLAY' && this.registerAllActions[i][1].values_.description === name) {
        countAdd++;
      }
    }
    for (let j = 0; j < this.registerAllActions.length; j++) {
      if(this.registerAllActions[j][0] === 'DELETE DISPLAY' && this.registerAllActions[j][1].values_.description === name) {
        countDelete++;
      }
    }
    if (countAdd === countDelete) {
      this.displayConfirm = true;
      return true;
    } else {
      this.displayConfirm = false;
      return false;
    }
  }
  
  //Function to align selected dot horizontally
  alignHorizontally() {
    let onlyCoordinate = [];
    for (let i = 0; i < this.selectedFeatures.getArray().length; i++) {
      onlyCoordinate[i] = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[1];
    }
    const sum = onlyCoordinate.reduce((a, b) => a + b, 0);
    const avg = (sum / onlyCoordinate.length) || 0;
    for (let i = 0; i < this.selectedFeatures.getArray().length; i++) {
      this.selectedFeatures.getArray()[i].getGeometry().translate(0, avg - this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[1]);
    }
    
    for(let i=0; i<this.selectedFeatures.getArray().length; i++) {
      this.registerSingleAction[0] = "MOVE";
      this.registerSingleAction[1] = this.selectedFeatures.getArray()[i];
      this.updateEvent();
    }
  }
  
  //Function to align selected dot vertically
  alignVertically() {
    let onlyCoordinate = [];
    for (let i = 0; i < this.selectedFeatures.getArray().length; i++) {
      onlyCoordinate[i] = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[0];
    }
    const sum = onlyCoordinate.reduce((a, b) => a + b, 0);
    const avg = (sum / onlyCoordinate.length) || 0;
    for (let i = 0; i < this.selectedFeatures.getArray().length; i++) {
      this.selectedFeatures.getArray()[i].getGeometry().translate(avg - this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[0], 0)
    }
    
    for(let i=0; i<this.selectedFeatures.getArray().length; i++) {
      this.registerSingleAction[0] = "MOVE";
      this.registerSingleAction[1] = this.selectedFeatures.getArray()[i];
      this.updateEvent();
    }
  }
  
  //Function to distribute selected dot horizontally
  distributeHorizontally() {
    let onlyCoordinate = [];
    this.selectedFeatures.getArray().sort(this.compareX)
    for (let i = 0; i<this.selectedFeatures.getArray().length; i++) {
      onlyCoordinate[i] = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[0];
    }
    let first = onlyCoordinate[0];
    let last = onlyCoordinate[onlyCoordinate.length-1];
    let avg = (last-first)/(onlyCoordinate.length-1);
    
    for (let i=0; i<this.selectedFeatures.getArray().length; i++) {
        let diff = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[0]-first;
        this.selectedFeatures.getArray()[i].getGeometry().translate((i*avg)-diff, 0);
    }
    
    for(let i=0; i<this.selectedFeatures.getArray().length; i++) {
      this.registerSingleAction[0] = "MOVE";
      this.registerSingleAction[1] = this.selectedFeatures.getArray()[i];
      this.updateEvent();
    }
  }
  
  compareX( a, b ) {
    if ( a['values_'].geometry.flatCoordinates[0] < b['values_'].geometry.flatCoordinates[0] ){
      return -1;
    }
    if ( a['values_'].geometry.flatCoordinates[0] > b['values_'].geometry.flatCoordinates[0] ){
      return 1;
    }
    return 0;
  }
  
  //Function to distribute selected dot vertically
  distributeVertically() {
    let onlyCoordinate = [];
    this.selectedFeatures.getArray().sort(this.compareY)
    for (let i = 0; i<this.selectedFeatures.getArray().length; i++) {
      onlyCoordinate[i] = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[1];
    }
    let first = onlyCoordinate[0];
    let last = onlyCoordinate[onlyCoordinate.length-1];
    let avg = (last-first)/(onlyCoordinate.length-1);
    
    for (let i=0; i<this.selectedFeatures.getArray().length; i++) {
      let diff = this.selectedFeatures.getArray()[i]['values_'].geometry.flatCoordinates[1]-first;
      this.selectedFeatures.getArray()[i].getGeometry().translate(0, (i*avg)-diff);
    }
    
    for(let i=0; i<this.selectedFeatures.getArray().length; i++) {
      this.registerSingleAction[0] = "MOVE";
      this.registerSingleAction[1] = this.selectedFeatures.getArray()[i];
      this.updateEvent();
    }
  }
  
  compareY( a, b ) {
    if ( a['values_'].geometry.flatCoordinates[1] < b['values_'].geometry.flatCoordinates[1] ){
      return -1;
    }
    if ( a['values_'].geometry.flatCoordinates[1] > b['values_'].geometry.flatCoordinates[1] ){
      return 1;
    }
    return 0;
  }
  
  //Function to generate the dot ID
  generateDotId(info: ImageInfo) {
    let numberId = [];
    if (info.dot_coordinate.length < 1) {
      numberId[0] = this.addDotForm.controls.lotNumberForm.value - 1;
    } else {
      if ((this.addDotForm.controls.lotNumberForm.value) == null) {
        numberId[0] = Number(info.dot_coordinate[info.dot_coordinate.length-1].name.replace(info.parking_id + '-' + this.section + this.letterName,'').split(/(\d+)/)[1])
      } else {
        if ((this.addDotForm.controls.lotNumberForm.value) !== Number(info.dot_coordinate[info.dot_coordinate.length-1].name.replace(info.parking_id + '-' + this.section + this.letterName,'').split(/(\d+)/)[1])
        ) {
          numberId[0] = this.addDotForm.controls.lotNumberForm.value - 1;
        } else {
          for (let i = 0; i < info.dot_coordinate.length; i++) {
            numberId[i] = Number(info.dot_coordinate[i].name.replace(info.parking_id + '-' + this.section + this.letterName,'').split(/(\d+)/)[1]);
          }
        }  
      }
    }
    return (numberId[numberId.length-1] + 1);
  }
  
  //Function to generate the Display Name
  generateDisplayNumber(info: ImageInfo) {
    let numberId = [];
    if (this.allDisplays.length < 1) {
      numberId[0] = this.addDisplayForm.controls.displayNumberDisplayForm.value - 1;
    } else {
      if ((this.addDisplayForm.controls.displayNumberDisplayForm.value) == null) {
        numberId[0] = Number(this.allDisplays[this.allDisplays.length-1].name.replace(info.parking_id + '-display-' + this.section + this.letterNameDisplay,'').split(/(\d+)/)[1])
      } else {
        if ((this.addDisplayForm.controls.displayNumberDisplayForm.value) !== Number(this.allDisplays[this.allDisplays.length-1].name.replace(info.parking_id + '-display-' + this.section + this.letterNameDisplay,'').split(/(\d+)/)[1])
        ) {
          numberId[0] = this.addDisplayForm.controls.displayNumberDisplayForm.value - 1;
        } else {
          for (let i = 0; i < this.allDisplays.length; i++) {
            numberId[i] = Number(this.allDisplays[i].name.replace(info.parking_id + '-display-' + this.section + this.letterNameDisplay,'').split(/(\d+)/)[1]);
          }
        }  
      }
    }
    return (numberId[numberId.length-1] + 1);
  }
  
  //Function to change the cursor style
  changeMouse(){
    document.body.style.cursor = "crosshair";
  }
  
  //Delete Event
  deleteEvent(feature) {
    this.buttonState();
    document.body.style.cursor = "pointer";
    let deleted = feature.values_.description
    this.deleteFromFeature(feature);
    if(feature.values_.featureType == 'display') {
      this.toast('success', feature.values_.description, 'Display deleted successfully');
      this.registerSingleAction[0] = "DELETE DISPLAY";
      this.registerSingleAction[1] = feature;
      this.updateEvent();
    } else {
      this.toast('success', feature.values_.description, 'Dot deleted successfully');
      this.isLocated(deleted)
      // Registro l'evento di DELETE in un vettore e chiamo la funzione update
      this.registerSingleAction[0] = "DELETE";
      this.registerSingleAction[1] = feature;
      this.updateEvent();
    }
    
  }
  
  //Function to store events in array
  updateEvent() {
    if(this.registerSingleAction[0] === 'MOVE' && this.registerAllActions.length > 0) {
      for (let i = 0; i<this.registerAllActions.length; i++) {
        if( this.registerSingleAction.length > 0 && this.registerAllActions[i][0] !== 'ADD' && this.registerAllActions[i][1].values_.description == this.registerSingleAction[1].values_.description) {
          this.registerAllActions[i] = this.registerSingleAction;
          this.registerSingleAction = [];
        } else if (this.registerSingleAction.length > 0 && this.registerAllActions[i][0] == 'ADD' && this.registerAllActions[i][1].values_.description == this.registerSingleAction[1].values_.description) {
          this.registerSingleAction = [];
        }
      } if(this.registerSingleAction.length > 0) {
        this.registerAllActions.push(this.registerSingleAction);
        this.registerSingleAction = [];
      }
    } else {
    this.registerAllActions.push(this.registerSingleAction);
    this.registerSingleAction = [];
    }
  }
  
  //Undo Single Event
  undoEvent() {
    this.buttonState();
    this.tool = "none";
    // Memorizzo e rimuovo l'ultimo evento registrato
    var lastAction = this.registerAllActions.pop();
    
    // In base al tipo di evento, modifico il comportamento dell'undo
    if (lastAction[0] == "DELETE") {
      this.vectorSource.addFeature(lastAction[1]);
    } else if (lastAction[0] == "ADD" || lastAction[0] == "ADD DISPLAY" || lastAction[0] == "REPOSITION") {
      this.deleteFromFeature(lastAction[1])
    } else if(lastAction[0] == "MOVE") {
      var geom = lastAction[1].getGeometry();
          for (let i=0; i<this.info.dot_coordinate.length; i++) {
            if(lastAction[1].values_.description == this.info.dot_coordinate[i].name) {
              var oldCoords = [
                this.info.dot_coordinate[i].x,
                this.info.y_multiplier - this.info.dot_coordinate[i].y,
              ];
              geom.setCoordinates(oldCoords);
            }
          }
    } else if (lastAction[0] == "MOVE DISPLAY") {
      var geom = lastAction[1].getGeometry();
      for (let i=0; i<this.displays.length; i++) {
        if(lastAction[1].values_.description == this.displays[i].name) {
          var oldCoords = [
            this.displays[i].x,
            this.info.y_multiplier - this.displays[i].y,
          ];
          geom.setCoordinates(oldCoords);
        }
      }
    } else if (lastAction[0] == "DELETE DISPLAY") {
      this.vectorSourceDisplay.addFeature(lastAction[1]);
    }
  }
  
  //Undo All event
  undoAllEvent() {
    this.buttonState();
    this.tool = "none";
    // Elimino tutti gli eventi registrati e ripristino le precedenti features
    for (let i = this.registerAllActions.length - 1; i >= 0; i--) {
      var lastAction = this.registerAllActions.pop();
      if (lastAction[0] == "DELETE") {
        this.vectorSource.addFeature(lastAction[1]);
        this.vectorLayer.setStyle(this.styleObject);
      } else if (lastAction[0] == "ADD" || lastAction[0] == "ADD DISPLAY" || lastAction[0] == "REPOSITION") {
        this.deleteFromFeature(lastAction[1])
      } else if (lastAction[0] == "MOVE") {
          var geom = lastAction[1].getGeometry();
          for (let i=0; i<this.info.dot_coordinate.length; i++) {
            if(lastAction[1].values_.description == this.info.dot_coordinate[i].name) {
              var oldCoords = [
                this.info.dot_coordinate[i].x,
                this.info.y_multiplier - this.info.dot_coordinate[i].y,
              ];
              geom.setCoordinates(oldCoords);
            }
          }
      } else if (lastAction[0] == "MOVE DISPLAY") {
        var geom = lastAction[1].getGeometry();
        for (let i=0; i<this.displays.length; i++) {
          if(lastAction[1].values_.description == this.displays[i].name) {
            var oldCoords = [
              this.displays[i].x,
              this.info.y_multiplier - this.displays[i].y,
            ];
            geom.setCoordinates(oldCoords);
          }
        }
      } else if (lastAction[0] == "DELETE DISPLAY") {
        this.vectorSourceDisplay.addFeature(lastAction[1]);
      }
    }
  }
  
  //Function to remove a feature from the parking info
  deleteFromFeature(feature) {
    /* var featGeometry = feature.getGeometry() */
    if (feature instanceof Feature) {
      var remID = feature.get("description");
      if(feature.get("featureType") == 'display') {
        console.log(feature)
        this.allDisplays.splice(
          this.allDisplays.indexOf(this.allDisplays.find((x)=> x.name == remID)),
        );
        this.vectorSourceDisplay.removeFeature(feature);
      } else {
        this.info.dot_coordinate.splice(
          this.info.dot_coordinate.indexOf(this.info.dot_coordinate.find((x) => x.name == remID)),
        );
        this.vectorSource.removeFeature(feature);
      }
      
      
    }
  }
  
  //Function to remove interaction with the map
  toolChange(val: string) {
    this.tool = val;
    if (this.tool !== "move") {
      this.map.removeInteraction(this.translate);
    }
    if (this.tool !== "Polygon") {
      this.map.removeInteraction(this.drawPolygon);
    }
  }
  
  //Function to draw polygon
  drawPolygonFeatures() {
    this.map.addInteraction(this.drawPolygon);
  }
  
  //Function to select features
  dragAndDropFeatures() {
    this.map.addInteraction(this.translate);
    document.body.style.cursor = "grab";
  }
  
  //Escape listener to close tool
  @HostListener("document:keydown.escape", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log("Escape pressed");
    this.buttonState();
    this.tool = "none";
    this.selectedFeatures.clear();
  }
  
  //Copy and paste listener based on Ctrl + C and Ctrl + V
  @HostListener("window:keydown", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key == "c") {
      console.log("CTRL + C");
      
      for (let i = 0; i < this.selectedFeatures.getLength(); i++) {
        const nameFeature = this.selectedFeatures.item(i)["values_"]["description"]
        const xCoord = this.selectedFeatures.item(i)["values_"]["geometry"]["flatCoordinates"][0]
        const yCoord = this.selectedFeatures.item(i)["values_"]["geometry"]["flatCoordinates"][1]
        
        this.copiedFeature[i] = new Feature({
          geometry: new Point([xCoord, yCoord]),
          description: nameFeature,
          status: "",
          type: "",
        });
      }
    }
    this.map.on("click", (event) => {
          this.xCoordClick = event.coordinate[0];
          this.yCoordClick = event.coordinate[1];
          this.selectedFeatures.clear();
          console.log("MOUSE COORDINATES ", this.xCoordClick, this.yCoordClick)
        });
    if ((event.ctrlKey || event.metaKey) && event.key == "v") {
      console.log("CTRL +  V");
      for (let i = 0; i < this.copiedFeature.length; i++) {
        var pint = this.copiedFeature[i].getGeometry();
        if(pint instanceof Point){
          pint.setCoordinates([this.xCoordClick,this.yCoordClick])
        }
        this.copiedFeature[i].setGeometry(pint);
        this.registerSingleAction[0] = "ADD";
        this.registerSingleAction[1] = this.copiedFeature[i];
        this.updateEvent();
      }
      this.vectorSource.addFeatures(this.copiedFeature);
      this.copiedFeature = [];
      this.xCoordClick = 0;
      this.yCoordClick = 0;
    }
  }
  
  //Function to update the status of the buttons
  buttonState() {
    document.body.style.cursor = "default";
    this.map.removeInteraction(this.translate);
    this.map.removeInteraction(this.drawPolygon);
  }
  
  //Change the cursor style to add a Dot
  addDot() {
    document.body.style.cursor = "crosshair";
  }
  
  //Change the cursor style to delete a Dot
  deleteDot() {
    document.body.style.cursor = "pointer";
  }
  
  //Change the cursor style to modify a Dot
  modifyDot() {
    document.body.style.cursor = "pointer";
  }
  
  //Function to manage the Save Modal
  saveButton() {
    this.registerModalActions = this.registerAllActions.slice(0);
    document.body.style.cursor = "default";
  }
  
  //Function to update the form values
  computeAddForm(id, section, info) {
    this.addDotForm.controls.parkingIdForm.setValue(id);
    this.addDotForm.controls.sectionNameForm.setValue(section);
    
    const progressiveNum = this.generateDotId(info);
    this.addDotForm.controls.lotNumberForm.setValue(progressiveNum);
    
    this.addDotForm.controls.parkingIdForm.disable();
    this.addDotForm.controls.sectionNameForm.disable();
  }
  
  //Function to update the form values when the button is clicked
  updateForm() {
    this.computeAddForm(this.id, this.section, this.info)
  }

  //Function to update the Add Display form values
  computeAddDisplayForm(id, section, info) {
    this.addDisplayForm.controls.parkingIdDisplayForm.setValue(id);
    this.addDisplayForm.controls.sectionNameDisplayForm.setValue(section);
    
    const progressiveNum = this.generateDisplayNumber(info);
    this.addDisplayForm.controls.displayNumberDisplayForm.setValue(progressiveNum);
    
    this.addDisplayForm.controls.parkingIdDisplayForm.disable();
    this.addDisplayForm.controls.sectionNameDisplayForm.disable();
  }
  
  //Function to update the form values when the button is clicked
  updateDisplayForm() {
    this.computeAddDisplayForm(this.id, this.section, this.info)
  }
  
  //Function to discard a change
  discardChanges(item) {
    this.registerModalActions.splice(this.registerModalActions.indexOf(item), 1);
  }
  
  //Modal to confirm saves
  async saveModification() {
    this.newDotModel = [];
    let newDotClass = new LotPutModel();
    this.updateDotModel = [];
    let updateDotClass = new LotPutModel();
    let updateDisplayBody = new DisplayPutModel();
    for (let i = 0; i < this.registerModalActions.length; i++) {
      if (this.registerModalActions[i][0] === 'ADD') {
        newDotClass = {
          id: this.registerModalActions[i][1].values_.description,
          type: "regular",
          location: {
            x: 0,
            y: 0,
            coordinates: [0 , 0],
            type: "Point"
          },
          area: "-1A",
          authlevel: "user",
          sensorId: "",
          configStatus: "not mapped",
          groups: [""],
          booked: false,
          x: Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[0]),
          y: this.info.y_multiplier - Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[1]),
          display: []
        }
        this.newDotModel.push(newDotClass)
        for(let j = 0; j<this.info.dot_coordinate.length; j++) {
          if(this.info.dot_coordinate[j].name == this.registerModalActions[i][1].values_.description) {
            this.info.dot_coordinate[j].x = Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[0])
            this.info.dot_coordinate[j].y = this.info.y_multiplier - Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[1])
          }
        }
      }
      if (this.registerModalActions[i][0] === 'ADD DISPLAY') {
        let newDisplayBody: DisplayPostModel = {
          name: this.registerModalActions[i][1].values_.description,
          url: '',
          layout: '',
          username: '',
          password: ''
        }
        
        try {
          this.displayService.createDisplay(newDisplayBody)
          this.toast('success', this.registerModalActions[i][1].values_.description, 'Display created successfully')
        } catch(error) {
          this.toast('error', this.registerModalActions[i][1].values_.description, 'Display not created')
        }
        
      }
      if (this.registerModalActions[i][0] === 'DELETE') {
        try {  
          if (this.newDotModel.length != 0) {
            try {
              await this.lotService.addNewLot(this.id, this.section,this.newDotModel)
              this.newDotModel = [];
              this.toast('success','', 'Dot created successfully')
            } catch (error) {
              this.toast('error', '', 'Dot not created')
            }
          }
            await this.lotService.deleteLot(this.registerModalActions[i][1].values_.description, this.id, this.section)
            this.toast('success', this.registerModalActions[i][1].values_.description, 'Dot deleted successfully')
        } catch (error) {
          this.toast('error', this.registerModalActions[i][1].values_.description, 'Dot not deleted')
          }
      }
      if (this.registerModalActions[i][0] === 'REPOSITION' || this.registerModalActions[i][0] === 'MOVE')  {
        let repositionedLot;
        for (let j=0; j<this.info.lot_coordinate.length; j++) {
          if(this.info.lot_coordinate[j].id == this.registerModalActions[i][1].values_.description) {
            repositionedLot = this.info.lot_coordinate[j]
          }
        }
        
        updateDotClass = {
          id: repositionedLot.id,
          type: repositionedLot.type,
          location: repositionedLot.location,
          area: repositionedLot.area,
          authlevel: repositionedLot.authlevel,
          sensorId: repositionedLot.sensor_id,
          configStatus: repositionedLot.config_status,
          groups: repositionedLot.groups,
          booked: repositionedLot.booked,
          x: Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[0]),
          y: this.info.y_multiplier - Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[1]),
          display: []
        }
        
        this.updateDotModel.push(updateDotClass)
      }
      if (this.registerModalActions[i][0] === 'MOVE DISPLAY') {
        let movedDisplay;
        
        for (let j=0; j<this.displays.length; j++) {
          if(this.displays[j].name == this.registerModalActions[i][1].values_.description) {
            movedDisplay = this.displays[j]
          }
        }
        
        updateDisplayBody = {
          name: movedDisplay.name,
          url: movedDisplay.url,
          layout: movedDisplay.layout,
          username: movedDisplay.username,
          password: movedDisplay.password,
          id: movedDisplay.id,
          expirationDate: movedDisplay.expirationDate,
          sessionId: movedDisplay.sessionId,
          status: movedDisplay.status,
          pubStatus: movedDisplay.pubStatus,
          loginStatus: movedDisplay.loginStatus,
          groups: movedDisplay.group,
          mac: movedDisplay.mac,
          btia: movedDisplay.btia,
          x: Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[0]),
          y: this.info.y_multiplier - Math.round(this.registerModalActions[i][1].values_.geometry.flatCoordinates[1]),
          parkingId: movedDisplay.parkingId,
          value: movedDisplay.value
        }
        
        try {
          this.displayService.updateDisplay(movedDisplay.id, updateDisplayBody).toPromise()
          this.toast('success','', 'Display updated successfully')
        } catch(error) {
          this.toast('error','', 'Display not updated')
        }
      }
      if (this.registerModalActions[i][0] === 'DELETE DISPLAY') {
        let deletedDisplay;
        
        for (let j=0; j<this.displays.length; j++) {
          if(this.displays[j].name == this.registerModalActions[i][1].values_.description) {
            deletedDisplay = this.displays[j]
          }
        }
        
        try {
          this.displayService.deleteDisplay(deletedDisplay.id)
          this.toast('success','', 'Display deleted successfully')
        } catch(error) {
          this.toast('error','', 'Display not deleted')
        }
      }
    }
    if (this.newDotModel.length != 0 && this.updateDotModel.length == 0) {
      try {
        this.lotService.addNewLot(this.id, this.section,this.newDotModel)
        this.toast('success','', 'Dots created successfully')
      } catch (error) {
        this.toast('error', '', 'Dots not created')
      }
    } else if(this.newDotModel.length == 0 && this.updateDotModel.length != 0) {
      try {
        this.lotService.updateLot(this.id, this.section, this.updateDotModel)
        this.toast('success','', 'Dots updated successfully')
      } catch(error) {
        this.toast('error','', 'Dots not updated')
      }
    } else if(this.newDotModel.length != 0 && this.updateDotModel.length != 0) {
      try {
        this.lotService.addNewLot(this.id, this.section,this.newDotModel)
        this.toast('success','', 'Dots created successfully')
      } catch (error) {
        this.toast('error', '', 'Dots not created')
      }
      let deleteADD = []
      for(let i=0; i<this.registerModalActions.length; i++) {
        if(this.registerModalActions[i][0] === 'ADD') {
          deleteADD.push(this.registerModalActions[i])
        }
      }
      for (let j=0; j<deleteADD.length; j++) {
        this.registerModalActions.splice(this.registerModalActions.indexOf(deleteADD[j]), 1)
      }
      this.saveModification()
    }
    this.router.navigate([], { queryParams: { view: "edit" }, queryParamsHandling: 'merge' });
    this.registerAllActions = [];
  }
  
  replaceImage() {
    this.displayConfirmReplaceImage = false;
    this.radiusLot = this.info.radius_lot;
    this.radiusArea = this.info.radius_area;
    this.radiusLotMobile = this.info.radius_lot_mobile;
    this.clusterDistance = this.info.cluster_distance;
  }
  
  // Storing new image 
  onFileSelected(event) {
    this.selectedFile = event[0];
    if(this.selectedFile) {
      this.confirmationMessageReplaceImage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-success"><span class="rb-ic rb-ic-alert-success-filled"></span>Image uploaded successfully</div>');
      this.displayConfirmReplaceImage = true;
    } else {
      this.confirmationMessageReplaceImage = this.sanitized.bypassSecurityTrustHtml('');
      this.displayConfirmReplaceImage = false;
    }
  }

  onReplaceImageChange(event) {
    if(this.radiusLot != this.info.radius_lot || this.radiusArea != this.info.radius_area || this.radiusLotMobile != this.info.radius_lot_mobile || this.clusterDistance != this.info.cluster_distance || this.selectedFile) {
      this.displayConfirmReplaceImage = true;
    } else {
      this.displayConfirmReplaceImage = false;
    }
  }
  
  // Map image replacement function
  confirmReplaceImage() {
    let formData = new FormData();
    let image = this.selectedFile;
    
    var blob = new Blob([image], {type: "image/jpeg"});
    formData.append('parkingId ', this.info.parking_id)
    formData.append('radiusLot', this.radiusLot.toString());
    formData.append('radiusArea', this.radiusArea.toString());
    formData.append('radiusLotMobile', this.radiusLotMobile.toString());
    formData.append('clusterDistance', this.clusterDistance.toString());
    if(image) {
      formData.append('file', blob);
    }
    
    this.imagesService.updateImage(this.info.id, formData).subscribe(res => {
      if(res.ok) {
        this.toast('success', this.info.parking_id, 'Image successfully replaced');
      } else {
        this.toast('error', this.info.parking_id, 'Image not replaced');
      }
    });
    
    setTimeout(()=>{this.reloadPage();},4000);
    
  }
  
  //Toast function
  toast(type: string, id: string, message: string) {
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'info':
        this.toastrService.info(id, message, {
          positionClass: 'toast-bottom-center',
          progressBar: false,
          closeButton: false,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      default:
        break;
    }
  }
  
  addItem(newItem: string, dot) {
    let index = this.info.dot_coordinate.findIndex(
      (element) => element.name === dot.name
    );
    if (index !== -1) {
      this.info.dot_coordinate[index].name = newItem;
    }
  }

  addItemDisplay(newItem: string, display) {
    let index = this.displays.findIndex(
      (element) => element.name === display.name
    );
    if (index !== -1) {
      this.displays[index].name = newItem;
    }
  }
  
  styleObjectDisplay = (display) => {
    
    var displayColor = '#BFC0C2';
    var displayStrokeColor = '#000000';
    var strokeWidth = 6;
    var colorText = "#FFFFFF";
    var colorTextStroke = "#000000";
    var nLotsLabel = "";
    var fontSize = (this.map.getView().getZoom()*13).toString();
    var font = "";
    
    switch (display.get("status")) {
      case "ok": {
        displayColor = '#70BF54';
        nLotsLabel = display.get("value");
        font = fontSize + "px sans-serif";
        break;
      }
      case "alert": {
        displayColor = 'rgb(252,175,23)';
        nLotsLabel = '!';
        colorText = "#000000";
        colorTextStroke = "#FF0000";
        font = fontSize + "px sans-serif";
        break;
      }
      default: {
        displayColor = '#BFC0C2';
        break;
      }
    }
    
    //Text fill style
    var textFill = new Fill({
      color: colorText
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: colorTextStroke,
      width: 1,
    });
    
    //Number of free parking lot
    var textParkingFree = new Text({
      font: font,
      text: nLotsLabel.toString(),
      fill: textFill,
      //stroke: textStroke,
    });
    
    var iconStyleDisplay = new Style({
      image: new RegularShape({
        fill: new Fill({
          color: displayColor,
        }),
        stroke: new Stroke({
          color: displayStrokeColor,
          width: strokeWidth,
        }),
        radius: this.map.getView().getZoom() * (20 / Math.SQRT2),
        radius2: this.map.getView().getZoom() * 20,
        points: 4,
        angle: 0,
        scale: [1, 0.5],
      }),
      text: textParkingFree,
    });
    return iconStyleDisplay;
  }
  
  //Features Styles
  styleObject = (dot) => {
    if (dot.getGeometry() instanceof Polygon) {
      var iconStyle = new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(112, 191, 84, 0.7)",
        }),
      });
      return iconStyle;
    }
    
    var lotColor = "#BFC0C2";
    var lotStrokeColor = "#BFC0C2";
    var strokeWidth = 2;
    switch (dot.get("status")) {
      case "free": {
        lotColor = "#70BF54";
        break;
      }
      case "busy": {
        lotColor = "#E11F26";
        break;
      }
      case "alert": {
        lotColor = "#FCAF17";
        break;
      }
      default: {
        lotColor = "#BFC0C2";
        break;
      }
    }
    lotStrokeColor = "black";
    
    var lotTypeLabel = "";
    switch (dot.get("type")) {
      case "regular": {
        lotTypeLabel = "";
        break;
      }
      case "management": {
        lotTypeLabel = "M";
        break;
      }
      case "guest": {
        lotTypeLabel = "G";
        break;
      }
      case "handicap": {
        lotTypeLabel = "H";
        break;
      }
      case "e-vehicle": {
        lotTypeLabel = "e";
        break;
      }
      case "short-term": {
        lotTypeLabel = "s-t";
        break;
      }
      default: {
        lotTypeLabel = "";
        break;
      }
    }
    
    //Text fill style
    var textFill = new Fill({
      color: "#fff",
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: "rgba(0, 0, 0, 0.6)",
      width: 3,
    });
    
    var textParkingFree = new Text({
      font: "20" + "px sans-serif",
      text: lotTypeLabel,
      fill: textFill,
      stroke: textStroke,
    });
    
    var iconStyle = new Style({
      image: new Circle({
        radius: this.pointFeatureRadius,
        fill: new Fill({
          color: lotColor,
        }),
        stroke: new Stroke({
          color: lotStrokeColor,
          width: strokeWidth,
        }),
      }),
      text: textParkingFree,
    });
    return iconStyle;
  };
  
  //Manage Parking Spaces Group: enable functions in the "Edit Selected" menu
  displaygroups() {
    this.displaybutton = false;
    this.displayselector = true;
  }
  
  //Manage dot repositioning
  reposition() {
    let defaultValue = this.repositionForm.controls.dotNameRepositionForm.value
    this.isLocated(defaultValue)
  }
  
  //Manage dot move
  selectDotToMove() {
    let selected = this.repositionForm.controls.dotNameRepositionForm.value
    for (let i =0; i<this.repositionDot.length; i++) {
      if(this.repositionDot[i] == selected && i+1 < this.repositionDot.length) {
        this.repositionForm.controls.dotNameRepositionForm.setValue(this.repositionDot[i+1])
      }
    }
    return selected
  }
  
  //Check if dot is already placed
  isLocated(dotName) {
    for (let j=0; j<this.repositionDot.length; j++) {
      if(dotName == this.repositionDot[j]) {
        for (let i = 0; i<this.info.dot_coordinate.length; i++) {
          if(dotName  == this.info.dot_coordinate[i].name) {
            this.displayReposition = true;
            return true;
          }
        }
        this.displayReposition = false;
        return false;
      }
    }
    this.displayReposition = true;
    return true;
  }
  
  //Function to move the selected dots
  moveAllDot() {
		this.translateAll = new Translate({
		  features: this.selectedFeatures
		})
		this.map.addInteraction(this.translateAll);
    
    this.translateAll.on("translateend", (event) => {
      event.features.getArray().forEach(feat => {
        this.registerSingleAction[0] = "MOVE";
        this.registerSingleAction[1] = feat;
        this.updateEvent(); 
        this.toast('success', (feat as any).values_.description ,'Dot moved successfully')
      });
    });
    
  }
  
  search(text: string) {
    if (text === 'error') {
      return throwError('failed');
    }
    
    this.filteredDotList = this.repositionDot.filter(value => value.includes(text));
    return of(this.filteredDotList);
  }
  
  selectValue(value) {
    this.repositionForm.controls.dotNameRepositionForm.setValue(value)
  }
  
  //Function to delete the selected dots
  deleteAllDot() {
    for (let i = 0; i<this.selectedFeatures.getArray().length; i++) {
      this.deleteEvent(this.selectedFeatures.getArray()[i])
    }
  }
  
  //Function to close Edit Parking Space Modal
  eventHandlerFunction(valueEmitted) {
    if (valueEmitted === true) {
      var popOverElements = [];
      popOverElements = this.ngbPopoverInfo.toArray();
      for (let i=0; i<popOverElements.length; i++) {
        var popOverComponent = popOverElements[i]
        popOverComponent.modalService.close();
      }
    }
  }
  
  //Function to close Edit Display Modal
  eventHandlerFunctionDisplay(valueEmitted) {
    if (valueEmitted === true) {
      var popOverElements = [];
      popOverElements = this.ngbPopoverInfoDisplay.toArray();
      for (let i=0; i<popOverElements.length; i++) {
        var popOverComponent = popOverElements[i]
        popOverComponent.modalService.close();
      }
    }
  }
  
  //Function to change the type of the selected parking spaces
  selectedType() {
    this.typeNameForm.setValue(this.typeList[0])
    this.selectedStatesType = {}
    this.selectedItemType = this.selectedFeatures.getArray();
    for (let i = 0; i < this.selectedItemType.length; i++) {
      this.selectedStatesType[this.selectedItemType[i].values_.description] = true;
    }
    this.updateStatesType(this.selectedStatesType)
  }
  
  //Change type selected Parking Spaces: update the list of parking spaces
  updateStatesType(states) {
    console.log('states', states);
    this.keys = Object.keys(states);
    this.selectedListType = this.keys.filter(function(key){
      return states[key]
    })
  }
  
  //Change type selected Parking Spaces: remove the parking space from the list
  removeParkingSpace(lot: any): void {
    const index = this.selectedListType.indexOf(lot);
    
    if (index >= 0) {
      this.selectedListType.splice(index, 1);
    }
    
    this.selectedStatesType = {}
    for (let i = 0; i < this.selectedListType.length; i++) {
      this.selectedStatesType[this.selectedListType[i]] = true;
    }
    this.updateStatesType(this.selectedStatesType)
  }
  
  //Patch to update the type of selected Parking Spaces
  async changeType() {
    let body = this.typeNameForm.value
    
    for(let i = 0; i<this.selectedListType.length; i++) {
      await this.lotService.updateLotType(this.selectedListType[i], body).subscribe(
        data => {
          this.toast('success',this.selectedListType[i], 'Parking Space type updated successfully')
        },
        (error) => {
          this.toast('error',this.selectedListType[i], 'Parking Space type not updated')
        })
    }
    
    this.selectedFeatures.clear();
    this.buttonState();
    this.reloadPage();
  }
  
  //Function to refresh the page after updates
  reloadPage() {
    let parkingId = this.route.snapshot.params.id;
    let section = this.route.snapshot.queryParams.section;
    let currentRoute = "/parking/" + parkingId;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'edit', section: section }, queryParamsHandling: 'merge' }); // navigate to same route
    });
  }
  
  statusTStyle(value) {
    let res={color:"black"};
    res.color=this.t.find(value).toString();
    return res;
  }
  
  addNewItem(value: any) {
    this.newItemEvent.emit(value);
  }
  
  addNewItemDisplay(value: any) {
    this.newItemEventDisplay.emit(value);
  }
  
}