import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleService {
  
  constructor() { }
  
  computeHeight(percentage) {
    var headerHeight = document.getElementsByTagName('rb-full-header')[0].getElementsByTagName('header')[0].offsetHeight;
    var footerHeight = document.getElementsByTagName('rb-footer-nav')[0].getElementsByTagName('footer')[0].offsetHeight;
    
    if (window.matchMedia("(min-width: 600px)").matches) {
      var sum = (headerHeight + footerHeight) + percentage * (headerHeight + footerHeight) / 100;
    } else {
      var sum = (headerHeight);
    }
    var container = document.getElementById('content');
    container.setAttribute("style", "height: calc(100vh - " + sum + "px)");
  }
  
  computeHeightList() {
    var height = document.getElementById('height').scrollHeight;
    var content = document.getElementById('content');
    content.setAttribute("style", "height:" + height + "px");
  }
  
  computeMinHeight() {
    var headerHeight = document.getElementsByTagName('rb-full-header')[0].getElementsByTagName('header')[0].offsetHeight;
    var footerHeight = document.getElementsByTagName('rb-footer-nav')[0].getElementsByTagName('footer')[0].offsetHeight;
    var sum = (headerHeight + footerHeight);
    var container = document.getElementById('drawer-container');
    container.setAttribute("style", "min-height: calc(100vh - " + sum + "px)");
  }
  
  computeLoginHeight() {
    var headerHeight = document.getElementsByTagName('rb-full-header')[0].getElementsByTagName('header')[0].offsetHeight;
    var footerHeight = document.getElementsByTagName('rb-footer-nav')[0].getElementsByTagName('footer')[0].offsetHeight;
    var sum = (headerHeight + footerHeight);
    var container = document.getElementById('login');
    container.setAttribute("style", "height: calc(100vh - " + sum + "px)");
  }
  
}
