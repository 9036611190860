import { Component, Input, AfterViewInit, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { _fixedSizeVirtualScrollStrategyFactory } from "@angular/cdk/scrolling";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Subscription, throwError, timer } from "rxjs";
import {catchError} from 'rxjs/operators'

import { environment } from "src/environments/environment";

import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import OSM from "ol/source/OSM";
import * as olProj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Style, Text, Stroke } from "ol/style";
import { Cluster } from "ol/source";
import * as olExtent from "ol/extent";
import ZoomToExtent from 'ol/control/ZoomToExtent';

import { User } from 'src/app/_shared/models/user';
import { Parking } from "src/app/_shared/models/parking";

import { ParkingService } from '../../_services/parking.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ImagesService } from '../../_services/images.service';
import { StyleService } from "src/app/_services/style.service";

import { NgbPopover } from "@ng-bootstrap/ng-bootstrap/popover/popover";

const pointFeatureRadius: number = 20;

@Component({
  selector: "app-streetmap",
  templateUrl: "./streetmap.component.html",
  styleUrls: ["./streetmap.component.scss"],
})
export class StreetmapComponent implements AfterViewInit {
  
  @Input() parkings: Parking[];
  @ViewChildren("popUpInfo") ngbPopoverInfo!: QueryList<NgbPopover>;
  labels: any[];
  map: Map;
  mapLoaded = false;
  center : [number, number];
  vectorSource: any;
  vectorLayer: VectorLayer;
  ovLevel: any[];
  features: any[];
  parking_info: String;
  public user: User;
  parkID: any;
  computedZoom : number;
  parsubs: Subscription;
  userCanEditStreetmap: boolean = false;
  
  constructor(
    public http: HttpClient,
    private park: ParkingService,
    private elementRef: ElementRef,
    public auth: AuthenticationService,
    public imagesService: ImagesService,
    private style: StyleService
  ) {
    this.labels = [];
    this.ovLevel = [];
    this.center = [0, 0];
    this.features = [];
    this.parking_info = "Ciao";
  }
  
  async ngAfterViewInit() {
    this.userCanEditStreetmap = (await this.auth.isAdmin() || await this.auth.isOwner())
    this.park.getParkingId('');
    this.user = this.auth.getUser();
    
    // Calcolo della posizione min e max di Parkings Location X e Y
    const maxLocationX = Math.max.apply(
      Math,
      this.parkings.map(function (o) {
        return o.location.x;
      })
    );
    const minLocationX = Math.min.apply(
      Math,
      this.parkings.map(function (o) {
        return o.location.x;
      })
    );
    const maxLocationY = Math.max.apply(
      Math,
      this.parkings.map(function (o) {
        return o.location.y;
      })
    );
    const minLocationY = Math.min.apply(
      Math,
      this.parkings.map(function (o) {
        return o.location.y;
      })
    );
    
    this.parkings.forEach((parking, index) => {
      console.log(parking);
      console.log(index);
      const pos = [parking.location.y, parking.location.x];
      
      const singleFeature = new Feature({
        geometry: new Point(olProj.fromLonLat(pos)),
        description: parking.name,
        park_id: parking.id,
        imageExists: parking.imageExists,
        sections: parking.section.length,
        firstsection: parking.section[0].name,
        parking: parking,
      });
      singleFeature.set("value", parking.n_lot_free);
      this.features.push(singleFeature);
      
      this.center[0] = this.center[0] + (maxLocationX + minLocationX)/2; // x location
      this.center[1] = this.center[1] + (maxLocationY + minLocationY)/2; // y location
      
    });
    
    this.vectorSource = new VectorSource({
      features: this.features,
    });
    
    this.vectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 30,
        source: this.vectorSource,
      }),
      style: this.styleFunction,
    });
    
    this.center[0] = this.center[0] / this.parkings.length;
    this.center[1] = this.center[1] / this.parkings.length;

    var mapwidth = document.getElementById('map');
    if(maxLocationY < 0 || minLocationY <0) {
      var maxPixelX = (((maxLocationY+180)*(mapwidth.clientWidth/360)));
      var minPixelX = (((minLocationY+180)*(mapwidth.clientWidth/360)));
    } else {
      var maxPixelX = (((maxLocationY)*mapwidth.clientWidth)/360);
      var minPixelX = (((minLocationY)*mapwidth.clientWidth)/360);
    }
    var pixelDiff = maxPixelX-minPixelX;
    var longDiff = (maxLocationY+180)-(minLocationY+180);


    // Function to calculate the zoom based on the position of xMin and xMax
    function getMinZoom(center: number) {
      var width = (center + (center - (minPixelX)) + ((maxPixelX) - center));
      var scaleFactor = width*256/mapwidth.clientWidth
      if (longDiff<100) {
        return Math.ceil(Math.LOG2E * Math.log(width));
      } else {
        return Math.ceil(Math.LOG2E * Math.log(width/scaleFactor));
      }
      
    }
    
    this.computedZoom=getMinZoom(pixelDiff)
    
    this.map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.vectorLayer,
      ],
      overlays: this.ovLevel,
      view: new View({
        center: olProj.fromLonLat([
          isNaN(this.center[1]) ? 16 : this.center[1] ,
          isNaN(this.center[0]) ? 44 : this.center[0]
        ]),
        zoom: isNaN(this.computedZoom) || !isFinite(this.computedZoom) ? 1 : this.computedZoom,
      }),
    });
    // Zoom Control Button
    var zoom = document.createElement("span");
    zoom.innerHTML = '<span class="rb-ic rb-ic-arrows-expand-object"></span>';
    var zoomControl = new ZoomToExtent({
      extent: this.map.getView().calculateExtent(),
      label: zoom,
    });
    this.map.addControl(zoomControl);
    
    this.map.once("rendercomplete", (event) => {
      this.mapLoaded = true;
    });
    
    this.parsubs = timer(0, 3000).subscribe(async () => {
      console.log("polling parkings");
      this.http
        .get(environment.apiUri + "parkings/allinfo")
        .subscribe((data: Parking[]) => {
          this.parkings = data;
          this.parkings.forEach((park) => {
            let singleFeature = this.features.find(
              (feat) => feat.get("park_id") === park.id
            );
            if (singleFeature) {
              let index = this.features.indexOf(singleFeature);
              this.features[index].set("value", park.n_lot_free);
            }
          });
          this.vectorLayer.setStyle(this.styleFunction);
        });
    });
    
    /*
    zoomCluster function to zoom on Cluster click
    */
    var zoomCluster = function (feature, map) {
      var features = feature.get("features");
      if (features.length > 1) {
        var extent = olExtent.createEmpty();
        features.forEach(function (feature) {
          olExtent.extend(extent, feature.getGeometry().getExtent());
        });
        map.getView().fit(extent, map.getSize());
        map.getView().setZoom(map.getView().getZoom() - 2);
        return true;
      } else {
        return false;
      }
    };
    
    // Function to compute dynamically the height of the map
    this.style.computeHeight(0);
    this.map.updateSize();
    /*
    on map click:
    - do not do anything if is a empty point
    - zoom on cluster
    - display popup if is a feature point
    */
    this.map.on("click", (event) => {
      var feature = this.map.forEachFeatureAtPixel(
        event.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature) {
        if (!zoomCluster(feature, this.map)) {
          var parkID = feature.get("features")[0].get("park_id");
          var popupComponent = document.getElementById(parkID);
          
          popupComponent.style.setProperty("display", "block");
          popupComponent.style.setProperty("position", "absolute");
          var pointerEvent = event.originalEvent;
          
          var pixelXY = this.map.getPixelFromCoordinate(
            feature.get("features")[0].getGeometry().getCoordinates()
          );
          
          if (pointerEvent) {
            popupComponent.style.setProperty(
              "top",
              (pixelXY[1] - pointFeatureRadius).toString() + "px"
            );
            popupComponent.style.setProperty(
              "left",
              pixelXY[0].toString() + "px"
            );
            var popOverElements = [];
            popOverElements = this.ngbPopoverInfo.toArray();
            var popOverComponent = popOverElements.find(
              (x) => x._elementRef.nativeElement.id == parkID
            );
            popOverComponent.open();
          }
        }
      }
    });
  }

  /*
    Set the Style of the clustered feature
  */
  styleFunction(feature) {
    //get the amount of free parking into a cluster
    var sum = 0;
    feature.get("features").forEach((feat) => {
      sum += feat.get("value");
    });
    
    //Text fill style
    var textFill = new Fill({
      color: "#fff",
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: "rgba(0, 0, 0, 0.6)",
      width: 3,
    });
    
    //Number of free parking lot
    var textParkingFree = new Text({
      font: "20" + "px sans-serif",
      text: sum.toString(),
      fill: textFill,
      stroke: textStroke,
    });
    
    //Name of each Parking, no name if is a cluster
    var parkingName = "";
    if (feature.get("features").length == 1) {
      parkingName = feature.get("features")[0].get("description");
    }
    var textLabel = new Text({
      font: "18" + "px sans-serif",
      text: parkingName,
      offsetY: -30,
      overflow: true,
      fill: new Fill({ color: "white" }),
      stroke: new Stroke({ color: "black", width: 3 }),
    });
    
    //Color strategy of each Parking Feature, red if no free parking available, green otherwise
    var parkingColor = "#70BF54";
    if (sum > 0) {
      parkingColor = "#70BF54";
    } else {
      parkingColor = "#E11F26";
    }
    
    //Parking Free Label
    var iconStyle = new Style({
      text: textParkingFree,
    });
    
    //Parking Feature with Parking Name Label
    var iconStyle2 = new Style({
      image: new Circle({
        radius: pointFeatureRadius,
        fill: new Fill({
          color: parkingColor,
        }),
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
      }),
      text: textLabel,
    });
    return [iconStyle, iconStyle2];
  }
  
  ngOnDestroy() {
    this.parsubs.unsubscribe();
  }
  
}