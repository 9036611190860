import { LotGetModel } from "./lot";
import { DisplayGetModel } from "./display";

export class ImageInfo {
    id: string;
    data: {
        type: string;
        data: [string]
    };
    parking_id:  string;
    radius_lot : any;
    radius_lot_mobile: any;
	radius_area : any;
    x_multiplier: number;
    y_multiplier: number;
    dot_coordinate: [
        {
            name:string;
            x:number;
            y:number;
            type:string;
        }
    ];
    area_coordinate: [
        {
            name: string;
            x: number;
            y: number;
            n_lot_free: number;
            n_lot_busy: number;
            n_lot_alert: number;
            n_lot: number;
        }
    ];
    lot_coordinate: LotGetModel[];
    display_coordinate: DisplayGetModel[];
    cluster_distance: any;
}
