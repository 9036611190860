import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ParkingMgmtRoutingModule } from './parking-mgmt-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RbUiComponentsModule, FormFieldsModule, BreadcrumbsModule, ModalModule, TabPanelModule } from '@inst-iot/bosch-angular-ui-components';
import { MaterialModule } from '../_shared/modules/material.module';

import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

import { ParkingMgmtComponent } from './parking-mgmt/parking-mgmt.component';
import { DisplayComponent } from './display/display.component';
import { DisplaysListComponent } from './displays-list/displays-list.component';
import { EditdisplayComponent } from './editdisplay/editdisplay.component';
import { EditgroupsComponent } from './editgroups/editgroups.component';
import { EditlotComponent } from './editlot/editlot.component';
import { EventHistoryComponent } from './event-history/event-history.component';
import { FloorInfoComponent } from './floor-info/floor-info.component';
import { HeatmapMapLayerComponent } from './heatmap-map-layer/heatmap-map-layer.component';
import { LotComponent } from './lot/lot.component';
import { LotChartsComponent } from './lot-charts/lot-charts.component';
import { MapDisplayComponent } from './map-display/map-display.component';
import { MapEditComponent } from './map-edit/map-edit.component';
import { MapLayerComponent } from './map-layer/map-layer.component';
import { MapLotComponent } from './map-lot/map-lot.component';
import { ParkingComponent } from './parking/parking.component';
import { SectionInfoComponent } from './section-info/section-info.component';
import { SensorStatusComponent } from './sensor-status/sensor-status.component';
import { UploadSensorsComponent } from './upload-sensors/upload-sensors.component';

import { AnalyticsComponent } from '../_shared/components/analytics-mgmt/analytics/analytics.component';
import { AnalyticsDailyComponent } from '../_shared/components/analytics-mgmt/analytics-daily/analytics-daily.component';
import { AnalyticsDurationsComponent } from '../_shared/components/analytics-mgmt/analytics-durations/analytics-durations.component';
import { AnalyticsWeeklyComponent } from '../_shared/components/analytics-mgmt/analytics-weekly/analytics-weekly.component';
import { AnalyticsDaysRangeComponent } from '../_shared/components/analytics-mgmt/analytics-days-range/analytics-days-range.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

@NgModule({
  declarations: [
    ParkingMgmtComponent,
    DisplayComponent,
    DisplaysListComponent,
    EditdisplayComponent,
    EditgroupsComponent,
    EditlotComponent,
    EventHistoryComponent,
    FloorInfoComponent,
    HeatmapMapLayerComponent,
    LotComponent,
    LotChartsComponent,
    MapDisplayComponent,
    MapLayerComponent,
    MapEditComponent,
    MapLotComponent,
    ParkingComponent,
    SectionInfoComponent,
    SensorStatusComponent,
    UploadSensorsComponent,
    AnalyticsComponent,
    AnalyticsDailyComponent,
    AnalyticsDurationsComponent,
    AnalyticsWeeklyComponent,
    AnalyticsDaysRangeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ParkingMgmtRoutingModule,
    NgbModule,
    RbUiComponentsModule,
    FormFieldsModule,
    ModalModule,
    TabPanelModule,
    MaterialModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class ParkingMgmtModule { }
