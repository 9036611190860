import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "src/environments/environment";

import { LotGetModel } from "../_shared/models/lot";

@Injectable({
  providedIn: "root",
})
export class LotService {
  
  LotsInfo: any;
  lots: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
    this.lots = new BehaviorSubject<LotGetModel>(null);
    this.LotsInfo = new BehaviorSubject<any>(null);
  }
  
  // Returns lot info
  retriveLot(id) {
    return this.http.get(environment.apiUri+"v2/lots/"+id).toPromise().then(data=>{
      console.log("lot "+id+" retrive");
      this.lots.next(data);
    });
  }
  
  // API /v2/lots/{parkingId}/{sectionName} - Post to add new lots
  addNewLot(id, sectionName, body) {
    return this.http.post(environment.apiUri + "v2/lots/" + id + "/" + sectionName, body).toPromise();
  }
  
  // API /lots/{id}/ - DELETE to delete a lot
  deleteLot(lotId, parkingId, sectionName) {
    return this.http.delete(environment.apiUri + "lots/" + lotId + "?parking_id=" + parkingId + '&section=' + sectionName).toPromise();
  }
  
  // Returns lot info
  retriveLotById(id) {
    return this.http.get(environment.apiUri+"v2/lots/"+id);
  }
  
  //Update Parking Space Type
  updateLotType(id, body) {
    return this.http.patch(environment.apiUri + "lots/" + id + "/type", body);
  }
  
  //Update Parking Space
  updateLot(parkingId, sectionName, body) {
    return this.http.put(environment.apiUri + "v2/lots/" + parkingId + "/" +  sectionName, body).toPromise();
  }
  
}
