import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard as AuthGuard } from '../authentication/_guards/auth-guard.guard'
import { ParkingComponent } from './parking/parking.component';
import { ParkingMgmtComponent } from './parking-mgmt/parking-mgmt.component';

const PARKINGMGMTROUTES: Routes = [
  
  {
      path: '', component: ParkingMgmtComponent, canActivate: [AuthGuard],
          children: [
            { path: 'parking/:id', component: ParkingComponent, canActivate: [AuthGuard],data: {title: 'Parking'}},
          ]
    },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(PARKINGMGMTROUTES)
  ],
  exports:[RouterModule]
})
export class ParkingMgmtRoutingModule { }