import { Injectable, Optional } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private authStorage: OAuthStorage,
        @Optional() private moduleConfig: OAuthModuleConfig
    ) { }

    private checkUrl(url: string): boolean {
        let found = this.moduleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
        return !!found;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
        // add authorization header with jwt token if available
        /*let token = this.oauthService.getAccessToken();
        if (token && request.url !== this.oauthService.get) {
            // console.log("add JWT token header ")
            request = request.clone({
                setHeaders: {
                    Authorization: token,
                }
            });
        }
        return next.handle(request);*/
        let url = req.url.toLowerCase();

        if (!this.moduleConfig) return next.handle(req);
        if (!this.moduleConfig.resourceServer) return next.handle(req);
        if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);
        if (!this.checkUrl(url)) return next.handle(req);

        let sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;

        if (sendAccessToken) {

            let token = this.authStorage.getItem('access_token');
            let header = token;

            let headers = req.headers
                .set('Authorization', header);

            req = req.clone({ headers });
        }

        return next.handle(req);
    }
}