import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { AnalyticsService } from 'src/app/_services/analytics.service';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';

const colors: Record<string, EventColor> = {
  busy: {
    primary: '#AD2121',
    secondary: '#FAE3E3',
  },
  alert: {
    primary: '#E3BC08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss']
})

export class EventHistoryComponent implements OnChanges {
  @Input() lotId: string;
  
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  
  durationObj: any;
  alertObj: any;
  
  constructor(private analytics: AnalyticsService) {}
  
  async ngOnChanges() {
    this.events=[];
    var today = new Date();
    var start = new Date(today.setMonth(today.getMonth()-6)).toISOString();
    var end = new Date().toISOString();
    
    await this.analytics.getLotDuration(this.lotId, start, end, "HOUR").then((x)=>(this.durationObj=x))
    
    this.durationObj.durations.forEach((item)=>{
      let startDate = item.start.replace('T', ' ').slice(0, -5);
      let endDate = item.end.replace('T', ' ').slice(0, -5);
      let title = '<div><b>Status:</b> Busy</div><div><b>Start:</b> '+ startDate +'</div><div><b>End:</b> ' + endDate + '</div>'
      let event: CalendarEvent = {
        start: new Date(item.start),
        end: new Date(item.end),
        title: title,
        color: { ...colors.busy },
      }
      this.events.push(event)
    })
    
    await this.analytics.getAlerts(this.lotId, start, end).then((x)=>(this.alertObj=x))
    
    this.alertObj.forEach((item)=>{
      if(Object.keys(item.end).length === 0) {
        let startDate = item.start.timestamp.replace('T', ' ').slice(0, -5)
        let endDate = new Date().toISOString().replace('T', ' ').slice(0, -5)
        let title = '<div><b>Status:</b> Alert</div><div><b>Start:</b> '+ startDate +'</div><div><b>End:</b> ' + endDate + '</div>'
        let event: CalendarEvent = {
          start: new Date(item.start.timestamp),
          end: new Date(),
          title: title,
          color: {...colors.alert}
        }
        this.events.push(event)
      } else {
        let startDate = item.start.timestamp.replace('T', ' ').slice(0, -5)
        let endDate = item.end.timestamp.replace('T', ' ').slice(0, -5)
        let title = '<div><b>Status:</b> Alert</div><div><b>Start:</b> '+ startDate +'</div><div><b>End:</b> ' + endDate + '</div>'
        let event: CalendarEvent = {
          start: new Date(item.start.timestamp),
          end: new Date(item.end.timestamp),
          title: title,
          color: {...colors.alert}
        }
        this.events.push(event)
      }
    })
    
    this.refresh.next()
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  
  setView(view: CalendarView) {
    this.view = view;
  }
  
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  
}
