export class Parking {
    id: string;
    name: string;
    address: string;
    location: {
        x: number;
        y: number;
        type: string;
        coordinates: [number, number];
    };
    section: [
        {
            name: string;
            lots: string[];
            display_id: string[];
        }
    ];
    groups: string[];
    n_lot: number;
    n_lot_free?: number;
    n_lot_busy?: number;
    n_lot_alert?: number;
    imageExists?: boolean = true;
}

export class ParkingStatus {
    section: {
        id: string;
        n_lot: number;
        n_lot_free: number;
        n_lot_busy: number;
        n_lot_alert: number;
    };
    types: [
        {
            _id: string;
            n_lot: number;
            n_lot_free: number;
        }
    ]
}