import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { User } from 'src/app/_shared/models/user';
import { Parking } from 'src/app/_shared/models/parking';

import { ParkingService } from '../../_services/parking.service';
import { ImagesService } from '../../_services/images.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  
  parkings: Parking[];
  view: string;
  public user: User;
  
  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    private park: ParkingService,
    public imagesService: ImagesService,
    public auth: AuthenticationService,) { }
  
  async ngOnInit() {
    this.user = this.auth.getUser();
    
    this.http.get(environment.apiUri + "parkings/allinfo").subscribe(async (data: Parking[]) => {
      this.parkings = data;
      for (let index = 0; index < this.parkings.length; index++) {
        var parkImagesId = "";
        await this.park.retriveParking(this.parkings[index].id);
        this.park.parking.subscribe((data) => {
          parkImagesId = this.parkings[index].id + "-" + data.section[0].name;
        });
        await this.imagesService.retriveImagesExists(parkImagesId)
        .then(
          (data) => {
            this.parkings[index].imageExists = true;
          },
          (err) => {
            this.parkings[index].imageExists = false;
          }
        );
      }
      
      this.route.queryParams.subscribe(par => {
        if (par.view == null) {
          this.router.navigate([], { queryParams: { view: "map" }, queryParamsHandling: 'merge' });
        }
        this.view = par.view;
      });
    });
    
  }
  
}