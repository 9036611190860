import { OnInit, Component, QueryList, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { timer, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Collection, Feature, View } from 'ol';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Cluster } from "ol/source";
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import { getCenter } from "ol/extent";
import Map from 'ol/Map';
import * as olExtent from "ol/extent";
import ZoomToExtent from 'ol/control/ZoomToExtent';

import { ImageInfo } from 'src/app/_shared/models/image';
import { DisplayGetModel } from 'src/app/_shared/models/display';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from 'src/app/_services/parking.service';
import { TypeService } from 'src/app/_services/type.service';
import { ImagesService } from 'src/app/_services/images.service';
import { DisplaysService } from 'src/app/_services/displays.service';
import { StyleService } from 'src/app/_services/style.service';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { MatSidenav } from '@angular/material/sidenav';
import { RbSwitchChange } from '@inst-iot/bosch-angular-ui-components/atoms/form-fields/form-switch/form-switch.component';

const lot_type = "lot";

@Component({
  selector: 'app-map-layer',
  templateUrl: './map-layer.component.html',
  styleUrls: ['./map-layer.component.scss']
})

export class MapLayerComponent implements OnInit {
  @ViewChildren('popUpInfo') ngbPopoverInfo!: QueryList<NgbPopover>;
  @ViewChildren('popUpDisplayInfo') ngbPopoverDisplayInfo!: QueryList<NgbPopover>;
  @Input() parkingdrawer: MatSidenav;
  @Output() newItemEvent = new EventEmitter<any>();
  
  map: Map;
  id: string;
  subs: Subscription;
  parsubs: Subscription;
  viewsubs: Subscription;
  section: string = "";
  view: string;
  info: ImageInfo;
  url: string;
  lots: any;
  areas: any;
  extent: any;
  features: any;
  vectorSource: VectorSource;
  vectorLayer: VectorLayer;
  selectedFeatures: Collection<Feature<Geometry>>;
  pointFeatureRadius: number = 12;
  originalFeatureRadius: number;
  cluster_distance: number = 20;
  radiusScaleOnZoom = 0.43;// to adjusting
  retriveImages: string;
  getImages: string;
  parkingId: string;
  images: any;
  parkImagesId: string;
  getUrl: string;
  mapLoaded = false;
  extentHeight: number;
  extentWidth: number;
  projection: Projection;
  
  //Display Variables
  displaySubscription: Subscription;
  displays: DisplayGetModel[] = [];
  displaysSub: DisplayGetModel[] = [];
  displaysFeatures: any;
  vectorSourceDisplay: VectorSource;
  vectorLayerDisplay: VectorLayer;
  showDisplay: boolean = true;
  
  constructor(
    public http: HttpClient,
    private park: ParkingService,
    public auth: AuthenticationService,
    public types: TypeService,
    private route: ActivatedRoute,
    public imagesService: ImagesService, // Injecting the Service
    public displayService: DisplaysService,
    private style: StyleService
  ) {
    this.features = [];
    this.displaysFeatures = [];
  }
  
  ngOnDestroy() {
    console.log('unsuscribe polling');
    
    if (this.subs) {
      this.subs.unsubscribe();
    }

    if (this.displaySubscription) {
      this.displaySubscription.unsubscribe();
    }

    this.parsubs.unsubscribe();
    
    if (this.viewsubs) {
      this.viewsubs.unsubscribe();
    }
  }
  
  async ngOnInit() {
    console.log("load map layer");
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par: any) => {
      this.view = par.view;
      this.section = par.section;
      this.parkImagesId = this.id + '-' + this.section;
    });
    
    //Setup a Vector Source
    this.vectorSource = new VectorSource({
      features: [],
    });
    
    //Setup a Vector Layer
    this.vectorLayer = new VectorLayer({
      source: new Cluster({
        distance: this.cluster_distance,
        source: this.vectorSource,
      }),
      style: this.styleObject,
    });
    
    //Setup a Vector Source for Display
    this.vectorSourceDisplay = new VectorSource({
      features: [],
    })
    
    //Setup a Vector Layer for Display
    this.vectorLayerDisplay = new VectorLayer({
      source: this.vectorSourceDisplay,
      style: this.styleObjectDisplay,
    })
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(info => {
      this.info = info as ImageInfo;
      /*
      * insert Feature into openlayer map
      */
      this.extentHeight = this.info.y_multiplier;
      this.extentWidth = this.info.x_multiplier;
      this.pointFeatureRadius = this.info.radius_lot ? this.info.radius_lot : this.pointFeatureRadius;
      this.originalFeatureRadius = this.pointFeatureRadius;
      this.cluster_distance = this.info.cluster_distance ? this.info.cluster_distance : this.cluster_distance;
      this.extent = [0, 0, this.extentWidth, this.extentHeight];
      this.projection = new Projection({
        code: "xkcd-image",
        units: "pixels",
        extent: this.extent,
      });
      this.projection.setWorldExtent(this.extent);
      
      this.info.dot_coordinate.forEach((lot_info, index) => {
        if (lot_info.type === lot_type) {
          const pos = [lot_info.x, (this.extentHeight - lot_info.y)];
          const singleFeature = new Feature({
            geometry: new Point(pos),
            description: lot_info.name,
            status: "",
            type: "regular",
          });
          this.features.push(singleFeature);
        }
      });
      this.vectorSource.addFeatures(this.features);
    });
    
    var sourceStaticImage = new Static({
      imageLoadFunction: (function (image, src) {
        this.imagesService.retriveImages(src)
          .subscribe(data => {
            let reader = new FileReader();
            reader.addEventListener("loadend", () => {
              image.getImage().src = reader.result;
            }, false);
            if (data) {
              reader.readAsDataURL(data);
            }
          }, error => {
            console.log(error);
          });
      }).bind(this),
      url: this.parkImagesId,
      projection: this.projection,
      imageExtent: this.extent
    })
    
    this.map = new Map({
      layers: [
        new ImageLayer({
          source: sourceStaticImage
        }),
      ],
      target: "map-layer",
      view: new View({
        projection: this.projection,
        center: getCenter(this.extent),
        maxZoom: 6,
        zoom: 1,
        extent: [-(0.2*this.extentWidth), -(0.2*this.extentHeight), this.extentWidth+(0.2*this.extentWidth), this.extentHeight+(0.2*this.extentHeight)],
        showFullExtent: true
      })
    });
    
    // Function to compute dynamically the height of the map
    this.style.computeHeight(25);
    this.map.updateSize();
    
    // Zoom Control Button
    var zoom = document.createElement("span");
    zoom.innerHTML = '<span class="rb-ic rb-ic-arrows-expand-object"></span>';
    var zoomControl = new ZoomToExtent({
      extent: [0, 0, this.extentWidth, this.extentHeight],
      label: zoom,
    });
    
    this.map.addControl(zoomControl);
    
    
    /*
    * Poll every 3 seconds to get lot infos
    */
    this.subs = timer(0, 3000).subscribe(() => {
      console.log('polling lots and areas info');
      this.park.retriveLotsInfo(this.id).subscribe((lots: any) => {
        this.lots = lots;
        this.lots.forEach(singleLot => {
          var singleFeature = this.features.find(feat => feat.get("description") === singleLot.id);
          if (singleFeature) {
            var index = this.features.indexOf(singleFeature);
            this.features[index].set("status", singleLot.status);
            this.features[index].set("type", singleLot.type);
            //singleFeature.set("status", singleLot.lot.status);
          }
        });
        this.vectorLayer.setStyle(this.styleObject);
      });
    });
    
    if(this.info.display_coordinate.length>0) {
      /*
      * Insert Display Feature into openlayer map
      */
      this.displays = [];
      this.displays = this.info.display_coordinate;
      
      this.displays.forEach((display_info, index) => {
        console.log('polling displays info');
        const pos = [display_info.x, (this.extentHeight - display_info.y)]
        const singleFeature = new Feature({
          geometry: new Point(pos),
          description: display_info.name,
          status: display_info.status,
          value: display_info.value,
          featureType: 'display',
        })
        this.displaysFeatures.push(singleFeature);
      })
      this.vectorSourceDisplay.addFeatures(this.displaysFeatures);
    }
    
    if(this.displays.length > 0) {
      this.displaySubscription = timer(0, 3000).subscribe(() => {
        this.displayService.retrieveDisplayByParkingId(this.id).subscribe((displays: any) => {
          this.displaysSub = displays;
          this.displaysSub.forEach(singleDisplay => {
            var singleFeature = this.features.find(feat => feat.get("description") === singleDisplay.name);
            if (singleFeature) {
              var index = this.features.indexOf(singleFeature);
              this.features[index].set("status", singleDisplay.status);
              this.features[index].set("value", singleDisplay.value);
            }
          });
          this.vectorLayerDisplay.setStyle(this.styleObjectDisplay);
        },
        err => {
          this.displaySubscription.unsubscribe();
        });
      })
    }
    
    /*
      zoomCluster function to zoom on Cluster click
    */
    var zoomCluster = function (feature, map) {
      var features = feature.get("features");
      if (features.length > 1) {
        var extent = olExtent.createEmpty();
        features.forEach(function (feature) {
          olExtent.extend(extent, feature.getGeometry().getExtent());
        });
        var zoom = map.getView().getZoom();
        map.getView().fit(extent, map.getSize());
        map.getView().setZoom(zoom + 2);
        return true;
      } else {
        return false
      }
    };
    
    this.map.getView().on('change:resolution', (event) => {
      this.pointFeatureRadius =  this.map.getView().getZoom() * this.originalFeatureRadius * this.radiusScaleOnZoom;
      this.vectorLayer.setStyle(this.styleObject);
      this.vectorLayerDisplay.setStyle(this.styleObjectDisplay);
    });
    
    this.map.getView().fit(sourceStaticImage.getImageExtent());
    
    this.viewsubs = this.route.queryParams.subscribe((par: any) => {
      this.view = par.view;
      
      if (this.view == 'map') {
        this.map.once("rendercomplete", (event) => {
          this.mapLoaded = true;
          this.map.addLayer(this.vectorLayer);
          this.map.addLayer(this.vectorLayerDisplay);
        });
        
        /*
        on map click:
        - do not do anything if is a empty point
        - zoom on cluster
        - display popup if is a feature point
        */
        this.map.on("click", (event) => {
          var feature = this.map.forEachFeatureAtPixel(event.pixel, function (feature) {
            return feature;
          });
          if (feature) {
              if((feature as any).values_.featureType == 'display') {
                var display_name = (feature as any).values_.description;
                var popupComponentDisplay = document.getElementById(display_name);
                
                popupComponentDisplay.style.setProperty('display', 'block');
                popupComponentDisplay.style.setProperty('position', 'absolute');
                var pointerEventDisplay = event.originalEvent;
                
                var pixelXYDisplay = this.map.getPixelFromCoordinate((feature as any).values_.geometry.flatCoordinates);
                
                if (pointerEventDisplay) {
                  // popupComponent.style.setProperty('top', pointerEvent.clientY.toString() + "px");
                  // popupComponent.style.setProperty('left', pointerEvent.clientX.toString() + "px");
                  popupComponentDisplay.style.setProperty('top', (pixelXYDisplay[1]).toString() + "px");
                  popupComponentDisplay.style.setProperty('left', pixelXYDisplay[0].toString() + "px");
                }
                
                var popOverElementsDisplay = [];
                popOverElementsDisplay = this.ngbPopoverDisplayInfo.toArray();
                var popOverComponentDisplay = popOverElementsDisplay.find(x => x._elementRef.nativeElement.id == display_name);
                popOverComponentDisplay.open();
                
              } else {
                if (!zoomCluster(feature, this.map)) {
                  var lot_name = feature.get("features")[0].get("description");
                  var popupComponent = document.getElementById(lot_name);
                
                  popupComponent.style.setProperty('display', 'block');
                  popupComponent.style.setProperty('position', 'absolute');
                  var pointerEvent = event.originalEvent;
                
                  var pixelXY = this.map.getPixelFromCoordinate(feature.get("features")[0].getGeometry().getCoordinates());
                
                  if (pointerEvent) {
                    // popupComponent.style.setProperty('top', pointerEvent.clientY.toString() + "px");
                    // popupComponent.style.setProperty('left', pointerEvent.clientX.toString() + "px");
                    popupComponent.style.setProperty('top', (pixelXY[1] - this.pointFeatureRadius).toString() + "px");
                    popupComponent.style.setProperty('left', pixelXY[0].toString() + "px");
                  }
                
                  var popOverElements = [];
                  popOverElements = this.ngbPopoverInfo.toArray();
                  var popOverComponent = popOverElements.find(x => x._elementRef.nativeElement.id == lot_name);
                  popOverComponent.open();
                }
              }
              
          }
        });
      }
    })
    
  }
  
  styleObjectDisplay = (display) => {
    
    var displayColor = '#BFC0C2';
    var displayStrokeColor = '#000000';
    var strokeWidth = 6;
    var colorText = "#FFFFFF";
    var colorTextStroke = "#000000";
    var nLotsLabel = "";
    var fontSize = (this.map.getView().getZoom()*13).toString()
    var font = "";
    
    switch (display.get("status")) {
      case "ok": {
        displayColor = '#70BF54';
        nLotsLabel = display.get("value");
        font = fontSize + "px sans-serif";
        break;
      }
      case "alert": {
        displayColor = 'rgb(252,175,23)';
        nLotsLabel = '!';
        colorText = "#000000";
        colorTextStroke = "#FF0000";
        font = fontSize + "px sans-serif";
        break;
      }
      default: {
        displayColor = '#BFC0C2';
        break;
      }
    }
    
    //Text fill style
    var textFill = new Fill({
      color: colorText
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: colorTextStroke,
      width: 4,
    });
    
    //Number of free parking lot
    var textParkingFree = new Text({
      font: font,
      text: nLotsLabel.toString(),
      fill: textFill,
      //stroke: textStroke,
    });
    
    var iconStyleDisplay = new Style({
      image: new RegularShape({
        fill: new Fill({
          color: displayColor,
        }),
        stroke: new Stroke({
          color: displayStrokeColor,
          width: strokeWidth,
        }),
        radius: this.map.getView().getZoom() * (20 / Math.SQRT2),
        radius2: this.map.getView().getZoom() * 20,
        points: 4,
        angle: 0,
        scale: [1, 0.5],
      }),
      text: textParkingFree,
    });
    return iconStyleDisplay;
  }
  
  styleObject = (dot) => {
    
    var nFreeLots = 0;
    var nNotStatusLots = 0;
    var nAlertStatusLots = 0;
    var nBusyLots = 0;
    var nLotsLabel = "";
    var lotColor = '#BFC0C2';
    var lotStrokeColor = '#BFC0C2';
    var strokeWidth = 2;
    var colorText = "#fff";
    var colorTextStroke = "rgba(0, 0, 0, 0.6)";
    
    if (dot.get("features").length == 1) {
      switch (dot.get("features")[0].get("status")) {
        case "free": {
          lotColor = '#70BF54';
          break;
        }
        case "busy": {
          lotColor = '#E11F26';
          break;
        }
        case "alert": {
          lotColor = 'rgb(252,175,23)';
          nLotsLabel = '!';
          colorText = "rgb(0, 0, 0)";
          colorTextStroke = "rgb(255, 0, 0)";
          break;
        }
        default: {
          lotColor = '#BFC0C2';
          break;
        }
      }
      lotStrokeColor = 'black';
      
      if (dot.get("features")[0].get("status") != "alert") {
        switch (dot.get("features")[0].get("type")) {
          case "regular": {
            nLotsLabel = '';
            break;
          }
          case "management": {
            nLotsLabel = 'M';
            break;
          }
          case "guest": {
            nLotsLabel = 'G';
            break;
          }
          case "handicap": {
            nLotsLabel = 'H';
            break;
          }
          
          case "e-vehicle": {
            nLotsLabel = 'e';
            break;
          }
          case "short-term": {
            nLotsLabel = 's-t';
            break;
          }
          default: {
            nLotsLabel = '';
            break;
          }
        }
      }
    } else if (dot.get("features").length > 1) {
      console.log(dot.get("features"));
      for (var i = 0; i < dot.get("features").length; i++) {
        if (dot.get("features")[i].get("status") === 'free') {
          nFreeLots++;
        } else if (dot.get("features")[i].get("status") === '') {
          nNotStatusLots++;
        } else if (dot.get("features")[i].get("status") === 'alert') {
          nAlertStatusLots++;
        } else if (dot.get("features")[i].get("status") === 'busy') {
          nBusyLots++;
        }
      }
      strokeWidth = 2;
      lotStrokeColor = 'black';
      if (nFreeLots > 0) {
        lotColor = '#70BF54';
        nLotsLabel = nFreeLots.toString();
      } else if (nNotStatusLots > 0) {
        lotColor = '#BFC0C2';
        nLotsLabel = nNotStatusLots.toString();
      } else if (nAlertStatusLots > 0) {
        lotColor = 'rgb(252,175,23)';
        nLotsLabel = nAlertStatusLots.toString();
      } else if (nBusyLots > 0) {
        lotColor = '#E11F26';
        nLotsLabel = nBusyLots.toString();
      }
    }
    
    //Text fill style
    var textFill = new Fill({
      color: colorText
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: colorTextStroke,
      width: 4,
    });
    
    //Number of free parking lot
    var textParkingFree = new Text({
      font: "20" + "px sans-serif",
      text: nLotsLabel,
      fill: textFill,
      stroke: textStroke,
    });
    
    var iconStyle = new Style({
      image: new Circle({
        radius: this.pointFeatureRadius,
        fill: new Fill({
          color: lotColor,
        }),
        stroke: new Stroke({
          color: lotStrokeColor,
          width: strokeWidth,
        }),
      }),
      text: textParkingFree,
    });
    return iconStyle;
  };
  
  generateRandomString(length = 6) {
    var randomChars = 'abcdef0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  };
  
  generateRandomLastUpdate(sY, sM, sD, eY, eM, eD, startHour, endHour): string {
    var start: any;
    var end: any;
    start = new Date(sY, sM, sD);
    end = new Date(eY, eM, eD);
    
    var date = new Date(+start + Math.random() * (end - start));
    var hour = startHour + Math.random() * (endHour - startHour) | 0;
    date.setHours(hour);
    return date.toUTCString();
  };
  
  generateRandomNumberMaxMin(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1))).toString();
  };
  
  generateRandomNumberMaxMin_toNumber(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1)));
  };
  
  addItem(newItem: any) {
    this.selectedFeatures = newItem;
    this.addNewItem(newItem);
  }
  
  addNewItem(value: any) {
    this.newItemEvent.emit(value);
  }
  
  hideDisplay($event: RbSwitchChange) {
    let enableDisplay = $event.checked;
    let features = this.vectorSourceDisplay.getFeatures();
    if(!enableDisplay) {
      //Hide display
      for (let i = 0; i<features.length; i++) {
        features[i].setStyle(new Style({})); 
      }
    } else {
      //Show display
      for (let i = 0; i<features.length; i++) {
        features[i].setStyle(null);
      }
    }
  }
}