import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BranchMgmtRoutingModule } from './branch-mgmt-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RbUiComponentsModule, FormFieldsModule } from '@inst-iot/bosch-angular-ui-components';
import { MaterialModule } from '../_shared/modules/material.module';

import { BranchMgmtComponent } from './branch-mgmt/branch-mgmt.component';
import { BranchComponent } from './branch/branch.component';
import { EditParkComponent } from './edit-park/edit-park.component';
import { EditstreetmapComponent } from './editstreetmap/editstreetmap.component';
import { ParkingDetComponent } from './parking-det/parking-det.component';
import { StreetmapComponent } from './streetmap/streetmap.component';
import { UploadComponent } from './upload/upload.component';



@NgModule({
  declarations: [
    BranchMgmtComponent,
    BranchComponent,
    EditParkComponent,
    EditstreetmapComponent,
    ParkingDetComponent,
    StreetmapComponent,
    UploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BranchMgmtRoutingModule,
    NgbModule,
    RbUiComponentsModule,
    FormFieldsModule,
    MaterialModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
})
export class BranchMgmtModule { }
