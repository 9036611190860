import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  
  constructor(private http: HttpClient) { }
  
  /* PARKING ANALYTICS */
  // HTTP GET - Parking Daily Occupancy of a parking defining a period
  getDailyOccupancy(parkingId: string, endDate: string, startDate: string) {
    return this.http.get(environment.apiUri + "analytics/parkings/" + parkingId + "?analyticsType=OCCUPANCY" + "&end=" + endDate + "&resolution=HOUR" + "&start=" + startDate, {observe: 'response'}).toPromise();
  }
  
  // HTTP GET - Parking Weekly Occupancy of a parking defining a period
  getWeeklyOccupancy(parkingId: string, endDate: string, startDate: string) {
    return this.http.get(environment.apiUri + "analytics/parkings/" + parkingId + "?analyticsType=OCCUPANCY" + "&end=" + endDate + "&resolution=DAY" + "&start=" + startDate, {observe: 'response'}).toPromise();
  }
  
  // HTTP GET - Parking Daily Duration of a parking defining a period
  getDailyDuration(parkingId: string, endDate: string, startDate: string) {
    return this.http.get(environment.apiUri + "analytics/parkings/" + parkingId + "?analyticsType=DURATION" + "&end=" + endDate + "&resolution=HOUR" + "&start=" + startDate, {observe: 'response'}).toPromise();
  }
  
  /* LOT ANALYTICS */
  // HTTP GET - Lot occupancy of a single lot defining a period and a resolution step
  getLotOccupancy(lotId: string, startDate: string, endDate: string, resolution: string) {
    return this.http.get(environment.apiUri + "analytics/lots/" + lotId + "?start=" + startDate + "&end=" + endDate + "&analyticsType=OCCUPANCY" + "&resolution=" + resolution, {observe: 'response'}).toPromise();
  }

  // HTTP GET - Lot duration of a single lot defining a period and a resolution step
  getLotDuration(lotId: string, startDate: string, endDate: string, resolution: string) {
    return this.http.get(environment.apiUri + "analytics/lots/" + lotId + "?start=" + startDate + "&end=" + endDate + "&analyticsType=DURATION" + "&resolution=" + resolution).toPromise();
  }

  getAlerts(lotId: string, startDate: string, endDate: string) {
    return this.http.get(environment.apiUri + "alerts/" + lotId + "?start=" + startDate + "&end=" + endDate).toPromise();
  }
  
  /* LOT GROUPS ANALYTICS */
  // HTTP GET - Daily Occupancy of lots groups defining a period
  getDailyOccupancyGroup(startDate: string, endDate: string, groupId: string) {
    return this.http.get(environment.apiUri + "analytics/aggregated/lots?start=" + startDate + "&end=" + endDate + "&groupId=" + groupId + "&analyticsType=OCCUPANCY"  + "&resolution=HOUR", {observe: 'response'}).toPromise();
  }
  
  // HTTP GET - Parking Weekly Occupancy of lots groups defining a period
  getWeeklyOccupancyGroup(startDate: string, endDate: string, groupId: string) {
    return this.http.get(environment.apiUri + "analytics/aggregated/lots?start=" + startDate + "&end=" + endDate + "&groupId=" + groupId + "&analyticsType=OCCUPANCY"  + "&resolution=DAY", {observe: 'response'}).toPromise();
  }
  
  // HTTP GET - Parking Daily Duration of lots groups defining a period
  getDailyDurationGroup(startDate: string, endDate: string, groupId: string) {
    return this.http.get(environment.apiUri + "analytics/aggregated/lots?start=" + startDate + "&end=" + endDate + "&groupId=" + groupId + "&analyticsType=DURATION"  + "&resolution=HOUR", {observe: 'response'}).toPromise();
  }
  
}
