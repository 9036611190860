import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Parking } from 'src/app/_shared/models/parking';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from '../../_services/parking.service';
import { TypeService } from '../../_services/type.service';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-park',
  templateUrl: './edit-park.component.html',
  styleUrls: ['./edit-park.component.scss']
})
export class EditParkComponent implements OnInit {
  
  parking: Parking;
  @Input() id: string;
  isOwner: boolean = false;
  
  @Output() closePop: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  // Form to add Section
  sectionName: string = "";
  newSectionModel: any;
  
  // Form to remove Section
  selectedStatesRemoveSection: any;
  list: any;
  keys: any;
  
  constructor(
    public http: HttpClient,
    private router: Router,
    public park: ParkingService,
    public auth: AuthenticationService,
    public t: TypeService,
    private toastrService: ToastrService,) {
      
      this.parking = new Parking;
      
      this.newSectionModel = {
        name: "",
        lots: []
      }
  }
  
  async ngOnInit() {
    
    this.isOwner = await this.auth.isOwner();
    this.park.retriveParkingSub(this.id).subscribe(
      (data: any) => {
        this.parking = data;
      },
      (err) => {
        console.log("Parking " + this.id + " don't found in DB!");
      }
    );
    
  }
  
  putParking() {
    let bodyUpdate={ 
      id: this.parking.id,
      name: this.parking.name,
      address: this.parking.address,
      location: {
        x: this.parking.location.x,
        y: this.parking.location.y,
        type: this.parking.location.type,
        coordinates: [this.parking.location.x, this.parking.location.y]
      },
      section: this.parking.section,
      groups: this.parking.groups
    };
    
    this.park.updateParking(this.parking.id, bodyUpdate).then( data => {
      this.toast('success', this.parking.id, 'Parking updated successfully');
    },
    (error) => {
      this.toast('error', this.parking.id, 'Parking not updated');
    })
    
    setTimeout(()=>{this.reloadPage();},4000);
  }
  
  confirmAddSection() {
    this.newSectionModel = {
      name: this.sectionName,
      lots: [ ],
    }
    this.parking.section.push(this.newSectionModel)
    this.sectionName = "";
  }
  
  updateStatesRemoveSection(states) {
    this.keys = Object.keys(states);
    this.list = this.keys.filter(function(key){
      return states[key]
    });
  }
  
  confirmDeleteSection() {
    
    for(let i=0; this.parking.section.length; i++) {
      this.list.forEach(element => {
        if(this.parking.section[i].name == element) {
          let index = this.parking.section.indexOf(this.parking.section[i]);
          this.parking.section.splice(index, 1);
        }
      })
    }
    
  }
  
  closePopover() {
    this.closePop.emit(true)
  }
  
  reloadPage() {
    let currentRoute = "/branch";
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'edit' }, queryParamsHandling: 'merge' }); // navigate to same route
    });
  }
  
  // Toast function
  toast(type: string, id: string, message: string){
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
      
      case 'info':
      this.toastrService.info(id, message, {
        positionClass: 'toast-bottom-center',
        progressBar: false,
        closeButton: false,
        tapToDismiss: true,
        timeOut: 4000
      });
      break;
      
      default:
        break;
    }
  }
  
}