import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { LotGetModel } from 'src/app/_shared/models/lot';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LotService } from 'src/app/_services/lot.service';
import { TypeService } from 'src/app/_services/type.service';
import { LotsGroupsService } from 'src/app/_services/lots-groups.service';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editlot',
  templateUrl: './editlot.component.html',
  styleUrls: ['./editlot.component.scss']
})
export class EditlotComponent implements OnInit {
  
  lot: LotGetModel;
  @Input() id: string;
  @Input() x: number;
  @Input() y: number;
  disabledMultiSelect = true;
  timestamp: string;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() closePop: EventEmitter<boolean> = new EventEmitter<boolean>();
  section: string="";
  parking_id: string;
  types:any=["regular", "handicap", "management", "guest", "e-vehicle", "short-term"];
  
  //Groups variables
  lotsGroups: any;
  
  //Form to update lots to a group
  groupUpdate: any;
  list: any;
  selectedStatesGroup: any;
  keys: any;
  initialGroups: any[];
  
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public lotService: LotService,
    public auth: AuthenticationService,
    public t: TypeService,
    private toastrService: ToastrService,
    public groupsService: LotsGroupsService)
    {
    this.lot= new LotGetModel();
  }
  
  async ngOnInit() {
    console.log("Edit lot "+ this.id);
    this.parking_id=this.route.snapshot.params.id;
    this.route.queryParams.subscribe( (par:any)=>{
      this.section=par.section;
      this.lotService.retriveLotById(this.id).subscribe(
        (data:any)=>{
          this.lot=data;
        },
        (err) => {
          console.log("Lot "+ this.id + " don't found in DB, it will be created after SAVE");
          this.lot.type="regular";
          this.lot.id=this.id;
        }
      );
    console.log("Lotto "+ this.lot.id);
    });
    
    this.selectedStatesGroup = {}
    await this.groupsService.retrieveGroupsUser().then(group => {
      this.lotsGroups = group;
      
      for (let i=0; i<this.lotsGroups.length; i++) {
        for (let j=0; j<this.lotsGroups[i].lots.length; j++) {
          if(this.lotsGroups[i].lots[j] == this.id) {
            this.selectedStatesGroup[this.lotsGroups[i].name] = true
          }
        }
      }
      this.initialGroups = Object.keys(this.selectedStatesGroup)
      this.updateStatesGroup(this.selectedStatesGroup)
    });
    
  }
  
  updateStatesGroup(states) {
    this.keys = Object.keys(states);
    this.list = this.keys.filter(function(key){
      return states[key]
    });
  }
  
  statusStyle() {
    let res={color:"red"};
    switch(this.lot.status){
      case "free":{
        res.color='#70BF54';
        break;
      }
      case "busy":{
        res.color='#E11F26';
        break;
      }
      case "alert":{
        res.color='#FCAF17';
        break;
      }
      default:{
        res.color='#BFC0C2';
        break;
      }}
    return res;
  }
  
  putLot() {
    let bodyModel = [];
    let body={ 
      id: this.lot.id,
      type: this.lot.type,
      location: this.lot.location,
      area: this.lot.area,
      authlevel: this.lot.authlevel,
      sensorId:this.lot.sensor_id,
      configStatus: this.lot.config_status,
      groups: this.lot.groups,
      booked: this.lot.booked,
      x: this.x,
      y: this.y,
      display: this.lot.display
      };
    
    if (this.lot.id===null) {
      body.id='';
    }
    
    if (this.lot.type===null) {
      body.type='regular';
    }
    
    if (this.lot.location===null) {
      body.location={x: 0, y: 0, coordinates: [0,0], type: "Point" };
    }
    
    if (this.lot.area===null) {
      body.area='';
    }
    
    if (this.lot.authlevel===null) {
      body.authlevel='';
    }
    
    if (this.lot.sensor_id===null || this.lot.sensor_id==='') {
      body.sensorId='';
    } else {
      body.configStatus='waiting';
    }
    
    if (this.lot.config_status===null && this.lot.sensor_id==='') {
      body.configStatus='not mapped';
    } else if (this.lot.config_status===null && this.lot.sensor_id!=='') {
      body.configStatus='waiting';
    }
    
    if(this.lot.groups===null) {
      body.groups=[''];
    }
    
    if(this.lot.booked===null) {
      body.booked=true;
    }
    
    if(this.lot.x===null) {
      body.x=this.x;
    }
    
    if(this.lot.y===null) {
      body.y=this.y;
    }
    
    if(this.lot.display===null) {
      body.display=[];
    }
    
    bodyModel.push(body);
    
    this.http.put(environment.apiUri + "v2/lots/" + this.parking_id + "/" +  this.section, bodyModel, { headers: { 'Content-Type': 'application/json' }}).subscribe(
      data => {
        console.log("PUT Request is successful ", data);
        this.toast('success', this.id,'Parking Space updated successfully')
        this.newItemEvent.emit(this.lot.id);
      },
      (error) => {
        console.log("Error PUT Request ", error);
        this.toast('error', this.id, 'Parking Space not updated')
      }
    )
    this.reloadPage();
  }
  
  closePopover() {
    this.closePop.emit(true)
  }
  
  statusTStyle(value) {
    let res={color:"black"};
    res.color=this.t.find(value).toString();
    return res;
  }
  
  // Toast function
  toast(type: string, id: string, message: string) {
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'info':
      this.toastrService.info(id, message, {
        positionClass: 'toast-bottom-center',
        progressBar: false,
        closeButton: false,
        tapToDismiss: true,
        timeOut: 4000
      });
      break;
      
      default:
        break;
    }
  }
  
  //Update Parking Spaces Groups
  confirmUpdateGroup() {
    this.groupUpdate = []
    for (let i=0; i<this.lotsGroups.length; i++) {
      for(let j=0; j<this.list.length; j++) {
        if(this.lotsGroups[i].name == this.list[j]) {
          this.groupUpdate.push({id: this.lotsGroups[i].id, name: this.lotsGroups[i].name, lots: this.lotsGroups[i].lots} )
        }
      }
    }
    if(this.groupUpdate.length>0) {
      for(let k=0; k<this.groupUpdate.length; k++) {
        let body = {}
        if(this.groupUpdate[k].lots.some(e => e === this.lot.id)) {
          body = {"name":this.groupUpdate[k].name, "lots": this.groupUpdate[k].lots}
        } else {
          this.groupUpdate[k].lots.push(this.lot.id)
          body = {"name":this.groupUpdate[k].name, "lots": this.groupUpdate[k].lots}
          this.groupsService.updateGroup(this.groupUpdate[k].id, JSON.stringify(body)).subscribe(
            data  => {
              this.toast('success', '', this.id + ' has been added to the ' + this.groupUpdate[k].name + ' Parking Spaces Group')
            },
            (error)  => {
              this.toast('error', '',  this.id + ' has not been added to the ' + this.groupUpdate[k].name + ' Parking Spaces Group')
            });
        }
      }
    }
    
    this.initialGroups.forEach(element => {
      if(this.list.indexOf(element) === -1) {
        for(let i=0; i<this.lotsGroups.length; i++) {
          if(this.lotsGroups[i].name == element) {
            let index = this.lotsGroups[i].lots.indexOf(this.lot.id)
            this.lotsGroups[i].lots.splice(index, 1)
            let body = {}
            body = {"name":this.lotsGroups[i].name, "lots": this.lotsGroups[i].lots}
            this.groupsService.updateGroup(this.lotsGroups[i].id, body).subscribe(
              data  => {
                this.toast('success', '', this.id + ' has been removed to the ' + this.lotsGroups[i].name + ' Parking Spaces Group')
              },
              (error)  => {
                this.toast('error', '',  this.id + ' has not been removed to the ' + this.lotsGroups[i].name + ' Parking Spaces Group')
              });
          }
        }
      }
    })
  }
  
  reloadPage() {
    let parkingId = this.route.snapshot.params.id;
    let section = this.route.snapshot.queryParams.section;
    let currentRoute = "/parking/" + parkingId;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'edit', section: section }, queryParamsHandling: 'merge' }); // navigate to same route
    });
  }
  
}