import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Parking } from '../../models/parking';

import { ParkingService } from 'src/app/_services/parking.service';

@Component({
  selector: 'app-parking-dropdown',
  templateUrl: './parking-dropdown.component.html',
  styleUrls: ['./parking-dropdown.component.scss']
})
export class ParkingDropdownComponent implements AfterViewInit {
  
  @Input() view: string;
  parkings: Parking[];
  sec: string;
  displayList: string[] = [];
  
  constructor(
    private router: Router,
    private parkingService: ParkingService,
  ) { }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.parkingService.retriveParkingsInfo().then(parkings => {
        this.parkings = parkings as Parking[];
      });
    }, 1000);
    
  }
  
  go(parkingId, section) {
    const currentRoute = "/parking/" + parkingId;
    let path = window.location.pathname
    
    this.sec = section[0].name
    
    this.displayList = [];
    section.forEach((section)=>{
      section.display_id.forEach((display)=>{
        this.displayList.push(display)
      })
    })
    
    if(this.view === '' && section.length === 1) {
      this.view='map';
    } else if(this.view === '' && section.length > 1) {
      this.view = 'floors';
    } else if(this.view === undefined && section.length === 1) {
      this.view='map';
    } else if(this.view === undefined && section.length > 1) {
      this.view = 'floors';
    } else if(path === '/branch' && section.length > 1) {
      this.view = 'floors'
    } else if(this.view === 'floors' && section.length === 1) {
      this.view='map';
    } else if(this.view === 'display-list' && this.displayList.length === 0) {
      this.view = 'map'
    }
    
    if (this.view !== 'floors' && this.view !== 'analytics' && this.view !== 'sensor-status') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute], { queryParams: { view: this.view, section: this.sec }, queryParamsHandling: 'merge' }); // navigate to same route
      });
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute], { queryParams: { view: this.view }, queryParamsHandling: 'merge' }); // navigate to same route
      });
    }
  }
  
}
