import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DisplaysService } from 'src/app/_services/displays.service';
import { ImagesService } from 'src/app/_services/images.service';

import { DisplayGetModel } from 'src/app/_shared/models/display';

@Component({
  selector: 'app-displays-list',
  templateUrl: './displays-list.component.html',
  styleUrls: ['./displays-list.component.scss']
})
export class DisplaysListComponent implements OnInit {
  
  @Input() parking_id: string
  section: any;
  displayName: any
  initialDisplay: any;
  displaymap: boolean = true;
  parkImagesId: string;
  
  //Display Variables
  allDisplays: DisplayGetModel[] = []; 
  displays: DisplayGetModel[] = [];
  display: any;
  displaysList: any = [];
  list: any = [];
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public displayService: DisplaysService,
    public imagesService: ImagesService,
  ) { }
  
  async ngOnInit() {
    
    this.section = this.route.snapshot.queryParams.section;
    
    await this.displayService.retrieveDisplayByParkingId(this.parking_id).toPromise().then(displays => {
      this.allDisplays = displays as DisplayGetModel[];
    })
    
    if(this.allDisplays.length>0) {
      this.allDisplays.forEach((display, index) => {
        if(this.section.includes('-')) {
          let indexStart = this.allDisplays[index].name.lastIndexOf('-')-1;
          let sectionNameWithDash = '-' + this.section;
          if(this.allDisplays[index].name.includes(sectionNameWithDash, indexStart)) {
            this.displays.push(this.allDisplays[index])
          } 
        } else {
          let indexStart = this.allDisplays[index].name.lastIndexOf('-');
          let sectionNameWithDash = '-' + this.section;
          if(this.allDisplays[index].name.includes(sectionNameWithDash, indexStart) && this.allDisplays[index].name.includes('--')==false) {
            this.displays.push(this.allDisplays[index])
          }
        }
      })
    }
    
    for(let i=0; i<this.displays.length; i++) {
      var lastDash = this.displays[i].name.lastIndexOf('-'); // find the last -
      var id = this.displays[i].name.substr(0, lastDash);
      if(this.section.includes('-')) {
        var lotNumber = this.displays[i].name.replace(id.concat(this.section), '')
      } else {
        var lotNumber = this.displays[i].name.replace(id.concat('-').concat(this.section), '')
      }
      var name = this.section.concat(lotNumber)
      this.displaysList[i] = this.displays[i]
      this.displaysList[i].code = name;
    }
    
    this.initialDisplay = this.route.snapshot.queryParams.display;
    
    if(this.initialDisplay !== undefined) {
      this.display = this.displaysList.filter(x => x.id == this.initialDisplay)
      this.viewDisplayInfo(this.display[0]);
    } else {
      this.router.navigate([], { queryParams: { display: this.displaysList[0].name }, queryParamsHandling: 'merge' });
      this.display = this.displaysList[0]
      this.viewDisplayInfo(this.display);
    }
    
    this.route.queryParams.subscribe(par => {
      this.displayName = par.display;
      if(this.displayName !== undefined) {
        
        this.displays.forEach((display, index)=>{
          if(this.displays[index].name == this.displayName) {
            this.display = display
          }
        })
        
        this.displayService.retrieveLotsDisplay(this.display.id).toPromise().then(list=>{
          this.list = list;
        })
      }
      
    })
    
    this.parkImagesId =  this.parking_id + "-" + this.section;
    await this.imagesService.retriveImagesExists(this.parkImagesId)
      .then(
        (data) => {
        },
        (err) => {
          this.displaymap = false
        }
      );
    
  }
  
  styleObjectDisplay(display) {
    let res;
    res = { cursor: "pointer", border: "1px solid black", margin: "5px 5px 0 0"};
    res.backgroundColor = '#BFC0C2';
    
    const dis = this.displaysList.filter(function (l) {
      return (l.id == display.id);
    });
    if (dis.length !== 0) {
      switch (dis[0]?.status) {
        case "ok": {
          res.backgroundColor = '#70BF54';
          break;
        }          
        case "alert": {
          // res.backgroundColor='#FCAF17';
          res.animation = "colorChange 1s infinite";
          break;
        }
        default: {
          res.backgroundColor = '#BFC0C2';
          break;
        }
      }
    }
    return res;
  }
  
  viewDisplayInfo(parkSpaceX) {
    this.display = parkSpaceX;
  }
  
}