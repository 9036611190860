import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';

@Component({
  selector: 'app-upload-sensors',
  templateUrl: './upload-sensors.component.html',
  styleUrls: ['./upload-sensors.component.scss']
})
export class UploadSensorsComponent implements OnInit {
  
  csvUploaded: boolean = false;
  confirmationMessage: SafeHtml;
  confirmationMessageEverynet: SafeHtml;
  
  csvFile: any;
  sensorsArray: any = [];
  
  //CSV Parser
  csvRecords: any;
  header: boolean = true;
  
  constructor(
    public http: HttpClient,
    private sanitized: DomSanitizer,
    private ngxCsvParser: NgxCsvParser
  ) { }
  
  ngOnInit() {
  }
  
  onFileSelected(event) {
    this.csvFile = event.target.files[0];
    console.log(event);
    if(this.csvFile !== undefined) {
      this.csvUploaded = true;
    } else {
      this.csvUploaded = false;
    }    
    
    const files = event.srcElement.files;
    this.header = (this.header as unknown as string) === 'true' || this.header === true;
    
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ';'}).pipe().subscribe({
      next: (result): void => {
        console.log('Result', result);
        this.csvRecords = result;
      },
      error: (error: NgxCSVParserError): void => {
        console.log('Error', error);
      }
    });
    
  }
  
  upload() {
    for(let i=0;i<this.csvRecords.length;i++) {
      if(this.csvRecords[i].dev_eui !== '') {
        this.sensorsArray.push(this.csvRecords[i].dev_eui)
      }
    }
    this.uploadOnDatabase();
    this.uploadOnEverynet();
  }
  
  uploadOnDatabase() {
    const formData = new FormData();
    let headers = new HttpHeaders();
    headers.append('accept','*/*')
    headers.append('Content-Type','multipart/form-data');
    if(this.csvFile) {
      formData.append('file', this.csvFile);
      this.http.post(environment.apiUri + "sensors/upload", formData, { headers: headers, observe: 'response' })
      .subscribe(res =>{
        console.log(res);
        if (res.ok) {
          console.log("Sensors successfully uploaded to the database")
          this.confirmationMessage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-success"><span class="rb-ic rb-ic-alert-success-filled"></span>Sensors successfully uploaded to the database</div>');
        } else {
          console.log("Upload Failed: Server replied with status message "+ res.statusText)
          this.confirmationMessage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-error"><span class="rb-ic rb-ic-alert-error-filled"></span>Upload to database failed: Server replied with status message '+ res.statusText + '. Check the dev_eui field is not empty.</div>');
        }
      });
    } else throw new Error('Upload Failed');
  }
  
  uploadOnEverynet() {
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    let body = this.sensorsArray;
    if(body) {
      this.http.post(environment.apiUri + "sensors/uploadOnEverynet", body, { headers: headers, observe: 'response' })
      .subscribe(res =>{
        console.log(res);
        if (res.ok) {
          console.log("Sensors successfully uploaded to the Everynet")
          this.confirmationMessageEverynet = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-success"><span class="rb-ic rb-ic-alert-success-filled"></span>Sensors successfully uploaded to the Everynet</div>');
        } else {
          console.log("Upload Failed: Server replied with status message "+ res.statusText)
          this.confirmationMessageEverynet = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-error"><span class="rb-ic rb-ic-alert-error-filled"></span>Upload to Everynet Failed: Server replied with status message '+ res.statusText + '</div>');
        }
      });
    } else throw new Error('Upload Failed');
  }
  
  reset() {
    this.csvUploaded = false;
  }
}