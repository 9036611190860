import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ModalService } from '@inst-iot/bosch-angular-ui-components';
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  pattern: RegExp;
  displayConfirm: boolean = false;
  modalUser

  @ViewChild('modalNewUsers') modalNewUsers: TemplateRef<any>;

  constructor(
    public auth: AuthenticationService,
    private oauthService: OAuthService,
    private modalService: ModalService
  ) { 
    this.auth.homeComponentCalled$.subscribe(
      () => {
       this.loginOrRegister();
      }
    );
  }

  onClickRegister() {
    this.auth.registerUser()
  }

  modelChangeFn(states) {
    console.log('states', states);
    if (states == true) {
      this.displayConfirm = true;
    } else {
      this.displayConfirm = false;
    }
  }

  public async loginOrRegister(){
    if (await this.auth.doLogin() === 404) {
      this.modalService.open(this.modalNewUsers)
    }
  }

  public async ngOnInit() {
    if (!this.oauthService.hasValidAccessToken()) {
      await this.auth.authenticationFlow();
    } else {
      this.loginOrRegister();
    }
  }
}

