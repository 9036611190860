import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parking-mgmt',
  templateUrl: './parking-mgmt.component.html',
  styleUrls: ['./parking-mgmt.component.scss']
})
export class ParkingMgmtComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
