import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Sensor } from '../_shared/models/sensor';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  
  sensorsInfo: any;
  sensors: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
    this.sensors = new BehaviorSubject<Sensor>(null);
    this.sensorsInfo = new BehaviorSubject<any>(null);
  }
  
  public retriveSensorInfo(sensorTag) {
    return this.http.get(environment.apiUri + "sensors?tag=" + sensorTag).toPromise();
  }
  
}


