import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { Feature, View } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Cluster, Vector } from 'ol/source';
import { Fill, Stroke, Style, Text } from 'ol/style';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import Map from 'ol/Map';
import * as olExtent from "ol/extent";
import ZoomToExtent from 'ol/control/ZoomToExtent';

import { LotGetModel } from 'src/app/_shared/models/lot';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ImagesService } from 'src/app/_services/images.service';
import { TypeService } from 'src/app/_services/type.service';
import { StyleService } from 'src/app/_services/style.service';

@Component({
  selector: 'app-map-lot',
  templateUrl: './map-lot.component.html',
  styleUrls: ['./map-lot.component.scss']
})
export class MapLotComponent implements OnInit{
  
  map: Map;
  id: string;
  parsubs: Subscription;
  section: string = "";
  extent: any;
  features: any;
  vectorSource: VectorSource = new Vector({});
  vectorLayer: VectorLayer;
  retriveImages: string;
  parkImagesId: string;
  mapLoaded = false;
  imagesInfo: any;
  lot: LotGetModel;
  
  x: any;
  y: any;
  @Input() lotid: any;
  @Input() status: any;
  center: any[];
  coordinates: any = [];
  
  constructor(
    public auth: AuthenticationService,
    public types: TypeService,
    private route: ActivatedRoute,
    public imagesService: ImagesService,
    public style: StyleService,
  ) {
    this.features = [];
    this.lot = new LotGetModel();
  }
  
  async ngOnChanges() {
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par:any) => {
      this.section = par.section;
      this.parkImagesId = this.id + '-' + this.section;
    });
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(info => {
      this.imagesInfo = info as any;
      this.coordinates = this.imagesInfo.dot_coordinate.filter(x => x.type === "lot");
      this.coordinates = this.coordinates.filter(x => x.name == this.lotid)
      this.x = this.coordinates[0].x;
      this.y = this.coordinates[0].y;
    });
    
    var extentHeight = this.imagesInfo.y_multiplier;
    this.center = [this.x, (extentHeight - this.y)];
    
    if(!this.vectorSource.isEmpty()) {
      this.features = [];
      this.vectorSource.clear();
      const pos = [this.x, (extentHeight - this.y)];
      const singleFeature = new Feature({
        geometry: new Point(pos),
      });
      this.features.push(singleFeature);
      this.vectorSource.addFeatures(this.features);
      
      //Set Map Center Dynamically
      this.map.getView().setCenter(this.center);
    }  
  }
  
  async ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par:any) => {
      this.section = par.section;
      this.parkImagesId = this.id + '-' + this.section;
    });
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(info => {
      this.imagesInfo = info as any;
      this.coordinates = this.imagesInfo.dot_coordinate.filter(x => x.type === "lot");
      this.coordinates = this.coordinates.filter(x => x.name == this.lotid)
      this.x = this.coordinates[0].x;
      this.y = this.coordinates[0].y;
    });
    
    var extentHeight = this.imagesInfo.y_multiplier;
    var extentWidth = this.imagesInfo.x_multiplier;
    this.extent = [0, 0, extentWidth, extentHeight];
    var projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: this.extent,
    });
    
    //Setup a Vector Source
    this.vectorSource = new VectorSource({
      features: [],
    });
    
    //Setup a Vector Layer
    this.vectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: this.vectorSource,
      }),
      style: this.styleObject,
    });
    
    var sourceStaticImage = new Static({
      imageLoadFunction: (function (image, src) {
        this.imagesService.retriveImages(src)
          .subscribe(data => {
            let reader = new FileReader();
            reader.addEventListener("loadend", () => {
              image.getImage().src = reader.result;
            }, false);
            if (data) {
              reader.readAsDataURL(data);
            }
          }, error => {
            console.log(error);
          });
      }).bind(this),
      url: this.parkImagesId,
      projection: projection,
      imageExtent: this.extent
    })
    
    this.map = new Map({
      layers: [
        new ImageLayer({
          source: sourceStaticImage
        }),
      ],
      target: "map-layer",
      view: new View({
        projection: projection,
        center: [this.x, (extentHeight - this.y)],
        maxZoom: 6,
        zoom: 2,
        extent: [-(0.1*extentWidth), -(0.1*extentHeight), extentWidth+(0.1*extentWidth), extentHeight+(0.1*extentHeight)],
        showFullExtent: true
      })
    });
    
    // Function to compute dynamically the height of the map
    this.style.computeHeightList();
    this.map.updateSize();
    
    // Zoom Control Button
    var zoom = document.createElement("span");
    zoom.innerHTML = '<span class="rb-ic rb-ic-arrows-expand-object"></span>';
    var zoomControl = new ZoomToExtent({
      extent: [0, 0, extentWidth, extentHeight],
      label: zoom,
    });
    
    this.map.addControl(zoomControl);
    this.map.once("rendercomplete", (event) => {
      this.mapLoaded = true;
      this.map.addLayer(this.vectorLayer);
    });
    
    this.features = [];
    
    const pos = [this.x, (extentHeight - this.y)];
    const singleFeature = new Feature({
      geometry: new Point(pos),
    });
    this.features.push(singleFeature);
    this.vectorSource.addFeatures(this.features);
    
  }
  
  styleObject = (dot) => {
    if(this.status == "free") {
      var lotColor = '#70BF54';
    } else if (this.status == "busy") {
      var lotColor = '#E11F26';
    } else if (this.status == "alert") {
      var lotColor = '#FCAF17';
    } else {
      var lotColor = '#BFC0C2';
    }
    var lotStrokeColor = '#000000';
    var strokeWidth = 2;
    
    var iconStyle = new Style({
      text: new Text({
        text: "\ue1c3",
        scale: 2.5,
        textBaseline: 'bottom',
        font: "400 18px 'Bosch-Icon'",
        fill: new Fill({ 
          color: lotColor, 
        }),
        stroke: new Stroke({ 
          color: lotStrokeColor,
          width: strokeWidth, 
        })
      })
    });
    return iconStyle;
  };
  
}