import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Parking } from 'src/app/_shared/models/parking';

import { ParkingService } from 'src/app/_services/parking.service';
import { LotsGroupsService } from 'src/app/_services/lots-groups.service';

import { AnalyticsDailyComponent } from '../analytics-daily/analytics-daily.component';
import { AnalyticsWeeklyComponent } from '../analytics-weekly/analytics-weekly.component';
import { AnalyticsDaysRangeComponent } from '../analytics-days-range/analytics-days-range.component';

import { jsPDF } from "jspdf";

const { DateTime } = require("luxon");

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  
  @ViewChild(AnalyticsDailyComponent, {static: false}) private childHourly: AnalyticsDailyComponent;
  @ViewChild(AnalyticsDaysRangeComponent, {static: false}) private childDaily: AnalyticsDaysRangeComponent;
  @ViewChild(AnalyticsWeeklyComponent, {static: false}) private childWeekly: AnalyticsWeeklyComponent;
  
  // DatePicker
  public myGroup: UntypedFormGroup;
  public minDate = new Date();
  public maxDate = new Date();
  
  display: boolean = false;
  displayDownload: boolean = false;
  
  //Download variables
  hourlyOccupancy: any;
  dailyOccupancy: any;
  weeklyOccupancy: any;
  dailyDurations: any;
  title: any;
  
  lotsGroups: any;
  parkings: any;
  paramsId: any;
  displayselect: boolean = false;
  offsetName: string;
  
  //Time range
  timeStart = {hour: 0, minute: 0};
  timeEnd = {hour: 24, minute: 0}
  meridian = true;
  hourStep = 1;
  minuteStep = 15;
  
  //Controls Day
  minCheckDate = new Date();
  maxCheckDate= new Date();
  actualDay = new Date();
  previousDay = new Date();
  nextDay = new Date();
  actualEndDay = new Date();
  previousEndDay = new Date();
  nextEndDay = new Date();
  
  constructor(
    private parkingService: ParkingService,
    private groupsService: LotsGroupsService,
    private route: ActivatedRoute) { }
  
  async ngOnInit() {
    this.minDate.setFullYear(this.maxDate.getFullYear(), this.maxDate.getMonth() - 3, this.maxDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.minCheckDate = this.minDate;
    this.maxCheckDate = this.maxDate;
    
    this.myGroup = new UntypedFormGroup({
      date: new UntypedFormControl(this.maxDate),
      endDate: new UntypedFormControl(this.maxDate),
      selected: new UntypedFormControl()
    });
    
    await this.groupsService.retrieveGroupsUser().then(group => {
      this.lotsGroups = group;
    });
    
    await this.parkingService.retriveParkingsInfo().then(parkings => {
      this.parkings = parkings as Parking[];
    });
    
    this.paramsId = this.route.snapshot.params.id
    if(this.paramsId !== undefined) {
      this.displayselect = false;
      this.displayDownload = true;
    } else {
      this.displayselect = true;
      this.myGroup.get("selected").setValue(this.parkings[0].id);
      this.displayDownload = true;
    }
    
    let offsetNameShort = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameShort;
    let offsetNameLong = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameLong;
    this.offsetName = offsetNameShort + " (" + offsetNameLong + ")";
    
    this.myGroup.valueChanges.subscribe(change=>{
      let offsetNameShort = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameShort;
      let offsetNameLong = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameLong;
      this.offsetName = offsetNameShort + " (" + offsetNameLong + ")";
      this.checkDay();
    })
    
    this.myGroup.get("selected").valueChanges.subscribe(change => {
      if(change.selected !== null) {
        this.displayDownload = true;
      }
    })
    
    this.checkDay();
  }
  
  ngAfterViewInit() {
    this.selectTime(); 
  }
  
  selectTime() {
    this.childHourly.computeTimeRange();
    this.childDaily.computeTimeRange();
    this.childWeekly.computeTimeRange();
  }
  
  download() {
    let startDate = this.myGroup.get('date').value;
    let endDate = this.myGroup.get('endDate').value;
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if(document.getElementById('analyticsTitle') !== null) {
      this.title = this.paramsId;
    } else {
      this.title = this.myGroup.get('selected').value;
    }
    
    this.hourlyOccupancy = document.getElementById("dailyParkingOccupancy");
    this.dailyOccupancy = document.getElementById("daysrangeParkingOccupancy");
    this.weeklyOccupancy = document.getElementById("weeklyParkingOccupancy");
    this.dailyDurations = document.getElementById("dailyDurations");
    let hourlyOccupancyImage = this.hourlyOccupancy.toDataURL('image/jpg', 1.0);
    let dailyOccupancyImage = this.dailyOccupancy.toDataURL('image/jpg', 1.0);
    let weeklyOccupancyImage = this.weeklyOccupancy.toDataURL('image/jpg', 1.0);
    let dailyDurationsImage = this.dailyDurations.toDataURL('image/jpg', 1.0);
    
    let pdf = new jsPDF();
    let pdfWidth = (pdf.internal.pageSize.getWidth())*0.8;
    let pdfHeight = (pdf.internal.pageSize.getHeight())
    let margin = (pdf.internal.pageSize.getWidth())*0.1;
    let imgPropsHourly= pdf.getImageProperties(hourlyOccupancyImage);
    let pdfHeightHourly = (imgPropsHourly.height * pdfWidth) / imgPropsHourly.width;
    let imgPropsDaily= pdf.getImageProperties(dailyOccupancyImage);
    let pdfHeightDaily = (imgPropsDaily.height * pdfWidth) / imgPropsDaily.width;
    let imgPropsWeekly= pdf.getImageProperties(weeklyOccupancyImage);
    let pdfHeightWeekly = (imgPropsWeekly.height * pdfWidth) / imgPropsWeekly.width;
    let imgPropsDurations= pdf.getImageProperties(dailyDurationsImage);
    let pdfHeightDurations = (imgPropsDurations.height * pdfWidth) / imgPropsDurations.width;
    pdf.setFontSize(20);
    pdf.text("Analytics:", 10, 10);
    pdf.text(this.title, 10, 20);
    pdf.addImage("https://smart-parking-app-d.bosch.tech/assets/img/Bosch_EN_LV_RGB.png", "png",160, -2, 50, 24);
    pdf.setFontSize(10);
    pdf.text("Start Day: " + startDate.toDateString() + " - End Day: " + endDate.toDateString(), 10, 30);
    pdf.setFontSize(15);
    pdf.text("Hourly Occupancy Chart", 80, 40);
    pdf.addImage(hourlyOccupancyImage, 'JPEG', margin, 45, pdfWidth, pdfHeightHourly);
    pdf.text("Daily Occupancy Chart", 80, pdfHeightHourly+60);
    pdf.addImage(dailyOccupancyImage, 'JPEG', margin, pdfHeightHourly+65, pdfWidth, pdfHeightDaily);
    if(pdfHeight < pdfHeightHourly+pdfHeightDaily+pdfHeightWeekly+85) {
      pdf.addPage();
      pdf.addImage("https://smart-parking-app-d.bosch.tech/assets/img/Bosch_EN_LV_RGB.png", "png",160, -2, 50, 24);
      pdf.text("Weekly Occupancy Chart", 80, 40);
      pdf.addImage(weeklyOccupancyImage, 'JPEG', margin, 45, pdfWidth, pdfHeightWeekly);
      pdf.text("Daily Durations Chart", 80, pdfHeightWeekly+60);
      pdf.addImage(dailyDurationsImage, 'JPEG', margin, pdfHeightWeekly+65, pdfWidth, pdfHeightDurations);
    } else {
      pdf.text("Weekly Occupancy Chart", 80, pdfHeightHourly+pdfHeightDaily+80);
      pdf.addImage(weeklyOccupancyImage, 'JPEG', margin, pdfHeightHourly+pdfHeightDaily+85, pdfWidth, pdfHeightWeekly);
      pdf.addPage();
      pdf.addImage("https://smart-parking-app-d.bosch.tech/assets/img/Bosch_EN_LV_RGB.png", "png",160, -2, 50, 24);
      pdf.text("Daily Durations Chart", 80, 30);
      pdf.addImage(dailyDurationsImage, 'JPEG', margin, 35, pdfWidth, pdfHeightDurations);
    }
    pdf.save(yyyy + "-" + mm + "-" + dd + " " + this.title + " Analytics" + ".pdf");
  }
  
  setPreviousDay() {
    this.actualDay = this.myGroup.get("date").value;
    this.previousDay = DateTime.fromJSDate(this.actualDay).minus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("date").setValue(this.previousDay);
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.previousEndDay = DateTime.fromJSDate(this.actualEndDay).minus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("endDate").setValue(this.previousEndDay);
  }
  
  setNextDay() {
    this.actualDay = this.myGroup.get("date").value;
    this.nextDay = DateTime.fromJSDate(this.actualDay).plus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("date").setValue(this.nextDay);
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.nextEndDay = DateTime.fromJSDate(this.actualEndDay).plus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("endDate").setValue(this.nextEndDay);
  }
  
  checkDay() {
    let min = DateTime.fromJSDate(this.minCheckDate).startOf('day');
    let max = DateTime.fromJSDate(this.maxCheckDate).startOf('day');
    this.actualDay = this.myGroup.get("date").value;
    this.previousDay = DateTime.fromJSDate(this.actualDay).minus({days: 1}).startOf('day').toJSDate();
    this.nextDay = DateTime.fromJSDate(this.actualDay).plus({days: 1}).startOf('day').toJSDate();
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.previousEndDay = DateTime.fromJSDate(this.actualEndDay).minus({days: 1}).startOf('day').toJSDate();
    this.nextEndDay = DateTime.fromJSDate(this.actualEndDay).plus({days: 1}).startOf('day').toJSDate();
    if(DateTime.fromJSDate(this.previousDay).ts < min.ts || DateTime.fromJSDate(this.previousEndDay).ts < min.ts) {
      document.getElementById("previousDay").setAttribute('disabled','');
    } else {
      document.getElementById("previousDay").removeAttribute('disabled');
    }
    
    if (DateTime.fromJSDate(this.nextDay).ts > max.ts || DateTime.fromJSDate(this.nextEndDay).ts > max.ts) {
      document.getElementById("nextDay").setAttribute('disabled','');
    } else {
      document.getElementById("nextDay").removeAttribute('disabled');
    }
  }
  
}
