import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LotsGroupsService {
  
  constructor(private http: HttpClient) { }
  
  //Share lots group
  retrieveGroups(id) {
    return this.http.get(environment.apiUri + "lotsGroups/" + id).toPromise();
  }
  
  //Returns lots groups by user
  retrieveGroupsUser() {
    return this.http.get(environment.apiUri + "lotsGroups").toPromise();
  }
  
  //Update group by id
  updateGroup(id, body) {
    return this.http.put(environment.apiUri + "lotsGroups/" + id, body,{ headers: { 'Content-Type': 'application/json' } });
  }
  
  //Create lots group
  createGroup(name, body) {
    return this.http.post(environment.apiUri + "lotsGroups?name=" + name, body).toPromise();
  }
  
  //Delete group by id
  deleteGroup(id) {
    return this.http.delete(environment.apiUri + "lotsGroups?id=" + id);
  }
  
}
