import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthenticationService } from 'src/app/_services/authentication.service';

@Pipe({
    name: 'authImage'
})
export class SecurePipe implements PipeTransform {
  
  constructor(private http: HttpClient,
    private auth: AuthenticationService,) { }
  
  async transform(src: string) {
    const token = this.auth.getToken();
    const headers = new HttpHeaders({'Authorization': token.access_token,'Accept':'image/jpeg'});
    const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }
}