export class LotGetModel {
  id: string;
  type: string;
  location: {
    x: number;
    y: number;
    coordinates: [number, number];
    type: string;
  };
  area: string;
  authlevel: string;
  groups: string[];
  booked: boolean;
  x:	number;
  y:	number;
  timestamp: string;
  status: string;
  display: string[];
  sensor_id: string;
  config_status: string;
  partner_id: string;
  installation_timestamp: string;
  has_access: boolean;
  status_duration?:	string;
  average_stopover_time?: number;
  saturation_time?:	number;
}
export class LotPutModel {
  id: string;
  type: string;
  location: {
    x: number;
    y: number;
    coordinates: [number, number];
    type: string;
  };
  area: string;
  authlevel: string;
  sensorId: string;
  configStatus: string;
  groups: string[];
  booked: boolean;
  x:	number;
  y:	number;
  display: string[];
}