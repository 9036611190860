import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';

import { AuthenticationService } from './_services/authentication.service';
import { ParkingService } from './_services/parking.service';
import { ImagesService } from './_services/images.service';
import { TypeService } from './_services/type.service';
import { UserService } from './_services/user.service';

import { JwtInterceptor } from './authentication/_helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/_helpers/error.interceptor';

import { AppRoutingModule } from './app-routing.module';

import { HomeModule } from './home/home.module';
import { PrivacyPolicyComponent } from './_shared/components/privacy-policy/privacy-policy.component';
import { OssLicensesComponent } from './_shared/components/oss-licenses/oss-licenses.component';
import { PageNotFoundComponent } from './_shared/components/page-not-found/page-not-found.component';

import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RbUiComponentsModule, ModalService, FormValidationMessageDirective, BreadcrumbsModule, ModalModule, FormFieldsModule, TabPanelModule } from '@inst-iot/bosch-angular-ui-components';
import { MaterialModule } from './_shared/modules/material.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxCsvParserModule } from 'ngx-csv-parser';

import { OAuthModule, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';  //here

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    PageNotFoundComponent,
    OssLicensesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HomeModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgbModule,
    RbUiComponentsModule,
    BreadcrumbsModule.forRoot(),
    ModalModule.forRoot(),
    FormFieldsModule,
    TabPanelModule,
    MaterialModule,
    ToastrModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUri],
        sendAccessToken: true
      }
    }),
    ToastContainerModule,
    NgxCsvParserModule
  ],
  providers: [
    AsyncPipe,
    ModalService,
    AuthenticationService,
    ParkingService,
    ImagesService,
    TypeService,
    UserService,
    OAuthService,
    UrlHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
