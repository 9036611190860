import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/_services/analytics.service';

import Chart from 'chart.js/auto';

const { DateTime } = require("luxon");

@Component({
  selector: 'app-analytics-weekly',
  templateUrl: './analytics-weekly.component.html',
  styleUrls: ['./analytics-weekly.component.scss']
})
export class AnalyticsWeeklyComponent implements OnInit {
  
  public parkingId: string;
  
  // CHART declarations
  public weeklyChartOccupancy: Chart;
  public ctxWeekly: any;
  
  // DatePicker
  @Input() myGroup: UntypedFormGroup;
  @Input() minDate = new Date();
  @Input() maxDate = new Date();
  public yesterday = new Date();
  
  // API variables
  public weeklyDataOccupancyObj: any = {};
  public weekOccupancyRate: number[] = [];
  public weekDayRange: string[] = [];
  
  // Variable declarations
  public mapLoaded = true;
  public showError:boolean = false;
  
  //Select Parking / Group
  @Input() lotsGroups: any;
  @Input() parkings: any;
  groupList: any = [];
  parkingList: any = [];
  
  //Time range
  @Input() timeStart;
  @Input() timeEnd;
  weeklyDataOccupancyTimeObj: any = {};
  
  constructor(
    private analytics: AnalyticsService,
    private route: ActivatedRoute) { }
  
  async ngOnInit() {
    
    this.createChart();
    this.yesterday.setFullYear(this.myGroup.get("date").value.getFullYear(), this.myGroup.get("date").value.getMonth(), this.myGroup.get("date").value.getDate() - 1);
    this.dataComputation(this.yesterday, '');
    this.myGroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(change  => {
      if(change.date !== null && change.endDate !== null) {
        if(change.date === this.maxDate) {
          this.dataComputation(this.yesterday, change.selected);
        } else {
          this.dataComputation(change.endDate, change.selected);
        }
      }
    });
    
    this.weeklyChartOccupancy.resize(document.getElementById("dailyParkingOccupancy").offsetWidth, document.getElementById("dailyParkingOccupancy").offsetHeight)
  }
  
  createChart() {
     // Create chart WEEKLY OCCUPANCY
    this.ctxWeekly = document.getElementById("weeklyParkingOccupancy");
    this.weeklyChartOccupancy = new Chart("weeklyParkingOccupancy", {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Occupancy",
            backgroundColor: "#50237f",
            borderColor: "#50237f",
            data: this.weekOccupancyRate,
          },
        ],
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y:
            {
              title: {
                display: true,
                text: "Weekly Occupancy (%)",
              },
              min: 0,
              max: 100,
              ticks: {  
                stepSize: 10,
              },
            },
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        }
      },
    });
    
    this.mapLoaded = true;
  }
  
  async dataComputation(genericDate: Date, selected: any){
    // WEEK COMPUTATION CHART
    this.computeChart([]);
    this.weekDayRange = [];
    this.mapLoaded = false;
    
    var startWeekDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').minus({days: 5}).toUTC().startOf('day').toISO()
    var endWeekDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO()
    
    if(this.parkings !== undefined) {
      this.groupList = [];
      for (let i=0; i<this.parkings.length; i++) {
        this.groupList.push(this.parkings[i].id)
        }
    }
    
    if(this.lotsGroups !== undefined) {
      this.parkingList = []
      for(let i=0; i<this.lotsGroups.length; i++) {
        this.parkingList.push(this.lotsGroups[i].name)
      }
    }
    
    this.parkingId = this.route.snapshot.params.id;
    
    if(this.parkingId !== undefined) {
      await this.analytics.getWeeklyOccupancy(this.parkingId, endWeekDate, startWeekDate).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.weeklyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.weeklyDataOccupancyObj = x.body;
        }
      });
    } else if (this.groupList.indexOf(selected) !== -1) {
      this.parkingId = selected;
      await this.analytics.getWeeklyOccupancy(this.parkingId, endWeekDate, startWeekDate).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.weeklyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.weeklyDataOccupancyObj = x.body;
        }
      });
    } else if (this.parkingList.indexOf(selected) !== -1 ){
      let groupId = '';
      for (let i = 0; i<this.lotsGroups.length; i++) {
        if(this.lotsGroups[i].name == selected) {
          groupId = this.lotsGroups[i].id
        }
      }
      await this.analytics.getWeeklyOccupancyGroup(startWeekDate, endWeekDate, groupId).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.weeklyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.weeklyDataOccupancyObj = x.body;
        }
      });
    }
    
    for (let i = 0; i < this.weeklyDataOccupancyObj?.occupancies?.length; i++) {
      this.weekOccupancyRate[i] = this.weeklyDataOccupancyObj.occupancies[i]["occupancy_rate"] * 100;
      this.weekDayRange[i] = DateTime.fromISO(this.weeklyDataOccupancyObj.occupancies[i]["start"]).toFormat('ccc, LLL dd, yyyy');
    }
    
    console.log("START WEEK ", startWeekDate)
    console.log("END WEEK ", endWeekDate)
    
    // Round to 2 decimal points
    this.weekOccupancyRate = this.weekOccupancyRate.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    // UPDATE CHART
    this.computeChart(this.weekOccupancyRate)
    
    //Compute Analytics in a time range
    await this.analytics.getDailyOccupancy(this.parkingId, endWeekDate, startWeekDate).then((x) => (this.weeklyDataOccupancyTimeObj = x));
		
    let hourRange = this.timeEnd.hour - this.timeStart.hour;
    if(hourRange < 24) {
      this.computeTimeRange()
    }
    
  }
  
  computeChart(weekOccupancyRate) {
    // WEEK CHART UPDATE
    this.weeklyChartOccupancy.data.datasets[0].data = weekOccupancyRate;
    this.weeklyChartOccupancy.data.labels = this.weekDayRange;
    this.weeklyChartOccupancy.update();
    this.mapLoaded = true;
  }
  
  computeTimeRange() {
    //UPDATE WEEKLY
    let hourRange = this.timeEnd.hour - this.timeStart.hour;
    
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    //Selection of data in the time range given in input
    var newdatasetweek = [];
    for (let i=0; i<this.weeklyDataOccupancyTimeObj?.occupancies?.length; i++) {
      if(DateTime.fromISO(this.weeklyDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour >=  this.timeStart.hour && DateTime.fromISO(this.weeklyDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour <  this.timeEnd.hour) {
      newdatasetweek[i] = this.weeklyDataOccupancyTimeObj.occupancies[i]
      }
    }
    
    //Reduce function to group the occupancies of the same day 
    let newdatasetsweek = newdatasetweek.reduce(function (r, a) {
      r[DateTime.fromISO(a.start).toUTC().toObject().day] = r[DateTime.fromISO(a.start).toUTC().toObject().day] || [];
      r[DateTime.fromISO(a.start).toUTC().toObject().day].push(a);
      return  r
    }, {});
    
    //Compute Occupancy Rate in a time range 
    let occupancies = []
    for (const i in newdatasetsweek) {
      let occupancyRate = 0
      for(let j = 0; j<newdatasetsweek[i].length; j++) {
      occupancyRate += newdatasetsweek[i][j].occupancy_rate
      }
      occupancies.push((occupancyRate/hourRange)*100)
    }
    
    occupancies = occupancies.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    this.weeklyChartOccupancy.data.datasets[0].data = occupancies;
    this.weeklyChartOccupancy.data.labels = this.weekDayRange;
    this.weeklyChartOccupancy.update();
  }
  
}
