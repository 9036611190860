import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authConfigSKID: AuthConfig = {
  issuer: environment.ciamIssuer,
  redirectUri: environment.redirectUri,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  clientId: environment.ciamClientId,
  responseType: environment.responseType,
  silentRefreshRedirectUri: window.location.origin,
  useSilentRefresh: true,
  scope: environment.ciamScope,
  showDebugInformation: true,
  requestAccessToken: true,
  customQueryParams: environment.skidStyleId
};

export const authConfigAAD: AuthConfig = {
  issuer: environment.azureIssuer,
  redirectUri: environment.redirectUri,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  clientId: environment.azureClientId,
  responseType: environment.responseType,
  scope: environment.azureScope,
  showDebugInformation: true,
  requestAccessToken: true,
  strictDiscoveryDocumentValidation: false
};