import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { LanguageItem } from '@inst-iot/bosch-angular-ui-components';

import { User } from 'src/app/_shared/models/user';
import { Parking } from '../../models/parking';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from 'src/app/_services/parking.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  title: string;
  user: User;
  language = 'en';
  parkings: Parking[];
  users: User[];
  view: string = '';
  userCanEdit: boolean = false;
  userRole: any;
  languages: LanguageItem[] = [
    { id: 'en', name: 'English' }
  ];
  parkingId: string;
  parkingSubscription: Subscription;
  
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthenticationService,
    public loc: Location,
    public http: HttpClient,
    public parkingService: ParkingService,
    public userService: UserService
  ) {
    this.user = {  id: "", username: "", iss: "", client: null, groups: [], resourceMgmt: {}, timestamp: "", lastResourceAccessed: [], responseStatus: null, badRequests: null, accepted: null };
    this.title = "SMART PARKING";
  }
  
  ngOnDestroy() {
    this.parkingSubscription.unsubscribe();
  }
  
  async ngOnInit() {
    this.parkingSubscription = this.parkingService.currentParkingId.subscribe(
      async parkId => {
        this.parkingId = parkId;
        if(this.parkingId !== '' && this.parkingId !== 'defaultParkId') {
          await this.userService.userCanEditByParkingID(this.parkingId).then(
            (data) => {
              this.userRole = data['role'];
            },
            () => {
              this.userRole = null;
            }
          )
        }
        this.route.queryParams.subscribe(par => {
          this.view = par.view;
        });
        if(this.userRole === "owner"){
          this.userCanEdit= true;
        }else{
          this.userCanEdit= false;
        }
      }
    );
    
    if (this.auth.isAuthenticated()) {
      this.user = this.auth.user.value;
      
      this.http.get(environment.apiUri + "parkings/allinfo").subscribe((data: Parking[]) => {
        this.parkings = data;
        this.route.queryParams.subscribe(par => {
          /* if (par.view == null) {
            this.router.navigate([], { queryParams: { view: "map" }, queryParamsHandling: 'merge' });
          } */
          this.view = par.view;
        });
      });
      
    }
  }
  
  ngAfterViewInit() {
    document.getElementsByClassName("logo")[0].innerHTML='<a href="https://smart-parking-app-d.bosch.tech/"><img src="assets/img/Bosch_EN_LV_RGB.png" alt="Bosch - Invented for life"></a>';
  }
  
  backOnClick() {
    this.loc.back();
  }
  
  onClickLogout() {
    this.auth.logout();
  }
  
  sectionParam() {
    let sectionParam: String;
    this.route.queryParams.subscribe(par => {
      sectionParam = par.section;
    });
    return sectionParam;
  }
  
}
