import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Parking } from '../_shared/models/parking';
import { ImageInfo } from '../_shared/models/image';


@Injectable({
  providedIn: 'root'
})
export class ParkingService {
  
  parking: BehaviorSubject<Parking>;
  parkingN: BehaviorSubject<any>;
  lot: BehaviorSubject<any>;
  section: BehaviorSubject<any>;
  parkingTypes: BehaviorSubject<any>;
  private parkingID = new BehaviorSubject('defaultParkId');
  currentParkingId = this.parkingID.asObservable();
  
  constructor(private http: HttpClient) {
    this.parking= new BehaviorSubject<Parking>(null);
    this.parkingN=new  BehaviorSubject<any>(null);
    this.lot= new BehaviorSubject<any>(null);
    this.section= new BehaviorSubject<any>(null);
    this.parkingTypes= new BehaviorSubject<any>(null);
  }
  
  retriveParkingsInfo() {
    return this.http.get(environment.apiUri + "parkings").toPromise();
  }
  
  retriveAllInfo(id) {
    return this.http.get(environment.apiUri + "parkings/allinfo/" + id).toPromise();
  }
  
  retriveParking(id) {
    return this.http.get<Parking>(environment.apiUri+"parkings/"+id)
    .toPromise()
    .then(data=>{
      console.log("parking retrive");
      this.parking.next(data);
    });
  }
  
  //parkingID v2
  retriveParkingId(id, parkingStatus) {
    return this.http.get<Parking>(environment.apiUri + "v2/parkings/"+ id +"/lots?status=" + parkingStatus);
  }
  
  retriveParkingSub(id) {
    return this.http.get<Parking>(environment.apiUri+"parkings/"+id);
  }
  
  retriveParkingN(id) {
    return this.http.get(environment.apiUri+"parkings/"+id+"/nlot")
    .toPromise()
    .then(data=>{
      console.log("parking retrive");
      this.parkingN.next(data);
    });
  }
  
  retriveParkingDet(id,status) {
    return this.http.get(environment.apiUri+"parkings/"+id+"/nlot/type"+"?status="+status)
    .toPromise()
    .then((data)=>{
      console.log(id +"det retrive ");
      this.parkingTypes.next(data);
    });
  }
  
  retriveLotsInfo(id) {
    return this.http.get(environment.apiUri+ "v2/parkings/" + id + "/lots");
  }
  
  retriveLot(id) {
    return this.http.get(environment.apiUri+"v2/lots/"+id)
    .toPromise()
    .then(data=>{
      console.log("lot "+id+" retrive");
      this.lot.next(data);
    });
  }
  
  retriveSection(id, sectionname, status) {
    return this.http.get(environment.apiUri+"parkings/"+id+"/nlot/section/type?sectionName="+sectionname+"&status="+status)
    .toPromise()
    .then(data=>{
      console.log("area "+sectionname+" retrive");
      this.section.next(data);
    });
  }
  
  getParking():Parking {
    return this.parking.value;
  }
  
  getParkingN() {
    return this.parkingN.value;
  }
  
  getParkingDet() {
    return this.parkingTypes.value;
  }
  
  getLot() {
    return this.lot.value;
  }
  
  getSection() {
    return this.section.value;
  }
  
  getParkingId(parkId: any) {
    this.parkingID.next(parkId);
  }
  
  //Add New Parking
  addNewParking(body) {
    return this.http.post(environment.apiUri + "parkings", body).toPromise();
  }
  
  //Update Parking
  updateParking(parkingId, body) {
    return this.http.put(environment.apiUri + "parkings/" + parkingId, body).toPromise();
  }
  
  //Delete Parking
  deleteParking(parkingId) {
    return this.http.delete(environment.apiUri + "parkings/" + parkingId).toPromise();
  }
  
}
