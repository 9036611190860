import { Component, OnInit, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {trigger, state, style, animate, transition, keyframes} from '@angular/animations';

import { LotGetModel } from 'src/app/_shared/models/lot';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from 'src/app/_services/parking.service';
import { TypeService } from 'src/app/_services/type.service';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ transform: 'scale(1)' })),
      state('closed', style({ transform: 'scale(1)' })),
      transition('* <=> *', [
        animate("6s", keyframes([
          style({opacity: 0, offset: 0.3}),
          style({opacity: 0.5, offset: 0.6})
        ])),
      ]),
    ])
  ],
})
export class LotComponent implements OnInit {
  
  lot: LotGetModel;
  @Input() id: string;
  @Input() sectionName: any;
  disabledMultiSelect = true;
  selectedType: string = "";
  timestamp: string;
  save = false;
  error = false;
  average_stopover_time_hours = 0;
  average_stopover_time_minutes  = 0; 
  @Input() random_sensorID: string;
  @Input() random_partnerID: string;
  @Input() random_status: string;
  @Input() random_last_update: string;
  @Input() random_avgSaturation_hours: string;
  @Input() random_avgSaturation_minutes: string;
  @Input() random_avgSaturation_percent: string;
  viewmap: boolean = false;
  view: any;
  isOpen: boolean = true;
  
  constructor(
    public http: HttpClient,
    public park: ParkingService,
    public auth: AuthenticationService,
    private route: ActivatedRoute,
    public t: TypeService) {
    this.lot = new LotGetModel();
  }
  
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges() {
    this.ngOnInit();
    this.isOpen = !this.isOpen;
  }
  
  async ngOnInit() {

    await this.park.retriveLot(this.id);
    this.lot = this.park.getLot();
    if (this.lot.average_stopover_time) {
      this.average_stopover_time_hours = Math.trunc((this.lot.average_stopover_time) / 60);
      this.average_stopover_time_minutes = this.lot.average_stopover_time - (this.average_stopover_time_hours * 60);
    }
    this.selectedType = this.lot.type;
    let t = new Date(this.lot.timestamp);
    this.timestamp = t.toLocaleString();
    console.log("Lotto " + this.lot.id);
    
    console.log("Lot component " + this.id);
    
    this.view = this.route.snapshot.queryParams.view;
    
    if (this.view == 'map') {
      this.viewmap = true;
    }
  }
  
  generateRandomNumberMaxMin(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1))).toString();
  };
  
  generateRandomNumberMaxMin_toNumber(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1)));
  };
  
  statusStyle() {
    let res = { color: "red" };
    switch (this.lot.status) {
      case "free": {
        res.color = '#70BF54';
        break;
      }
      case "busy": {
        res.color = '#E11F26';
        break;
      }
      case "alert": {
        res.color = '#FCAF17';
        break;
      }
      default: {
        res.color = '#BFC0C2';
        break;
      }
    }
    return res;
  }
  
  generateRandomString(length = 6) {
    var randomChars = 'abcdef0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  
  generateRandomLastUpdate(start, end, startHour, endHour): string {
    var date = new Date(+start + Math.random() * (end - start));
    var hour = startHour + Math.random() * (endHour - startHour) | 0;
    date.setHours(hour);
    return date.toString();
  }
  
  statusTStyle(value) {
    let res = { color: "black" };
    res.color = this.t.find(value).toString();
    return res;
  }
  
}