import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TypeService {
  
  constructor() { }
  
  public types() {
    return ["regular", "management","guest","handicap","e-vehicle","short-term"]
  }
  
  public find(value):string {
    let res:string;
    switch(value){
      case "regular":{
        res='black';
        break;
      }
      case "management":{
        res='#50237F';
        break;
      }
      case "guest":{
        res='#008ECF';
        break;
      }
      case "handicap":{
        res='#B90276';
        break;
      }
      case "e-vehicle":{
        res='#00A8B0';
        break;
      }
      case "short-term":{
        res='#525F6B';
        break;
      }
      
      default:{
        res='#BFC0C2';
        break;
      }
    }
    return res;
  }
  
}
