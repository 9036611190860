import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DisplaysService } from 'src/app/_services/displays.service';
import { ParkingService } from 'src/app/_services/parking.service';

import { DisplayGetModel, DisplayPutModel } from 'src/app/_shared/models/display';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editdisplay',
  templateUrl: './editdisplay.component.html',
  styleUrls: ['./editdisplay.component.scss']
})
export class EditdisplayComponent implements OnInit {
  
  @Input() id: string;
  @Output() closePop: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  display: DisplayGetModel;
  lotsDisplay: any;
  parking: any
  lotsList: any = [];
  item: any;
  
  //Form to associate lots to a display
  groupUpdate: any;
  list: any;
  selectedStatesDisplay: any;
  keys: any;
  
  constructor(
    public displayService: DisplaysService,
    public parkingService: ParkingService,
    private toastrService: ToastrService,
  ) { }
  
  async ngOnInit() {
    await this.displayService.retrieveDisplay(this.id).then((data: DisplayGetModel)=>{
      this.display = data;
    })
    
    await this.parkingService.retriveParkingSub(this.display.parkingId).toPromise().then((data)=>{
      this.parking = data;
      
      for(let i = 0; i<this.parking.section.length; i++) {
        this.lotsList = this.lotsList.concat(this.parking.section[i].lots)
      }
      
      this.item = [];
      for (let j=0; j<this.lotsList.length; j++) {
        this.item[j] = {name: this.lotsList[j]};
      }
      
    })
    
    await this.displayService.retrieveLotsDisplay(this.id).toPromise().then(lots => {
      this.lotsDisplay = lots;
    })
    
    this.associatedLots()
    
  }
  
  associatedLots() {
    this.selectedStatesDisplay = {}
    for(let i = 0; i<this.lotsDisplay.length; i++) {
      this.selectedStatesDisplay[this.lotsDisplay[i]] = true;
    }
    this.updateStatesDisplay(this.selectedStatesDisplay)
  }
  
  updateStatesDisplay(states) {
    console.log(this.selectedStatesDisplay)
    this.keys = Object.keys(states);
    this.list = this.keys.filter(function(key){
      return states[key]
    });
  }
  
  removeParkingSpace(lot: any): void {
    const index = this.list.indexOf(lot);
    
    if (index >= 0) {
      this.list.splice(index, 1);
    }
    
    this.selectedStatesDisplay = {}
    for (let i = 0; i < this.list.length; i++) {
      this.selectedStatesDisplay[this.list[i]] = true;
    }
    this.updateStatesDisplay(this.selectedStatesDisplay)
  }
  
  confirmUpdateDisplay() {
    
    let updateBody: DisplayPutModel = {
      name: this.display.name,
      url: this.display.url,
      layout: this.display.layout,
      username: this.display.username,
      password: this.display.password,
      id: this.display.id,
      expirationDate: this.display.expirationDate,
      sessionId: this.display.sessionId,
      status: this.display.status,
      pubStatus: this.display.pubStatus,
      loginStatus: this.display.loginStatus,
      groups: this.display.groups,
      mac: this.display.mac,
      btia: this.display.btia,
      x: this.display.x,
      y: this.display.y,
      parkingId: this.display.parkingId,
      value: this.display.value,
    }
    
    this.displayService.updateDisplay(this.display.id, updateBody).subscribe(
      data => {
        console.log("PUT Request is successful ", data);
        this.toast('success', this.display.id, 'Display updated successfully');
      },
      (error)  => {
        console.log("Error PUT Request ", error);
        this.toast('error', this.display.id, 'Display not updated');
      }
    )
  }

  confirmUpdateLots() {
    this.displayService.updateDisplayLots(this.display.id, this.list).subscribe(
      data => {
        console.log("PUT Request is successful ", data);
        this.toast('success', this.display.id, 'Parking Spaces list updated successfully');
      },
      (error)  => {
        console.log("Error PUT Request ", error);
        this.toast('error', this.display.id, 'Parking Spaces list not updated');
      }
    )
  }
  
  closePopover() {
    this.closePop.emit(true)
  }

    // Toast function
    toast(type: string, id: string, message: string) {
    
      switch (type) {
        case 'success':
          this.toastrService.success(id, message, {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true,
            tapToDismiss: true,
            timeOut: 4000
          });
          break;
          
        case 'warning':
          this.toastrService.warning(id, message, {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true,
            tapToDismiss: true,
            timeOut: 4000
          });
          break;
          
        case 'error':
          this.toastrService.error(id, message, {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true,
            tapToDismiss: true,
            timeOut: 4000
          });
          break;
          
        default:
          break;
      }
    }
    
  }