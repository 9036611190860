import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { User } from '../_shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  constructor(private http: HttpClient) { }
  
  retrieveUser() {
    return this.http.get<User>(environment.apiUri + "v3/users").toPromise();
  }
  
  addNewUser(body) {
    return this.http.post<User>(environment.apiUri + "v2/users", body).toPromise();
  }
  
  userCanEditByParkingID(parkingID): Promise<string> {
    return this.http.get<string>(environment.apiUri + "v2/users/roleByParkingID?parking_id=" + parkingID).toPromise();
  }
  
  //Update user accepted term
  updateTerms(body) {
    return this.http.patch(environment.apiUri + "v2/users", body, { headers: { 'Content-Type': 'application/json' } });
  }
  
  //Returns all users based on common resources
  retrieveAllUsersResourceBased() {
    return this.http.get(environment.apiUri + "users/resourceBased/all").toPromise();
  }
  
  //Grant resources to user
  grantResources(id, body) {
    return this.http.patch(environment.apiUri + "users/" + id + "/grantResource", body);
  }
  
  //Deny resources to user
  denyResources(id, body) {
    return this.http.patch(environment.apiUri + "users/" + id + "/denyResource", body);
  }
  
  //Check isOwner
  checkIsOwner() {
    return this.http.get(environment.apiUri + "users/checkRole?roleType=owner").toPromise();
  }
  
  //Check isAdmin
  checkIsAdmin() {
    return this.http.get(environment.apiUri + "users/checkRole?roleType=admin").toPromise();
  }

  // Delete user
  deleteUser() {
    return this.http.delete(environment.apiUri + "v2/users").toPromise();
  }
  
}