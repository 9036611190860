import { Component, OnInit, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalOptions, ModalService } from '@inst-iot/bosch-angular-ui-components';

import { environment } from 'src/environments/environment';

import { User } from 'src/app/_shared/models/user';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { StyleService } from 'src/app/_services/style.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  [x: string]: any;
  user: User;
  mail: string;
  displayConfirm: boolean = false;
  displayLogin: boolean = false;
  options: ModalOptions = {
    showClose: false,
    escClose: false,
    backdropClose: false
  };

  @ViewChild('modalContent', { static: true }) private modalContent: TemplateRef<any>;

  constructor(
    public auth: AuthenticationService,
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    public http: HttpClient,
    public style: StyleService,
    public modalService: ModalService) { }

  async ngOnInit() {

    const state: string = this.route.snapshot.queryParamMap.get('state');
    if (state == 'UGF0cmljaw') {
      this.auth.logout();
    }
    else {
      if (this.auth.isAuthenticated()) {
        await this.userService.retrieveUser()
          .then(
            (data) => {
              this.user = data;
              if (this.user.accepted === false) {
                this.modalService.open(this.modalContent, this.options)
              }
            }
          );
      }
    }

    setTimeout(() => { this.style.computeLoginHeight() }, 100);
  }

  updateStatesMail(states) {
    this.mail = states;
    if (states !== undefined && states !== '') {
      this.displayLogin = true;
    }
  }

  modelChangeFn(states) {
    console.log('states', states);
    if (states == true && this.mail !== '') {
      this.displayConfirm = true;
    } else {
      this.displayConfirm = false;
    }
  }

  confirmTerms() {
    let body = "true";
    this.userService.updateTerms(body).subscribe(
      data => {
        console.log("Terms and Conditions Accepted")
      },
      (error) => {
        console.log("Terms and Conditions Not Accepted")
      })
  }

  async onClickRegister() {
    //await this.auth.discoveryAzure();
    if (this.mail.includes("bosch")) {
      window.open(this.auth.azureLoginUri +
        environment.azureLoginParam +
        "/home" +
        "&state=" + this.auth.generateStateParameter() +
        "&nonce=" + this.auth.generateNonceParameter(),
        "_self")
    } else {
      window.open(
        environment.ciamRegistrationUri,
        "_blank");
    }
  }

  onClickLogout() {
    this.auth.logout();
    this.change.emit(null);
  }

  async onClickLogin() {
    localStorage.setItem('mail', this.mail)
    this.router.navigate(["home"]);
  }

}
