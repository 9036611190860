import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {trigger, state, style, animate, transition, keyframes} from '@angular/animations';

import { Subscription, timer } from 'rxjs';

import { DisplayGetModel } from 'src/app/_shared/models/display';

import { DisplaysService } from 'src/app/_services/displays.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({ transform: 'scale(1)' })),
      state('closed', style({ transform: 'scale(1)' })),
      transition('* <=> *', [
        animate("6s", keyframes([
          style({opacity: 0, offset: 0.3}),
          style({opacity: 0.5, offset: 0.6})
        ])),
      ]),
    ])
  ],
})
export class DisplayComponent implements OnInit {
  
  @Input() display: DisplayGetModel;
  @Input() sectionName: any;
  view: any;
  viewmap: boolean = false;
  isOpen: boolean = true;
  
  displaySubscription: Subscription;
  
  constructor(
    private route: ActivatedRoute,
    public displayService: DisplaysService,
  ) { }
  
  ngOnDestroy() {
    console.log('unsuscribe polling');
    this.displaySubscription.unsubscribe();
  }
  
  ngOnChanges() {
    this.isOpen = !this.isOpen;
  }
  
  async ngOnInit() {
    this.view = this.route.snapshot.queryParams.view;
    
    if (this.view == 'map') {
      this.viewmap = true;
    }
    
    this.displaySubscription = timer(0, 3000).subscribe(async () => {
      await this.displayService.retrieveDisplay(this.display.id).then( (data: DisplayGetModel) => {
        this.display = data
      })
    });
  }
  
  statusStyle() {
    let res = { color: "red" };
    switch (this.display?.status) {
      case "ok": {
        res.color = '#70BF54';
        break;
      }
      case "alert": {
        res.color = '#FCAF17';
        break;
      }
      default: {
        res.color = '#BFC0C2';
        break;
      }
    }
    return res;
  }
  
}