export class DisplayGetModel {
    name: string;
    url: string;
    layout: string;
    username: string;
    password: string;
    id: string;
    expirationDate: string;
    sessionId: string;
    status: string;
    pubStatus: string;
    loginStatus: string;
    groups: string[];
    mac: string;
    btia: string;
    x: number;
    y: number;
    parkingId: string;
    value: number;
}

export class DisplayPostModel {
    name: string;
    url: string;
    layout: string;
    username: string;
    password: string;
}

export class DisplayPutModel {
    name: string;
    url: string;
    layout: string;
    username: string;
    password: string;
    id: string;
    expirationDate: string;
    sessionId: string;
    status: string;
    pubStatus: string;
    loginStatus: string;
    groups: string[];
    mac: string;
    btia: string;
    x: number;
    y: number;
    parkingId: string;
    value: number;
}