import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Image } from 'ol/style';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  
  images: BehaviorSubject<Image>;
  imagesInfo: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) {
    this.images = new BehaviorSubject<Image>(null);
    this.imagesInfo = new BehaviorSubject<any>(null);
  }
  
  // API v2/images/{id} returns a JPEG
  retriveImages(id): Observable<Blob> {
    const headers = new HttpHeaders({ 'Accept': 'image/jpeg' });
    return this.http.get(environment.apiUri + "v2/images/" + id, { headers, responseType: 'blob' });
  }
  
  // API v2/images/{id} returns image info
  retriveImagesInfo(id) {
    return this.http.get(environment.apiUri + "v4/images/"+ id + "/info" ).toPromise();
  }
  
  // API v2/images/{id} returns whethere an image id exists
  retriveImagesExists(id) {
    return this.http.get(environment.apiUri + "v2/images/"+ id + "/exists", { observe: 'response' }).toPromise();
  }
  
  // API /v2/images/{id}/dot - Patch to Update dot list 
  updateDotList(imageId, body) {
    return this.http.patch(environment.apiUri + "v2/images/" + imageId + "/dot", body);
  }
  
  // API /v2/images - POST to Upload an image
  uploadImage(body) {
    let headers = new HttpHeaders();
    headers.append('Content-Type','multipart/form-data');
    return this.http.post(environment.apiUri + "v2/images/", body, { headers: headers, observe: 'response' });
  }
  
  // API /v2/images/{id} - PUT to Update an image
  updateImage(imageId, body) {
    let headers = new HttpHeaders();
    headers.append('Content-Type','multipart/form-data');
    return this.http.put(environment.apiUri + "v2/images/" + imageId, body, { headers: headers, observe: 'response' });
  }
  
  // returns a JPEG
  getImages(): Image { 
    return this.images.value;
  }
  
  // returns image info
  getImagesInfo() { 
    return this.imagesInfo.value;
  }
  
}
