import { Component, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { ParkingStatus } from 'src/app/_shared/models/parking';

import { ParkingService } from 'src/app/_services/parking.service';
import { TypeService } from '../../_services/type.service';

@Component({
  selector: 'app-floor-info',
  templateUrl: './floor-info.component.html',
  styleUrls: ['./floor-info.component.scss']
})
export class FloorInfoComponent implements OnInit {
  
  @Input() id: string;
  @Input() section: string;
  
  //Multy-storey variables
  sectionSubscription: Subscription;
  parsubs: Subscription;
  sectionInfo: ParkingStatus;
  list: any;
  
  constructor(
    private park: ParkingService,
    public t: TypeService
    )
    {
      this.sectionInfo = new ParkingStatus();
  }
  
  async  ngOnInit() {
    this.parsubs = timer(0, 5000).subscribe(async () => {
      await this.park.retriveSection(this.id, this.section, 'free');
      this.sectionInfo = this.park.getSection();
      this.list = [];
      for(let i=0; i<this.sectionInfo.types.length; i++) {
        if(this.sectionInfo.types[i]._id == 'regular') {
          this.list[0] = this.sectionInfo.types[i];
        } else if(this.sectionInfo.types[i]._id == 'management') {
          this.list[1] = this.sectionInfo.types[i];
        } else if(this.sectionInfo.types[i]._id == 'guest') {
          this.list[2] = this.sectionInfo.types[i];
        } else if(this.sectionInfo.types[i]._id == 'handicap') {
          this.list[3] = this.sectionInfo.types[i];
        } else if(this.sectionInfo.types[i]._id == 'e-vehicle') {
          this.list[4] = this.sectionInfo.types[i];
        } else if(this.sectionInfo.types[i]._id == 'short-term') {
          this.list[5] = this.sectionInfo.types[i];
        } else {
          this.list[5+i] = this.sectionInfo.types[i];
        }
      }
      this.list = this.list.filter(value => JSON.stringify(value) !== '{}');
      });
  }
  
  ngOnDestroy() {
    this.parsubs.unsubscribe();
  }
  
  statusStyle(value) {
    let res = { color: "black" };
    res.color = this.t.find(value).toString();
    return res;
  }
  
  changeColor() {
    if(this.sectionInfo.section.n_lot_free == 0) {
      return { 'color': '#E11F26' };
    } else return {'color': '#70BF54'}
  }
  
}