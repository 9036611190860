import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard as AuthGuard } from '../authentication/_guards/auth-guard.guard'
import { BranchMgmtComponent } from './branch-mgmt/branch-mgmt.component';
import { BranchComponent } from './branch/branch.component';
import { UploadComponent } from './upload/upload.component';

const BRANCHMGMTROUTES: Routes = [
  {
    path: '', component: BranchMgmtComponent ,
        children: [
          { path: 'branch', component: BranchComponent, canActivate: [AuthGuard]},
          { path: 'upload', component: UploadComponent, canActivate: [AuthGuard]},
        ]
      }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(BRANCHMGMTROUTES)
  ],
  exports:[RouterModule]
})
export class BranchMgmtRoutingModule { }




