import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/_services/analytics.service';

import Chart from 'chart.js/auto';

const { DateTime } = require("luxon");

@Component({
  selector: 'app-analytics-daily',
  templateUrl: './analytics-daily.component.html',
  styleUrls: ['./analytics-daily.component.scss']
})
export class AnalyticsDailyComponent implements OnInit {
  
  public parkingId: string;
  
  // CHART declarations
  public dailyChartOccupancy: Chart;
  public ctxDaily: any;
  
  // DatePicker
  @Input() myGroup: UntypedFormGroup;
  @Input() minDate = new Date();
  @Input() maxDate = new Date();
  public maxCheckDate = new Date();
  public yesterday = new Date();
  
  // API variables
  public dailyDataOccupancyObj: any = {};
  public dayOccupancyRate: number[] = [];
  
  // Variable declarations
  public mapLoaded = true;
  public showError:boolean = false;
  
  //Select Parking / Group
  @Input() lotsGroups: any;
  @Input() parkings: any;
  groupList: any = [];
  parkingList: any = [];
  
  //Time range
  @Input() timeStart;
  @Input() timeEnd;
  dayOccupancyRateTime: any = [];
  
  constructor(
    private analytics: AnalyticsService,
    private route: ActivatedRoute) { }
  
  async ngOnInit() {
    this.createChart();
    this.yesterday.setFullYear(this.myGroup.get("date").value.getFullYear(), this.myGroup.get("date").value.getMonth(), this.myGroup.get("date").value.getDate() - 1);
    this.dataComputation(this.yesterday, this.yesterday, '');
    
    this.maxCheckDate = this.maxDate;
    
    this.myGroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(change  => {
      if(change.date !== null && change.endDate !== null) {
        if(change.date === this.maxCheckDate) {
          this.dataComputation(this.yesterday, this.yesterday, change.selected)
        } else {
          this.dataComputation(change.date, change.endDate, change.selected);
        }
      }
    });
    
  }
  
  createChart() {
    // Create chart DAILY OCCUPANCY
    this.ctxDaily = document.getElementById("dailyParkingOccupancy");
    this.dailyChartOccupancy = new Chart("dailyParkingOccupancy", {
      type: "bar",
      data: {
        labels: ["12am","1am","2am","3am","4am","5am","6am","7am","8am","9am","10am","11am","12pm","1pm","2pm","3pm","4pm","5pm","6pm","7pm","8pm","9pm","10pm","11pm"],
        datasets: [
          {
            label: "Occupancy",
            backgroundColor: "#005691",
            borderColor: "#005691",
            data: this.dayOccupancyRate,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: false
            }
          },
          y: 
            {
              title: {
                display: true,
                text: "Hourly Occupancy (%)",
              },
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                }
            },
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        }
      },
    });
    
    this.mapLoaded = true;
  }
  
  async dataComputation(genericDate: Date, endDate: Date, selected: any){
    // DAY COMPUTATION CHART
    this.computeChart([]);
    this.mapLoaded = false;
    
    var startDayDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').plus({days: 1}).toUTC().startOf('day').toISO();
    var endDayDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO();
    
    console.log("START DAY ", startDayDate)
    console.log("END DAY ", endDayDate)
    
    if(this.parkings !== undefined) {
      this.groupList = [];
      for (let i=0; i<this.parkings.length; i++) {
        this.groupList.push(this.parkings[i].id)
      }
    }
    
    if(this.lotsGroups !== undefined) {
      this.parkingList = []
      for(let i=0; i<this.lotsGroups.length; i++) {
        this.parkingList.push(this.lotsGroups[i].name)
      }
    }
    
    this.parkingId = this.route.snapshot.params.id;
    
    if(this.parkingId !== undefined) {
      await this.analytics.getDailyOccupancy(this.parkingId, endDayDate, startDayDate).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.dailyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataOccupancyObj = x.body;
        }
      });
    } else if (this.groupList.indexOf(selected) !== -1) {
      this.parkingId = selected;
      await this.analytics.getDailyOccupancy(this.parkingId, endDayDate, startDayDate).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.dailyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataOccupancyObj = x.body;
        }
      });
    } else if (this.parkingList.indexOf(selected) !== -1 ) {
      let groupId = '';
      for (let i = 0; i<this.lotsGroups.length; i++) {
        if(this.lotsGroups[i].name == selected) {
          groupId = this.lotsGroups[i].id;
        }
      }
      await this.analytics.getDailyOccupancyGroup(startDayDate, endDayDate, groupId).then((x) => {
        if(x.status !== 200) {
          this.showError = true;
          this.dailyDataOccupancyObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataOccupancyObj = x.body;
        }
      });
    }
    
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    let occupanciesObj = this.dailyDataOccupancyObj?.occupancies;
    
    if(occupanciesObj?.length>0) {
      occupanciesObj = occupanciesObj.reduce(function (r, a) {
        r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour] = r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour] || [];
        r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour].push(a);
        return  r
      }, {});
      
      var occupanciesArray = Object.keys(occupanciesObj).map(function(index){
        let a = occupanciesObj[index]
        return a;
      })
      
      this.dayOccupancyRate = [];
      this.dayOccupancyRateTime = []
      for(let i = 0; i<occupanciesArray.length; i++) {
        let dayTotalOccupancy = 0;
        let start = '';
        for (let j=0; j<occupanciesArray[i].length; j++) {
          dayTotalOccupancy += occupanciesArray[i][j].occupancy_rate;
          start = occupanciesArray[i][j].start;
        }
        this.dayOccupancyRate.push((dayTotalOccupancy/occupanciesArray[i].length)*100)
        this.dayOccupancyRateTime.push({start: start, occupancy_rate: (dayTotalOccupancy/occupanciesArray[i].length)*100})
      }
      
      // Round to 2 decimal points
      this.dayOccupancyRate = this.dayOccupancyRate.map(function (each_element) {
        return Number(each_element.toFixed(2));
      });
      
      // UPDATE CHART
      this.computeChart(this.dayOccupancyRate)
      
      //Compute Analytics in a time range
      let hourRange = this.timeEnd.hour - this.timeStart.hour;
      if(hourRange < 24) {
        this.computeTimeRange()
      }
    } else {
      this.mapLoaded = true
    }
  }
  
  computeChart(dayOccupancyRate) {
    // DAY CHART UPDATE
    this.dailyChartOccupancy.data.datasets[0].data = dayOccupancyRate;
    
    this.dailyChartOccupancy.update();
    
    this.mapLoaded = true;
  }
  
  computeTimeRange() {
    //UPDATE DAILY
    let hourRange = this.timeEnd.hour - this.timeStart.hour;
    let newlabel = []
    for(let i = 0; i<hourRange; i++) {
      newlabel[i] = this.timeStart.hour + i;
    }
    
    let newdataset = [];
    
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    for (let j=0; j<newlabel.length; j++) {
      for (let i = 0; i<this.dayOccupancyRateTime.length; i++) {
        if(DateTime.fromISO(this.dayOccupancyRateTime[i].start).plus({hours: offset}).toUTC().toObject().hour == newlabel[j]) {
          newdataset[j] = (this.dayOccupancyRateTime[i].occupancy_rate)
        }
      }
    }
    
    newdataset = newdataset.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    for(let i = 0; i<newlabel.length; i++) {
      if(newlabel[i] == 0) {
        newlabel[i] = '12am'
      }else if (newlabel[i] < 12) {
        newlabel[i] = newlabel[i] + 'am';
      } else if (newlabel[i] == 12) {
        newlabel[i] = newlabel[i] + 'pm'
      } else {
        newlabel[i] = (newlabel[i] - 12) + 'pm'
      }
    }
    
    this.dailyChartOccupancy.data.datasets[0].data = newdataset;
    this.dailyChartOccupancy.data.labels = newlabel;
    this.dailyChartOccupancy.update();
  }
  
}