import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Parking } from 'src/app/_shared/models/parking';

import { ParkingService } from '../../_services/parking.service';
import { TypeService } from '../../_services/type.service';

@Component({
  selector: 'app-parking-det',
  templateUrl: './parking-det.component.html',
  styleUrls: ['./parking-det.component.scss']
})
export class ParkingDetComponent implements OnInit {
  
  @Input() status: string;
  @Input() parking: Parking;
  types: any;
  list: any;
  parsubs: Subscription;
  n_lot: number = 0;
  n_lot_free: number = 0;
  
  constructor(
    public http: HttpClient,
    public park: ParkingService,
    public t: TypeService) { }
  
  async ngOnInit() {
    this.parsubs = timer(0, 3000).subscribe(async () => {
      await this.park.retriveParkingDet(this.parking.id, this.status);
      this.types = this.park.getParkingDet().types;
      
      this.n_lot = 0;
      this.n_lot_free = 0;
      for (let j=0; j<this.types.length; j++) {
        this.n_lot += this.types[j].n_lot;
        this.n_lot_free += this.types[j].n_lot_free;
      }
      
      this.list = [];
      for(let i=0; i<this.types.length; i++) {
        if(this.types[i]._id == 'regular') {
          this.list[0] = this.types[i];
        } else if(this.types[i]._id == 'management') {
          this.list[1] = this.types[i];
        } else if(this.types[i]._id == 'guest') {
          this.list[2] = this.types[i];
        } else if(this.types[i]._id == 'handicap') {
          this.list[3] = this.types[i];
        } else if(this.types[i]._id == 'e-vehicle') {
          this.list[4] = this.types[i];
        } else if(this.types[i]._id == 'short-term') {
          this.list[5] = this.types[i];
        } else {
          this.list[5+i] = this.types[i];
        }
      }
      this.list = this.list.filter(value => JSON.stringify(value) !== '{}');
    });
  }
  
  statusStyle(value) {
    let res = { color: "black" };
    res.color = this.t.find(value).toString();
    return res;
  }
  
  ngOnDestroy(){
    this.parsubs.unsubscribe();
  }
  
}