import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { Collection, Feature, Graticule, View } from "ol";
import Geometry from "ol/geom/Geometry";

import { LotsGroupsService } from 'src/app/_services/lots-groups.service';
import { ParkingService } from 'src/app/_services/parking.service';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editgroups',
  templateUrl: './editgroups.component.html',
  styleUrls: ['./editgroups.component.scss']
})
export class EditgroupsComponent implements OnInit {
  
  @Input() selectedFeatures: Collection<Feature<Geometry>>
  @Input() displaybutton: boolean;
  @Input() displayselector: boolean;
  
  parkingspaces: any;
  item: any;
  selectedItem: any[] = [];
  
  // Form to Create group
  createGroupForm: UntypedFormGroup;
  groupNameForm = new UntypedFormControl('');
  selectedStatesNewGroup: any;
  displayButtonCreate: boolean = false;
  
  //Form to update lots to a group
  updateGroupForm: UntypedFormGroup;
  groupNameUpdateForm = new UntypedFormControl();
  groupNameUpdatedForm = new UntypedFormControl();
  selectedStatesUpdateGroup: any;
  idGroupUpdate: any;
  displayButtonUpdate: boolean = false;
  
  //Form to delete group
  deleteGroupForm: UntypedFormGroup;
  groupNameDeleteForm = new UntypedFormControl();
  idGroupDelete: any;
  displayButtonDelete: boolean = false;
  
  //Filter Variables
  keys: any;
  list: any;
  
  //Groups variables
  lotsGroups: any;
  lotsList: any = [];
  updateList: any;
  
  constructor(
    private toastrService: ToastrService,
    public form: UntypedFormBuilder,
    public groupsService: LotsGroupsService,
    public parkingService: ParkingService,
    private route: ActivatedRoute,
  ) {
    this.createGroupForm = form.group({
      groupNameForm: this.groupNameForm,
    });
    this.updateGroupForm = form.group({
      groupNameUpdateForm: this.groupNameUpdateForm,
      groupNameUpdatedForm: this.groupNameUpdatedForm,
    });
    this.deleteGroupForm = form.group({
      groupNameDeleteForm: this.groupNameDeleteForm,
    })
  }
  
  async ngOnInit() {
    await this.groupsService.retrieveGroupsUser().then(group => {
      this.lotsGroups = group;
    });
    
    let parkingId = this.route.snapshot.params.id;
    let section = this.route.snapshot.queryParams.section;
    
    await this.parkingService.retriveParkingSub(parkingId).subscribe( x => {
      this.parkingspaces = x;
      
      this.item = []
      for (let i=0; i<this.parkingspaces.section.length; i++) {
        if (this.parkingspaces.section[i].name == section) {
          for (let j=0; j<this.parkingspaces.section[i].lots.length; j++) {
            this.item[j] = {name: this.parkingspaces.section[i].lots[j]}
          }
          
        }
      }
    })
    
    this.checkEmpty(this.createGroupForm.get("groupNameForm").value);
    this.createGroupForm.get("groupNameForm").valueChanges.subscribe(change=>{
      this.checkEmpty(change);
      this.checkName(change);
    })
    
    this.updateGroupForm.get("groupNameUpdatedForm").valueChanges.subscribe(change=>{
      this.checkEmptyUpdate(change);
    })
    
    this.deleteGroupForm.get("groupNameDeleteForm").valueChanges.subscribe(change=>{
      this.checkEmptyDelete(change);
    })
  }
  
  // Create new group
  selectedNewGroup() {    
    this.selectedStatesNewGroup = {}
    this.selectedItem = this.selectedFeatures.getArray();
    for (let i = 0; i < this.selectedItem.length; i++) {
      this.selectedStatesNewGroup[this.selectedItem[i].values_.description] = true;
    }
    this.updateStatesNewGroup(this.selectedStatesNewGroup)
  }
  
  updateStatesNewGroup(states) {
    console.log('states', states);
    this.keys = Object.keys(states);
    this.list = this.keys.filter(function(key){
      return states[key]
    })
    this.checkEmpty(this.createGroupForm.get("groupNameForm").value)
    this.checkName(this.createGroupForm.get("groupNameForm").value)
  }
  
  confirmNewGroup() {
    this.groupsService.createGroup(this.groupNameForm.value, this.list);
    this.toast('success', this.groupNameForm.value, 'Parking Spaces group created successfully');
  }
  
  removeNewGroup(lot: any): void {
    const index = this.list.indexOf(lot);
    
    if (index >= 0) {
      this.list.splice(index, 1);
    }
    
    this.selectedStatesNewGroup = {}
    for (let i = 0; i < this.list.length; i++) {
      this.selectedStatesNewGroup[this.list[i]] = true;
    }
    this.updateStatesNewGroup(this.selectedStatesNewGroup)
  }
  
  checkEmpty(name) {
    if(name !== '' && this.list.length !== 0) {
      this.displayButtonCreate = true;
    } else {
      this.displayButtonCreate = false;
    }
  }
  
  checkName(name) {
    for (let i=0; i<this.lotsGroups.length; i++) {
      if (this.lotsGroups[i].name == name) {
        this.displayButtonCreate = false;
        this.groupNameForm.setErrors({ invalid: true });
      }
    }
  }
  
  //Update Group
  updateGroups() {
    this.idGroupUpdate =  this.lotsGroups.filter(x => x.name == this.groupNameUpdateForm.value)
    this.selectedStatesUpdateGroup = {}
      
    this.lotsList = [];
    for (let i=0; i<this.lotsGroups.length; i++) {
      if (this.lotsGroups[i].name === this.groupNameUpdateForm.value) {
        this.lotsList = this.lotsGroups[i].lots;
      }
    }
    
    for (let i=0; i<this.lotsList.length; i++) {
      this.selectedStatesUpdateGroup[this.lotsList[i]] = true;
    }
    
    this.selectedItem = this.selectedFeatures?.getArray();
    for (let i = 0; i < this.selectedItem?.length; i++) {
      this.selectedStatesUpdateGroup[this.selectedItem[i].values_.description] = true;
    }
    
    this.updateStatesUpdateGroup(this.selectedStatesUpdateGroup)
  }
  
  updateStatesUpdateGroup(states) {
    console.log('states', states);
    this.keys = Object.keys(states);
    this.list = this.keys.filter(function(key){
      return states[key]
    });
  }
  
  confirmUpdateGroup() {
    let body = {"name": this.groupNameUpdatedForm.value, "lots": this.list}
    this.groupsService.updateGroup(this.idGroupUpdate[0].id, JSON.stringify(body)).subscribe(
      data  => {
      console.log("PUT Request is successful ", data);
      this.toast('success', this.groupNameUpdateForm.value, 'Parking Spaces group updated successfully');
      },
      (error)  => {
      console.log("Error PUT Request ", error);
      this.toast('error', this.groupNameUpdateForm.value, 'Parking Spaces group not updated');
      }
      );
    
    
  }
  
  removeUpdateGroup(lot: any): void {
    const index = this.list.indexOf(lot);
    
    if (index >= 0) {
      this.list.splice(index, 1);
    }
    
    this.selectedStatesUpdateGroup = {}
    for (let i = 0; i < this.list.length; i++) {
      this.selectedStatesUpdateGroup[this.list[i]] = true;
    }
    this.updateStatesUpdateGroup(this.selectedStatesUpdateGroup)
  }
  
  checkEmptyUpdate(name) {
    if(name !== '' && name !== null) {
      this.displayButtonUpdate = true;
    } else {
      this.displayButtonUpdate = false;
    }
  }
  
  //Delete group
  deleteGroup() {
    this.lotsList = [];
    for (let i=0; i<this.lotsGroups.length; i++) {
      if (this.lotsGroups[i].name === this.groupNameDeleteForm.value) {
        this.lotsList = this.lotsGroups[i].lots;
      }
    }
  }
  
  confirmDeleteGroup() {
    this.idGroupDelete =  this.lotsGroups.filter(x => x.name == this.groupNameDeleteForm.value);
    
    this.groupsService.deleteGroup(this.idGroupDelete[0].id).subscribe(
      data  => {
        console.log("DELETE Request is successful ", data);
        this.toast('success', this.groupNameDeleteForm.value, 'Parking Spaces group deleted successfully');
        },
        (error)  => {
        console.log("Error DELETE Request ", error);
        this.toast('error', this.groupNameDeleteForm.value, 'Parking Spaces group not deleted');
      }
    );
  }
  
  checkEmptyDelete(name) {
    if(name !== '' && name !== null) {
      this.displayButtonDelete = true;
    } else {
      this.displayButtonDelete = false;
    }
  }
  
  // Toast function
  toast(type: string, id: string, message: string) {
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      default:
        break;
    }
  }
  
}