import { Component, Input, ElementRef, QueryList, ViewChildren, HostListener, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { _fixedSizeVirtualScrollStrategyFactory } from "@angular/cdk/scrolling";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import OSM from "ol/source/OSM";
import * as olProj from "ol/proj";
import TileLayer from "ol/layer/Tile";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Style, Text, Stroke } from "ol/style";
import { Zoom } from "ol/control";
import { Draw, Translate } from "ol/interaction";
import Graticule from "ol/layer/Graticule";
import GeometryType from "ol/geom/GeometryType";
import Polygon from "ol/geom/Polygon";

import { Parking } from "src/app/_shared/models/parking";
import { ImageInfo } from "src/app/_shared/models/image";

import { ParkingService } from "../../_services/parking.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { TypeService } from "../../_services/type.service";
import { StyleService } from "src/app/_services/style.service";

import { ModalComponent, ModalDirective } from "@inst-iot/bosch-angular-ui-components";

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

const pointFeatureRadius = 20;

@Component({
  selector: "app-editstreetmap",
  templateUrl: "./editstreetmap.component.html",
  styleUrls: ["./editstreetmap.component.scss"],
})
export class EditstreetmapComponent implements OnInit {
  @Input() parkings: Parking[];
  
  @ViewChildren("popUpInfo", {read: ModalDirective}) ngbPopoverInfo!: QueryList<ModalComponent>;
  
  labels: any[];
  map: Map;
  center: [number, number];
  vectorSource: any;
  vectorLayer: VectorLayer;
  tool: string = "none";
  ovLevel: any[];
  features: any[];
  translate: Translate;
  drawPolygon: Draw;
  info: ImageInfo;
  userCanEditStreetmap: boolean = false;
  displayEditStreetmap: boolean = false;
  
  registerAllActions: any[] = [];
  registerSingleAction: any[] = [];
  registerModalActions: any[] = [];
  
  // Form to Add Parking
  addParkingForm: UntypedFormGroup;
  parkingIdForm = new UntypedFormControl();
  parkingNameForm = new UntypedFormControl();
  parkingAddressForm = new UntypedFormControl();
  parkingLocationXForm = new UntypedFormControl();
  parkingLocationYForm = new UntypedFormControl();
  parkingSectionNameForm = new UntypedFormControl();
  displayConfirmAdd: boolean = false;
  
  constructor(
    private router: Router,
    public form: UntypedFormBuilder,
    private loc: Location,
    private park: ParkingService,
    public types: TypeService,
    public auth: AuthenticationService,
    private style: StyleService,
    private toastrService: ToastrService,
  ) {
    
    this.addParkingForm = form.group({
      parkingIdForm: this.parkingIdForm,
      parkingNameForm: this.parkingNameForm,
      parkingAddressForm: this.parkingAddressForm,
      parkingLocationXForm: this.parkingLocationXForm,
      parkingLocationYForm: this.parkingLocationYForm,
      parkingSectionNameForm: this.parkingSectionNameForm
    });
    
    this.labels = [];
    this.ovLevel = [];
    this.center = [0, 0];
    this.features = [];
    
    this.translate = new Translate();
    
    this.drawPolygon = new Draw({
      source: this.vectorSource,
      type: 'Polygon' as GeometryType,
    });
  }
  
  async ngOnInit() {
    this.userCanEditStreetmap = (await this.auth.isAdmin() || await this.auth.isOwner())
    if(this.userCanEditStreetmap) {
      this.displayEditStreetmap = true;
      
      // Compute min/maxLocationX and min/maxLocationY
      const maxLocationX = Math.max.apply(
        Math,
        this.parkings.map(function (o) {
          return o.location.x;
        })
      );
      const minLocationX = Math.min.apply(
        Math,
        this.parkings.map(function (o) {
          return o.location.x;
        })
      );
      const maxLocationY = Math.max.apply(
        Math,
        this.parkings.map(function (o) {
          return o.location.y;
        })
      );
      const minLocationY = Math.min.apply(
        Math,
        this.parkings.map(function (o) {
          return o.location.y;
        })
      );
      
      // Creation of parking features
      this.parkings.forEach((parking, index) => {
        console.log(parking);
        console.log(index);
        const pos = [parking.location.y, parking.location.x];
        const singleFeature = new Feature({
          geometry: new Point(olProj.fromLonLat(pos)),
          description: parking.name,
          park_id: parking.id,
        });
        singleFeature.set("value", parking.n_lot_free);
        this.features.push(singleFeature);
        
        this.center[0] = this.center[0] + (maxLocationX + minLocationX) / 2;
        this.center[1] = this.center[1] + (maxLocationY + minLocationY) / 2;
      });
      
      this.vectorSource = new VectorSource({
        features: this.features,
      });
      
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
        style: this.styleFunction,
      });
      
      this.center[0] = this.center[0] / this.parkings.length;
      this.center[1] = this.center[1] / this.parkings.length;

      var mapwidth = document.getElementById('edit-map');
      if(maxLocationY < 0 || minLocationY <0) {
        var maxPixelX = (((maxLocationY+180)*(mapwidth.clientWidth/360)));
        var minPixelX = (((minLocationY+180)*(mapwidth.clientWidth/360)));
      } else {
        var maxPixelX = (((maxLocationY)*mapwidth.clientWidth)/360);
        var minPixelX = (((minLocationY)*mapwidth.clientWidth)/360);
      }
      var pixelDiff = maxPixelX-minPixelX;
      var longDiff = (maxLocationY+180)-(minLocationY+180);
      
      // Function to calculate the zoom based on the position of xMin and xMax
      function getMinZoom(center: number) {
        var width = (center + (center - (minPixelX)) + ((maxPixelX) - center));
        var scaleFactor = width*256/mapwidth.clientWidth
        if (longDiff<100) {
          return Math.ceil(Math.LOG2E * Math.log(width));
        } else {
          return Math.ceil(Math.LOG2E * Math.log(width/scaleFactor));
        }
      }

      var computedZoom = getMinZoom(pixelDiff);
      
      // Creation StreetMap
      this.map = new Map({
        target: "edit-map",
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new Graticule({
            strokeStyle: new Stroke({
              color: "#6f6f76",
              width: 1,
            }),
            showLabels: true,
            wrapX: false,
            targetSize: 30,
          }),
          this.vectorLayer,
        ],
        controls: [
          new Zoom({
            className: 'zoomControl'
          })
        ],
        
        overlays: this.ovLevel,
        view: new View({
          center: olProj.fromLonLat([this.center[1], this.center[0]]),
          zoom: isNaN(computedZoom) || !isFinite(computedZoom) ? 1 : computedZoom,
        }),
      });
      
      // Function to compute dynamically the height of the map
      this.style.computeHeight(0);
      this.map.updateSize();
      
      // Check on the Add New Parking modal fields  
      this.addParkingForm.valueChanges.subscribe(changes=>{
        this.displayConfirmAdd = false;
        this.checkEmpty();
      });
      
      /*
      on map click:
      - if is feature
        - move Parking or delete Parking or modify Parking
      */
      this.map.on("click", (event) => {
        var feature = this.map.forEachFeatureAtPixel(
          event.pixel,
          function (feature) {
            return feature;
          }
        );
        if (feature) {
          if (this.tool === "mod") {
            
            // Modify a Parking
            var park_id = feature.get("park_id");
            var featGeometry = feature.getGeometry();
            
            if (featGeometry instanceof Point) {
              var popOverElements = [];
              popOverElements = this.ngbPopoverInfo.toArray();
              var popOverComponent = popOverElements.find(
                (x) => x.content.elementRef.nativeElement.previousSibling.id == park_id
              );
              popOverComponent.modalService.open(popOverComponent.content)
            }
            
          } else if (this.tool === "delete") {
            
            // Delete a Parking
            this.buttonState();
            if (feature instanceof Feature) {
              var remID = feature.get("park_id");
              this.parkings.splice(
                this.parkings.indexOf(this.parkings.find((x) => x.id == remID)),
                1
              );
              this.vectorSource.removeFeature(feature);
            }
            
            this.registerSingleAction[0] = "DELETE";
            this.registerSingleAction[1] = feature;
            this.updateEvent();
          }
          
        } else if (this.tool === "Polygon") {
          var newParkName = this.info.parking_id + "-0000new";
        }
      });
      
      //Move Interaction
      this.translate.on("translatestart", (event) => {
        document.body.style.cursor = "grabbing";
        if(this.registerAllActions.length == 0) {
          this.registerSingleAction[0] = "MOVE";
          this.registerSingleAction[1] = event.features.getArray()[0];
          console.log("Old Latitude: " + olProj.toLonLat(event.coordinate)[1])
          console.log("Old Longitude: " + olProj.toLonLat(event.coordinate)[0])
          this.registerSingleAction[1].values_.oldLatitude = olProj.toLonLat(event.coordinate)[1];
          this.registerSingleAction[1].values_.oldLongitude = olProj.toLonLat(event.coordinate)[0];
          this.registerSingleAction[1].values_.coordinate = event.coordinate;
        } else {
          let newEvent: any = event.features.getArray()[0];
          for (let i = 0; i<this.registerAllActions.length; i++) {
            if(this.registerAllActions[i][0] == 'MOVE' && this.registerAllActions[i][1].values_.description == newEvent.values_.description) {
              this.registerSingleAction[0] = "MOVE";
              this.registerSingleAction[1] = event.features.getArray()[0];
              return;
            }
          }
          this.registerSingleAction[0] = "MOVE";
          this.registerSingleAction[1] = event.features.getArray()[0];
          console.log("Old Latitude: " + olProj.toLonLat(event.coordinate)[1])
          console.log("Old Longitude: " + olProj.toLonLat(event.coordinate)[0])
          this.registerSingleAction[1].values_.oldLatitude = olProj.toLonLat(event.coordinate)[1];
          this.registerSingleAction[1].values_.oldLongitude = olProj.toLonLat(event.coordinate)[0];
          this.registerSingleAction[1].values_.coordinate = event.coordinate;
        }
      });
      
      this.translate.on("translateend", (event) => {
        document.body.style.cursor = "grab";
        this.registerSingleAction[1].values_.newLatitude = olProj.toLonLat(event.coordinate)[1];
        this.registerSingleAction[1].values_.newLongitude = olProj.toLonLat(event.coordinate)[0];
        console.log("New Latitude: " + olProj.toLonLat(event.coordinate)[1])
        console.log("New Longitude: " + olProj.toLonLat(event.coordinate)[0])
        this.updateEvent();
        this.toast('success', (event.features.getArray()[0] as any).values_.description,'Parking moved successfully')
      });
      
      this.drawPolygon.on("drawend", (event) => {
        var newPolygonFeature = event.feature;
        this.vectorSource.addFeature(newPolygonFeature);
        this.vectorLayer.setStyle(this.styleFunction);
      });
      
    } else {
      this.toast('error', 'You do not have permission to access this page.', 'Access Denied')
      setTimeout(()=>{this.loc.back();},3000);
    }
  }
  
  // End ngOnInit
  
  //Check if the Add New Parking Fields is empty
  checkEmpty() {
    if(this.addParkingForm.controls.parkingIdForm.value !== null && this.addParkingForm.controls.parkingNameForm.value !== null && this.addParkingForm.controls.parkingAddressForm.value !== null && this.addParkingForm.controls.parkingLocationXForm.value !== null && this.addParkingForm.controls.parkingLocationYForm.value !== null && this.addParkingForm.controls.parkingSectionNameForm.value !== null) {
      this.displayConfirmAdd = true;
      return false;
    } else {
      this.displayConfirmAdd = false;
      return true;
    }
  }
  
  // Confirmation Add a new parking
  confirmAddNewParking() {
    let newParkingBody = {};
    newParkingBody = {
      id: this.addParkingForm.controls.parkingIdForm.value,
      name: this.addParkingForm.controls.parkingNameForm.value,
      address: this.addParkingForm.controls.parkingAddressForm.value,
      location: {
        x: this.addParkingForm.controls.parkingLocationXForm.value,
        y: this.addParkingForm.controls.parkingLocationYForm.value,
        type: "Point",
        coordinates: [
          this.addParkingForm.controls.parkingLocationXForm.value,
          this.addParkingForm.controls.parkingLocationYForm.value
        ]
      },
      section: [
        {
          name: this.addParkingForm.controls.parkingSectionNameForm.value,
          lots: [],
          display_id: []
        }
      ],
      groups: []
    };
    console.log("New Parking Body: " + newParkingBody);
    
    try {
      this.park.addNewParking(newParkingBody);
      this.toast('success', this.addParkingForm.controls.parkingNameForm.value,'New parking created successfully');
    } catch (error) {
      this.toast('error', this.addParkingForm.controls.parkingNameForm.value, 'New parking not created');
    }
    
    setTimeout(()=>{this.reloadPage();},4000);
    
  }
  
  // Function to remove interaction with the map
  toolChange(val: string) {
    this.tool = val;
    if (this.tool !== "move") {
      this.map.removeInteraction(this.translate);
    }
    if (this.tool !== "Polygon") {
      this.map.removeInteraction(this.drawPolygon);
    }
  }
  
  // Function to draw polygon
  drawPolygonFeatures() {
    this.map.addInteraction(this.drawPolygon);
  }
  
  // Function to select features
  dragAndDropFeatures() {
    this.map.addInteraction(this.translate);
  }

  // Function to store events in array
  updateEvent() {
    if(this.registerSingleAction[0] === 'MOVE' && this.registerAllActions.length > 0) {
      for (let i = 0; i<this.registerAllActions.length; i++) {
        if(this.registerAllActions[i][0] == 'MOVE' && this.registerAllActions[i][1].values_.description == this.registerSingleAction[1].values_.description) {
          this.registerAllActions[i] = this.registerSingleAction;
          this.registerSingleAction = [];
          return;
        }
      }
      this.registerAllActions.push(this.registerSingleAction);
      this.registerSingleAction = [];
    } else {
    this.registerAllActions.push(this.registerSingleAction);
    this.registerSingleAction = [];
    }
  }
  
  // Undo Single Event
  undoEvent() {
    this.buttonState();
    this.tool = "none";
    // Storing and removing the last event
    var lastAction = this.registerAllActions.pop();
    if (lastAction[0] == "DELETE") {
      this.vectorSource.addFeature(lastAction[1]);
    } else if (lastAction[0] == "MOVE") {
        var geom = lastAction[1].getGeometry();
        geom.setCoordinates(lastAction[1].values_.coordinate)
    }
  }
  
  // Undo All event
  undoAllEvent() {
    // Delete all events and restore previous features
    this.buttonState();
    this.tool = "none";
    for (let i = this.registerAllActions.length - 1; i >= 0; i--) {
      var lastAction = this.registerAllActions.pop();
      if (lastAction[0] == "DELETE") {
        this.vectorSource.addFeature(lastAction[1]);
      } else if(lastAction[0] == "MOVE") {
          var geom = lastAction[1].getGeometry();
          geom.setCoordinates(lastAction[1].values_.coordinate)
      }
    }
  }
  
  
  //Escape listener to close tool
  @HostListener("document:keydown.escape", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log("Escape pressed");
    this.buttonState();
    this.tool = "none";
  }

  // Function to update the status of the buttons
  buttonState() {
    document.body.style.cursor = "default";
    this.map.removeInteraction(this.translate);
    this.map.removeInteraction(this.drawPolygon);
  }
  
  // Change the cursor style to modify a Dot
  modifyDot() {
    document.body.style.cursor = "pointer";
  }
  
  // Change the cursor style to delete a Dot
  deleteDot() {
    document.body.style.cursor = "pointer";
  }
  
  // Function to manage the Save Modal
  saveButton() {
    this.registerModalActions = this.registerAllActions.slice(0);
    document.body.style.cursor = "default";
  }
  
  // Function to discard a change
  discardChanges(item) {
    this.registerModalActions.splice(this.registerModalActions.indexOf(item), 1);
  }
  
  //Modal to confirm saves
  async saveModification() {
    
    for (let i = 0; i < this.registerModalActions.length; i++) {
      if (this.registerModalActions[i][0] === 'DELETE') {
        try {
          this.park.deleteParking(this.registerModalActions[i][1].values_.park_id)
          this.toast('success', this.registerModalActions[i][1].values_.park_id, 'Parking deleted successfully')
        } catch (error) {
          this.toast('error', this.registerModalActions[i][1].values_.park_id, 'Parking not deleted')
        }
      }
      if (this.registerModalActions[i][0] === 'MOVE')  {
        let bodyModify = {}
        for(let j = 0; j<this.parkings.length; j++) {
          if(this.parkings[j].id ==  this.registerModalActions[i][1].values_.park_id) {
            bodyModify = {
              id: this.parkings[j].id,
              name: this.parkings[j].name,
              address: this.parkings[j].address,
              location: {
                x: this.registerModalActions[i][1].values_.newLatitude,
                y: this.registerModalActions[i][1].values_.newLongitude,
                type: this.parkings[j].location.type,
                coordinates: [this.registerModalActions[i][1].values_.newLatitude, this.registerModalActions[i][1].values_.newLongitude]
              },
              section: this.parkings[j].section,
              groups: this.parkings[j].groups,
            }
          }
        }
        try {
          console.log(bodyModify)
          this.park.updateParking(this.registerModalActions[i][1].values_.park_id, bodyModify);
          this.toast('success', this.registerModalActions[i][1].values_.park_id, 'Parking updated successfully');
        } catch (error) {
          this.toast('error', this.registerModalActions[i][1].values_.park_id, 'Parking not updated');
        }
      }
    }
    setTimeout(()=>{this.reloadPage();},4000);
  }
  
  //Function to close Edit Parking Modal
  eventHandlerFunction(valueEmitted) {
    if (valueEmitted === true) {
      var popOverElements = [];
      popOverElements = this.ngbPopoverInfo.toArray();
      for (let i=0; i<popOverElements.length; i++) {
        var popOverComponent = popOverElements[i]
        popOverComponent.modalService.close();
      }
    }
  }
  
  reloadPage() {
    let currentRoute = "/branch";
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'edit' }, queryParamsHandling: 'merge' }); // navigate to same route
    });
  }
  
  // Set the Style of the clustered feature
  styleFunction(feature) {
    if (feature.getGeometry() instanceof Polygon) {
      var iconStyle = new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(112, 191, 84, 0.7)",
        }),
      });
      return iconStyle;
    }
    // Name of each Parking, no name if is a cluster
    var parkingName = "";
    parkingName = feature.get("description");
    
    var textLabel = new Text({
      font: "18" + "px sans-serif",
      text: parkingName,
      offsetY: -30,
      overflow: true,
      fill: new Fill({ color: "white" }),
      stroke: new Stroke({ color: "black", width: 3 }),
    });
    
    // Color strategy of each Parking Feature, red if no free parking available, green otherwise
    var parkingColor = "#70BF54";
    
    // Parking Feature with Parking Name Label
    var iconStyle = new Style({
      image: new Circle({
        radius: pointFeatureRadius,
        fill: new Fill({
          color: parkingColor,
        }),
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
      }),
      text: textLabel,
    });
    return iconStyle;
  }
  
  // Toast function
  toast(type: string, id: string, message: string){
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'info':
        this.toastrService.info(id, message, {
          positionClass: 'toast-bottom-center',
          progressBar: false,
          closeButton: false,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      default:
        break;
    }
  }
}