import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/_services/analytics.service';

import Chart from 'chart.js/auto';

import { jsPDF } from "jspdf";

const { DateTime } = require("luxon");

@Component({
  selector: "app-lot-charts",
  templateUrl: "./lot-charts.component.html",
  styleUrls: ["./lot-charts.component.scss"],
})
export class LotChartsComponent implements OnInit {
  // INPUT declarations
  @Input() lotId: string;
  
  // CHART declarations
  public dailyChartOccupancy: Chart;
  public ctxDaily: any;
  public daysrangeChartOccupancy: Chart;
  public ctxDaysRange: any;
  public weeklyChartOccupancy: Chart;
  public ctxWeekly: any;
  
  // DatePicker
  public myGroup: UntypedFormGroup;
  public minDate = new Date();
  public maxDate = new Date();
  public yesterday = new Date();
  public dayBefore = new Date();
  public dayEndBefore = new Date();
  
  // API variables
  public dailyDataOccupancyObj: any;
  public dayOccupancyRate: number[] = [];
  public daysrangeDataOccupancyObj: any;
  public daysrangeOccupancyRate: number[] = [];
  public daysrangeDayRange: string[] = [];
  public weeklyDataOccupancyObj: any;
  public weekOccupancyRate: number[] = [];
  public weekDayRange: string[] = [];
  public step;
  
  // Variable declarations
  public mapLoaded = true;
  public showErrorHourly: boolean = false;
  public showErrorDaily: boolean = false;
  public showErrorWeekly: boolean = false;
  offsetName: string;
  
  //Time range
  dayOccupancyRateTime: any;
  weeklyDataOccupancyTimeObj: any;
  daysrangeDataOccupancyTimeObj: any;
  timeStart = {hour: 0, minute: 0};
  timeEnd = {hour: 24, minute: 0}
  meridian = true;
  hourStep = 1;
  minuteStep = 15;
  
  //Controls Day
  minCheckDate = new Date();
  maxCheckDate = new Date();
  actualDay = new Date();
  previousDay = new Date();
  nextDay = new Date();
  actualEndDay = new Date();
  previousEndDay = new Date();
  nextEndDay = new Date();
  
  //Download variables
  hourlyOccupancy: any;
  dailyOccupancy: any;
  weeklyOccupancy: any;
  title: any;
  
  constructor(private analytics: AnalyticsService) {}
  
  async ngOnInit() {
    
    this.minDate.setFullYear(this.maxDate.getFullYear(), this.maxDate.getMonth() - 3, this.maxDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    this.minCheckDate = this.minDate;
    this.maxCheckDate = this.maxDate;
    
    this.myGroup = new UntypedFormGroup({
      date: new UntypedFormControl(this.maxDate),
      endDate: new UntypedFormControl(this.maxDate)
    });
    
    this.createChart();
    this.yesterday.setDate(this.maxDate.getDate() - 1);
    this.dataComputation(this.yesterday, this.yesterday, "HOUR","DAY");
    
    this.myGroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(dateSelected => {
      if(dateSelected.date !== null && dateSelected.endDate !== null) {
        this.dataComputation(dateSelected.date, dateSelected.endDate, "HOUR", "DAY");
      }
    })
    
    let offsetNameShort = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameShort;
    let offsetNameLong = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameLong;
    this.offsetName = offsetNameShort + " (" + offsetNameLong + ")"
    
    this.myGroup.valueChanges.subscribe(change=>{
      let offsetNameShort = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameShort;
      let offsetNameLong = DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offsetNameLong;
      this.offsetName = offsetNameShort + " (" + offsetNameLong + ")"
      this.checkDay();
    })
    
    this.checkDay();
  }
  
  ngOnChanges() {
    setTimeout(() => {
      this.dayBefore.setFullYear(this.myGroup.get("date").value.getFullYear(), this.myGroup.get("date").value.getMonth(), this.myGroup.get("date").value.getDate() - 1);
      this.dayEndBefore.setFullYear(this.myGroup.get("endDate").value.getFullYear(), this.myGroup.get("endDate").value.getMonth(), this.myGroup.get("endDate").value.getDate() - 1);
      this.dataComputation(this.dayBefore, this.dayEndBefore, "HOUR","DAY");
    }, 500)
  }
  
  createChart() {
    // Create chart HOURLY OCCUPANCY
    this.ctxDaily = document.getElementById("dailyOccupancy");
    this.dailyChartOccupancy = new Chart("dailyOccupancy", {
      type: "bar",
      data: {
        labels: ["12am","1am","2am","3am","4am","5am","6am","7am","8am","9am","10am","11am","12pm","1pm","2pm","3pm","4pm","5pm","6pm","7pm","8pm","9pm","10pm","11pm"],
        datasets: [
          {
            label: "Occupancy",
            backgroundColor: "#005691",
            borderColor: "#005691",
            data: this.dayOccupancyRate,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: false
            }
          },
          y:
            {
              title: {
                display: true,
                text: "Hourly Occupancy (%)",
              },
              min: 0,
              max: 100,
              ticks: {
                stepSize: 10,
              },
            },  
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        }
      },
    });
    
    // Create chart DAILY OCCUPANCY
    this.ctxDaysRange = document.getElementById("daysrangeOccupancy");
    this.daysrangeChartOccupancy = new Chart("daysrangeOccupancy", {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Occupancy",
            backgroundColor: "#00d07f",
            borderColor: "#00d07f",
            data: this.daysrangeOccupancyRate,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y:
            {
              title: {
                display: true,
                text: "Daily Occupancy (%)",
              },
              min: 0,
              max: 100,
              ticks: {
                stepSize: 10,
              },
            },
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        }
      },
    });
    
    // Create chart WEEKLY OCCUPANCY
    this.ctxWeekly = document.getElementById("weeklyOccupancy");
    this.weeklyChartOccupancy = new Chart("weeklyOccupancy", {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: "Occupancy",
            backgroundColor: "#50237f",
            borderColor: "#50237f",
            data: this.weekOccupancyRate,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y:
            {
              title: {
                display: true,
                text: "Weekly Occupancy (%)",
              },
              min: 0,
              max: 100,
              ticks: {
                stepSize: 10,
              },
            },
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
        }
      },
    });
    
    /* var weeklyChartRef = this.weeklyChartOccupancy;
    document.getElementById("weeklyOccupancy").onclick = function (evt) {
    var activePoints = weeklyChartRef.getElementsAtEvent(evt);
    var firstPoint = activePoints[0];
    var label = weeklyChartRef.data.labels[firstPoint._index];
    var value =
      weeklyChartRef.data.datasets[firstPoint._datasetIndex].data[
        firstPoint._index
      ];
    if (firstPoint !== undefined) console.log(label + ": " + value);
    }; */
    
    this.mapLoaded = true;
  }
  
  async dataComputation(genericDate: Date, endDate: Date, dayStep: string, weekStep: string) {
    // DAY COMPUTATION CHART
    this.computeChart([],[],[]);
    this.mapLoaded = false;
    
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    var startDayDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').plus({days: 1}).toUTC().startOf('day').toISO();
    var endDayDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO();
    
    console.log("START DAY ", startDayDate)
    console.log("END DAY ", endDayDate)
    
    await this.analytics.getLotOccupancy(this.lotId, startDayDate, endDayDate, dayStep).then((x) => {
      if(x.status !== 200) {
        this.showErrorHourly = true;
        this.dailyDataOccupancyObj = x.body;
      } else {
        this.showErrorHourly = false;
        this.dailyDataOccupancyObj = x.body;
      }
    });
    
    if(this.dailyDataOccupancyObj?.occupancies?.length > 0) {
      let occupanciesObj = this.dailyDataOccupancyObj?.occupancies;
      
      occupanciesObj = occupanciesObj.reduce(function (r, a) {
        r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour] = r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour] || [];
        r[DateTime.fromISO(a.start).plus({hours: offset}).toUTC().toObject().hour].push(a);
        return  r
      }, {});
      
      var occupanciesArray = Object.keys(occupanciesObj).map(function(index){
        let a = occupanciesObj[index];
        return a;
      })
      
      this.dayOccupancyRate = [];
      this.dayOccupancyRateTime = [];
      for(let i = 0; i<occupanciesArray.length; i++) {
        let dayTotalOccupancy = 0;
        let start = '';
        for (let j=0; j<occupanciesArray[i].length; j++) {
          dayTotalOccupancy += occupanciesArray[i][j].occupancy_rate;
          start = occupanciesArray[i][j].start;
        }
        this.dayOccupancyRate.push((dayTotalOccupancy/occupanciesArray[i].length)*100);
        this.dayOccupancyRateTime.push({start: start, occupancy_rate: (dayTotalOccupancy/occupanciesArray[i].length)*100});
      }
    
      // Round to 2 decimal points
      this.dayOccupancyRate = this.dayOccupancyRate.map(function (each_element) {
        return Number(each_element.toFixed(2));
      });
    } else {
      this.mapLoaded = true;
    }
    
    // DAYS RANGE COMPUTATION CHART
    this.computeChart([],[],[]);
    this.daysrangeDayRange = [];
    this.mapLoaded = false;
    
    var startDaysRangeDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').plus({days: 1}).toUTC().startOf('day').toISO();
    var endDaysRangeDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO();
    
    console.log("START DAYS RANGE ", startDaysRangeDate)
    console.log("END DAYS RANGE ", endDaysRangeDate)
    
    await this.analytics.getLotOccupancy(this.lotId, startDaysRangeDate, endDaysRangeDate, weekStep).then((x) => {
      if(x.status !== 200) {
        this.showErrorDaily = true;
        this.daysrangeDataOccupancyObj = x.body;
      } else {
        this.showErrorDaily = false;
        this.daysrangeDataOccupancyObj = x.body;
      }
    });
    
    if(this.daysrangeDataOccupancyObj?.occupancies?.length > 0) {
      for (let i = 0; i < this.daysrangeDataOccupancyObj.occupancies.length; i++) {
        this.daysrangeOccupancyRate[i] = this.daysrangeDataOccupancyObj.occupancies[i]["occupancy_rate"] * 100;
        this.daysrangeDayRange[i] = DateTime.fromISO(this.daysrangeDataOccupancyObj.occupancies[i]["start"]).toFormat('ccc, LLL dd, yyyy');
      }
      
      // Round to 2 decimal points
      this.daysrangeOccupancyRate = this.daysrangeOccupancyRate.map(function (each_element) {
        return Number(each_element.toFixed(2));
      });
    } else {
      this.mapLoaded = true;
    }
    
    // WEEK COMPUTATION CHART
    this.computeChart([],[],[]);
    this.weekDayRange = [];
    this.mapLoaded = false;
    
    var startWeekDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').minus({days: 5}).toUTC().startOf('day').toISO();
    var endWeekDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO();
    
    console.log("START WEEK ", startWeekDate)
    console.log("END WEEK ", endWeekDate)
    
    await this.analytics.getLotOccupancy(this.lotId, startWeekDate, endWeekDate, weekStep).then((x) => {
      if(x.status !== 200) {
        this.showErrorWeekly = true;
        this.weeklyDataOccupancyObj = x.body;
      } else {
        this.showErrorWeekly = false;
        this.weeklyDataOccupancyObj = x.body;
      }
    });
    
    if(this.weeklyDataOccupancyObj?.occupancies?.length > 0) {
      for (let i = 0; i < this.weeklyDataOccupancyObj.occupancies.length; i++) {
        this.weekOccupancyRate[i] = this.weeklyDataOccupancyObj.occupancies[i]["occupancy_rate"] * 100;
        this.weekDayRange[i] = DateTime.fromISO(this.weeklyDataOccupancyObj.occupancies[i]["start"]).toFormat('ccc, LLL dd, yyyy');
      }
      
      // Round to 2 decimal points
      this.weekOccupancyRate = this.weekOccupancyRate.map(function (each_element) {
        return Number(each_element.toFixed(2));
      });
    } else {
      this.mapLoaded = true;
    }
    
    // UPDATE CHART
    this.computeChart(this.dayOccupancyRate, this.daysrangeOccupancyRate, this.weekOccupancyRate);
    
    //Compute Analytics in a time range
    await this.analytics.getLotOccupancy(this.lotId, startWeekDate, endWeekDate, dayStep).then((x) => {
      if(x.status !== 200) {
        this.showErrorWeekly = true;
        this.weeklyDataOccupancyTimeObj = x.body;
      } else {
        this.showErrorWeekly = false;
        this.weeklyDataOccupancyTimeObj = x.body;
      }
    });
    
    //Compute Analytics in a time range - Days Range
    await this.analytics.getLotOccupancy(this.lotId, startDaysRangeDate, endDaysRangeDate, dayStep).then((x) => {
      if(x.status !== 200) {
        this.showErrorDaily = true;
        this.daysrangeDataOccupancyTimeObj = x.body;
      } else {
        this.showErrorDaily = false;
        this.daysrangeDataOccupancyTimeObj = x.body;
      }
    });
		
    let hourRange = this.timeEnd.hour - this.timeStart.hour;
    if(hourRange < 24) {
      this.computeTimeRange();
    }
  }
  
  computeChart(dayOccupancyRate, daysrangeOccupancyRate, weekOccupancyRate) {
    // DAY CHART UPDATE
    this.dailyChartOccupancy.data.datasets[0].data = dayOccupancyRate;
    this.dailyChartOccupancy.update();
    
    // DAYS RANGE CHART UPDATE
    this.daysrangeChartOccupancy.data.datasets[0].data = daysrangeOccupancyRate;
    this.daysrangeChartOccupancy.data.labels = this.daysrangeDayRange;
    this.daysrangeChartOccupancy.update();
    
    // WEEK CHART UPDATE
    this.weeklyChartOccupancy.data.datasets[0].data = weekOccupancyRate;
    this.weeklyChartOccupancy.data.labels = this.weekDayRange;
    this.weeklyChartOccupancy.update();

    this.mapLoaded = true;
  }
  
  computeTimeRange() {
    //UPDATE DAILY
    let hourRange = this.timeEnd.hour - this.timeStart.hour;
    let newlabel = []
    for(let i = 0; i<hourRange; i++) {
      newlabel[i] = this.timeStart.hour + i;
    }
    
    let newdataset = [];
    
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    for (let j=0; j<newlabel.length; j++) {
      for (let i = 0; i<this.dayOccupancyRateTime.length; i++) {
        if(DateTime.fromISO(this.dayOccupancyRateTime[i].start).plus({hours: offset}).toUTC().toObject().hour == newlabel[j]) {
          newdataset[j] = (this.dayOccupancyRateTime[i].occupancy_rate);
        }
      }
    }
    
    newdataset = newdataset.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    for(let i = 0; i<newlabel.length; i++) {
      if(newlabel[i] == 0) {
        newlabel[i] = '12am';
      }else if (newlabel[i] < 12) {
        newlabel[i] = newlabel[i] + 'am';
      } else if (newlabel[i] == 12) {
        newlabel[i] = newlabel[i] + 'pm';
      } else {
        newlabel[i] = (newlabel[i] - 12) + 'pm';
      }
    }
    
    this.dailyChartOccupancy.data.datasets[0].data = newdataset;
    this.dailyChartOccupancy.data.labels = newlabel;
    this.dailyChartOccupancy.update();
    
    //UPDATE DAYS RANGE
    //Selection of data in the time range given in input
    var newdatasetdaysrange = [];
    for (let i=0; i<this.daysrangeDataOccupancyTimeObj.occupancies.length; i++) {
      if(DateTime.fromISO(this.daysrangeDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour >=  this.timeStart.hour && DateTime.fromISO(this.daysrangeDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour <  this.timeEnd.hour) {
        newdatasetdaysrange[i] = this.daysrangeDataOccupancyTimeObj.occupancies[i];
      }
    }
    
    //Reduce function to group the occupancies of the same day 
    let newdatasetsdaysrange = newdatasetdaysrange.reduce(function (r, a) {
      r[DateTime.fromISO(a.start).toUTC().toObject().day] = r[DateTime.fromISO(a.start).toUTC().toObject().day] || [];
      r[DateTime.fromISO(a.start).toUTC().toObject().day].push(a);
      return  r
    }, {});
    
    //Compute Occupancy Rate in a time range 
    let daysrangeoccupancies = [];
    for (const i in newdatasetsdaysrange) {
      let daysrangeoccupancyRate = 0;
      for(let j = 0; j<newdatasetsdaysrange[i].length; j++) {
        daysrangeoccupancyRate += newdatasetsdaysrange[i][j].occupancy_rate;
      }
      daysrangeoccupancies.push((daysrangeoccupancyRate/hourRange)*100);
    }
    
    daysrangeoccupancies = daysrangeoccupancies.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    this.daysrangeChartOccupancy.data.datasets[0].data = daysrangeoccupancies;
    this.daysrangeChartOccupancy.data.labels = this.daysrangeDayRange;
    this.daysrangeChartOccupancy.update();
    
    //UPDATE WEEKLY
    //Selection of data in the time range given in input
    var newdatasetweek = [];
    for (let i=0; i<this.weeklyDataOccupancyTimeObj.occupancies.length; i++) {
      if(DateTime.fromISO(this.weeklyDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour >=  this.timeStart.hour && DateTime.fromISO(this.weeklyDataOccupancyTimeObj.occupancies[i].start).plus({hours: offset}).toUTC().toObject().hour <  this.timeEnd.hour) {
        newdatasetweek[i] = this.weeklyDataOccupancyTimeObj.occupancies[i];
      }
    }
    
    //Reduce function to group the occupancies of the same day 
    let newdatasetsweek = newdatasetweek.reduce(function (r, a) {
      r[DateTime.fromISO(a.start).toUTC().toObject().day] = r[DateTime.fromISO(a.start).toUTC().toObject().day] || [];
      r[DateTime.fromISO(a.start).toUTC().toObject().day].push(a);
      return  r
    }, {});
    
    //Compute Occupancy Rate in a time range 
    let occupancies = [];
    for (const i in newdatasetsweek) {
      let occupancyRate = 0;
      for(let j = 0; j<newdatasetsweek[i].length; j++) {
      occupancyRate += newdatasetsweek[i][j].occupancy_rate;
      }
      occupancies.push((occupancyRate/hourRange)*100);
    }
    
    occupancies = occupancies.map(function (each_element) {
      return Number(each_element.toFixed(2));
    });
    
    this.weeklyChartOccupancy.data.datasets[0].data = occupancies;
    this.weeklyChartOccupancy.data.labels = this.weekDayRange;
    this.weeklyChartOccupancy.update();
  }
  
  setPreviousDay() {
    this.actualDay = this.myGroup.get("date").value;
    this.previousDay = DateTime.fromJSDate(this.actualDay).minus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("date").setValue(this.previousDay);
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.previousEndDay = DateTime.fromJSDate(this.actualEndDay).minus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("endDate").setValue(this.previousEndDay);
  }
  
  setNextDay() {
    this.actualDay = this.myGroup.get("date").value;
    this.nextDay = DateTime.fromJSDate(this.actualDay).plus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("date").setValue(this.nextDay);
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.nextEndDay = DateTime.fromJSDate(this.actualEndDay).plus({days: 1}).startOf('day').toJSDate();
    this.myGroup.get("endDate").setValue(this.nextEndDay);
  }
  
  checkDay() {
    let min = DateTime.fromJSDate(this.minCheckDate).startOf('day');
    let max = DateTime.fromJSDate(this.maxCheckDate).startOf('day');
    this.actualDay = this.myGroup.get("date").value;
    this.previousDay = DateTime.fromJSDate(this.actualDay).minus({days: 1}).startOf('day').toJSDate();
    this.nextDay = DateTime.fromJSDate(this.actualDay).plus({days: 1}).startOf('day').toJSDate();
    this.actualEndDay = this.myGroup.get("endDate").value;
    this.previousEndDay = DateTime.fromJSDate(this.actualEndDay).minus({days: 1}).startOf('day').toJSDate();
    this.nextEndDay = DateTime.fromJSDate(this.actualEndDay).plus({days: 1}).startOf('day').toJSDate();
    if(DateTime.fromJSDate(this.previousDay).ts < min.ts || DateTime.fromJSDate(this.previousEndDay).ts < min.ts) {
      document.getElementById("previousDay").setAttribute('disabled','');
    } else {
      document.getElementById("previousDay").removeAttribute('disabled');
    }
    
    if (DateTime.fromJSDate(this.nextDay).ts > max.ts || DateTime.fromJSDate(this.nextEndDay).ts > max.ts) {
      document.getElementById("nextDay").setAttribute('disabled','');
    } else {
      document.getElementById("nextDay").removeAttribute('disabled');
    }
  }
  
  download() {
    let startDate = this.myGroup.get('date').value;
    let endDate = this.myGroup.get('endDate').value;
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    this.title = this.lotId;
    
    this.hourlyOccupancy = document.getElementById("dailyOccupancy");
    this.dailyOccupancy = document.getElementById("daysrangeOccupancy");
    this.weeklyOccupancy = document.getElementById("weeklyOccupancy");
    let hourlyOccupancyImage = this.hourlyOccupancy.toDataURL('image/jpg', 1.0);
    let dailyOccupancyImage = this.dailyOccupancy.toDataURL('image/jpg', 1.0);
    let weeklyOccupancyImage = this.weeklyOccupancy.toDataURL('image/jpg', 1.0);
    
    let pdf = new jsPDF();
    let pdfWidth = (pdf.internal.pageSize.getWidth())*0.8;
    let pdfHeight = (pdf.internal.pageSize.getHeight());
    let margin = (pdf.internal.pageSize.getWidth())*0.1;
    let imgPropsHourly= pdf.getImageProperties(hourlyOccupancyImage);
    let pdfHeightHourly = (imgPropsHourly.height * pdfWidth) / imgPropsHourly.width;
    let imgPropsDaily= pdf.getImageProperties(dailyOccupancyImage);
    let pdfHeightDaily = (imgPropsDaily.height * pdfWidth) / imgPropsDaily.width;
    let imgPropsWeekly= pdf.getImageProperties(weeklyOccupancyImage);
    let pdfHeightWeekly = (imgPropsWeekly.height * pdfWidth) / imgPropsWeekly.width;
    pdf.setFontSize(20);
    pdf.text("Parking Space Analytics: ", 10, 10);
    pdf.text(this.title, 10, 20);
    pdf.addImage("https://smart-parking-app-d.bosch.tech/assets/img/Bosch_EN_LV_RGB.png", "png",160, -2, 50, 24)
    pdf.setFontSize(10);
    pdf.text("Start Day: " + startDate.toDateString() + " - End Day: " + endDate.toDateString(), 10, 30);
    pdf.setFontSize(15);
    pdf.text("Hourly Occupancy Chart", 80, 40);
    pdf.addImage(hourlyOccupancyImage, 'JPEG', margin, 45, pdfWidth, pdfHeightHourly);
    pdf.text("Daily Occupancy Chart", 80, pdfHeightHourly+60);
    pdf.addImage(dailyOccupancyImage, 'JPEG', margin, pdfHeightHourly+65, pdfWidth, pdfHeightDaily);
    if(pdfHeight < pdfHeightHourly+pdfHeightDaily+pdfHeightWeekly+85) {
      pdf.addPage();
      pdf.addImage("https://smart-parking-app-d.bosch.tech/assets/img/Bosch_EN_LV_RGB.png", "png",160, -2, 50, 24)
      pdf.text("Weekly Occupancy Chart", 80, 30);
      pdf.addImage(weeklyOccupancyImage, 'JPEG', margin, 35, pdfWidth, pdfHeightWeekly);
    } else {
      pdf.text("Weekly Occupancy Chart", 80, pdfHeightHourly+pdfHeightDaily+80);
      pdf.addImage(weeklyOccupancyImage, 'JPEG', margin, pdfHeightHourly+pdfHeightDaily+85, pdfWidth, pdfHeightWeekly);
    }
    pdf.save(yyyy + "-" + mm + "-" + dd + " " + this.title + " Analytics" + ".pdf");
  }
  
}