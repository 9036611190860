import { OnInit, Component, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder } from '@angular/forms';
import { timer, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

import { Feature, View } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Cluster } from "ol/source";
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import { getCenter } from "ol/extent";
import Map from 'ol/Map';
import * as olExtent from "ol/extent";
import ZoomToExtent from 'ol/control/ZoomToExtent';

import { ImageInfo } from 'src/app/_shared/models/image';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from 'src/app/_services/parking.service';
import { TypeService } from 'src/app/_services/type.service';
import { AnalyticsService } from 'src/app/_services/analytics.service';
import { ImagesService } from 'src/app/_services/images.service';
import { StyleService } from 'src/app/_services/style.service';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

const { DateTime } = require("luxon");
const lot_type = "lot";

@Component({
  selector: 'app-heatmap-map-layer',
  templateUrl: './heatmap-map-layer.component.html',
  styleUrls: ['./heatmap-map-layer.component.scss']
})
export class HeatmapMapLayerComponent implements OnInit {
  
  @ViewChildren('popUpInfo') ngbPopoverInfo!: QueryList<NgbPopover>;
  map: Map;
  id: string;
  subs: Subscription;
  parsubs: Subscription;
  section: string = "";
  info: ImageInfo;
  url: string;
  lots: any;
  areas: any;
  extent: any;
  features: any;
  vectorSource: VectorSource<any>;
  vector: HeatmapLayer;
  vectorLayer: VectorLayer;
  pointFeatureRadius: number = 18;
  retriveImages: string;
  getImages: string;
  parkingId: string;
  images: any;
  parkImagesId: string;
  getUrl: string;
  mapLoaded = false;
  imagesInfo: any;
  endDate: any;
  startDate: any;
  
  constructor(
    public http: HttpClient,
    private park: ParkingService,
    private lotAnalytics: AnalyticsService,
    public auth: AuthenticationService,
    public types: TypeService,
    private route: ActivatedRoute,
    public imagesService: ImagesService, // Injecting the Service
    private fb: UntypedFormBuilder,
    private style: StyleService
  ) {
    this.features = [];
  }
  
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    console.log('unsuscribe polling');
    //this.subs.unsubscribe();
    this.parsubs.unsubscribe();
  }
  
  async ngOnInit() {
    
    console.log("load map layer");
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par: any) => {
      this.section = par.section;
      this.url = '../../../assets/images/' + this.id + '-' + this.section + '.jpg';
      this.parkImagesId = this.id + '-' + this.section;
      this.retriveImages = (environment.apiUri + "v2/images/" + this.parkImagesId);
    });
    
    this.endDate = new Date();
    this.startDate = new Date();
    this.endDate.setDate(this.endDate.getDate()-1);
    this.startDate.setDate(this.startDate.getDate()-16);
    this.endDate = DateTime.fromISO(this.endDate.toISOString()).plus({days: 1}).toUTC().startOf('day').toISO();
    this.startDate = DateTime.fromISO(this.startDate.toISOString()).plus({days: 1}).toUTC().startOf('day').toISO();
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(x => this.imagesInfo = x as any);
    
    let extentHeight = this.imagesInfo.y_multiplier;
    let extentWidth = this.imagesInfo.x_multiplier;
    this.extent = [0, 0, extentWidth, extentHeight];
    let projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: this.extent,
    });
    
    //Setup a Vector Source
    this.vectorSource = new VectorSource({
      features: [],
    });
    
    //Setup a Vector Layer
    this.vectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: this.vectorSource,
      }),
      style: this.styleObject,
    });
    
    this.vector = new HeatmapLayer({
      source: this.vectorSource,
      blur: 8,
      radius: 20,
      gradient: ['#00f', '#0f0', '#ff0', '#f00'],
      weight: function (feature) {
        const name = feature.get('description');
        const saturation = feature.get('saturation');
        return saturation;
      },
    });
    
    let sourceStaticImage = new Static({
      imageLoadFunction: (function (image, src) {
        this.imagesService.retriveImages(src)
          .subscribe(data => {
            let reader = new FileReader();
            reader.addEventListener("loadend", () => {
              image.getImage().src = reader.result;
            }, false);
            if (data) {
              reader.readAsDataURL(data);
            }
          }, error => {
            console.log(error);
          });
      }).bind(this),
      url: this.parkImagesId,
      projection: projection,
      imageExtent: this.extent
    });
    
    this.map = new Map({
      layers: [
        new ImageLayer({
          source: sourceStaticImage
        }),
        // this.vectorLayer //Add Vector in layers
      ],
      target: "map-layer",
      view: new View({
        projection: projection,
        center: getCenter(this.extent),
        maxZoom: 6,
        zoom: 1,
        extent: [-(0.2*extentWidth), -(0.2*extentHeight), extentWidth+(0.2*extentWidth), extentHeight+(0.2*extentHeight)],
        showFullExtent: true
      })
    });
    
    // Function to compute dynamically the height of the map
    this.style.computeHeight(25);
    this.map.updateSize();
    
    // Zoom Control Button
    let zoom = document.createElement("span");
    zoom.innerHTML = '<span class="rb-ic rb-ic-arrows-expand-object"></span>';
    let zoomControl = new ZoomToExtent({
      extent: [0, 0, extentWidth, extentHeight],
      label: zoom,
    });
    
    this.map.addControl(zoomControl);
    this.map.once("rendercomplete", (event) => {
      this.map.addLayer(this.vector);
    });
    
    this.map.getView().fit(sourceStaticImage.getImageExtent());
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(async (info) => {
      this.info = info as any;
      /*
      * insert Feature into openlayer map
      */
      for (let index = 0; index < this.info.dot_coordinate.length; index++) {
        if (this.info.dot_coordinate[index].type === lot_type) {
          const pos = [this.info.dot_coordinate[index].x, (extentHeight - this.info.dot_coordinate[index].y)];
          const singleFeature = new Feature({
            geometry: new Point(pos),
            description: this.info.dot_coordinate[index].name,
            status: "",
            type: "regular",
            saturation: 0
          });
          try {
            await this.lotAnalytics.getLotOccupancy(
              this.info.dot_coordinate[index].name,
              this.startDate,
              this.endDate,
              "DAY")
              .then((x:any) => {
                singleFeature.set("saturation", x["avg_occupancy"]);
              }
            );
          } catch (error) {
            singleFeature.set("saturation", 0);
          }
          this.features.push(singleFeature);
        }
      }
      this.vectorSource.addFeatures(this.features);
      this.mapLoaded = true;
    });
    
    /*
      zoomCluster function to zoom on Cluster click
    */
    let zoomCluster = function (feature, map) {
      let features = feature.get("features");
      if (features.length > 1) {
        let extent = olExtent.createEmpty();
        features.forEach(function (feature) {
          olExtent.extend(extent, feature.getGeometry().getExtent());
        });
        let zoom = map.getView().getZoom();
        map.getView().fit(extent, map.getSize());
        map.getView().setZoom(zoom + 2);
        return true;
      } else {
        return false;
      }
    };
  }
  
  styleObject = (dot) => {
    
    let nFreeLots = 0;
    let nNotStatusLots = 0;
    let nAlertStatusLots = 0;
    let nBusyLots = 0;
    let nLotsLabel = "";
    let lotColor = '#BFC0C2';
    let lotStrokeColor = '#BFC0C2';
    let strokeWidth = 2;
    let colorText = "#fff";
    let colorTextStroke = "rgba(0, 0, 0, 0.6)";
    
    if (dot.get("features").length === 1) {
      switch (dot.get("features")[0].get("status")) {
        case "free": {
          lotColor = '#70BF54';
          break;
        }
        case "busy": {
          lotColor = '#E11F26';
          break;
        }
        case "alert": {
          lotColor = 'rgb(252,175,23)';
          nLotsLabel = '!';
          colorText = "rgb(0, 0, 0)";
          colorTextStroke = "rgb(255, 0, 0)";
          break;
        }
        default: {
          lotColor = '#BFC0C2';
          break;
        }
      }
      lotStrokeColor = 'black';
      
      if (dot.get("features")[0].get("status") != "alert") {
        switch (dot.get("features")[0].get("type")) {
          case "regular": {
            nLotsLabel = '';
            break;
          }
          case "management": {
            nLotsLabel = 'M';
            break;
          }
          case "guest": {
            nLotsLabel = 'G';
            break;
          }
          case "handicap": {
            nLotsLabel = 'H';
            break;
          }
          case "e-vehicle": {
            nLotsLabel = 'e';
            break;
          }
          case "short-term": {
            nLotsLabel = 's-t';
            break;
          }
          default: {
            nLotsLabel = '';
            break;
          }
        }
      }
    } else if (dot.get("features").length > 1) {
      console.log(dot.get("features"));
      for (let i = 0; i < dot.get("features").length; i++) {
        if (dot.get("features")[i].get("status") === 'free') {
          nFreeLots++;
        } else if (dot.get("features")[i].get("status") === '') {
          nNotStatusLots++;
        } else if (dot.get("features")[i].get("status") === 'alert') {
          nAlertStatusLots++;
        } else if (dot.get("features")[i].get("status") === 'busy') {
          nBusyLots++;
        }
      }
      strokeWidth = 2;
      lotStrokeColor = 'black';
      if (nFreeLots > 0) {
        lotColor = '#70BF54';
        nLotsLabel = nFreeLots.toString();
      } else if (nNotStatusLots > 0) {
        lotColor = '#BFC0C2';
        nLotsLabel = nNotStatusLots.toString();
      } else if (nAlertStatusLots > 0) {
        lotColor = 'rgb(252,175,23)';
        nLotsLabel = nAlertStatusLots.toString();
      } else if (nBusyLots > 0) {
        lotColor = '#E11F26';
        nLotsLabel = nBusyLots.toString();
      }
    }
    
    //Text fill style
    let textFill = new Fill({
      color: colorText
    });
    
    //Text stroke style
    let textStroke = new Stroke({
      color: colorTextStroke,
      width: 4,
    });
    
    //Number of free parking lot
    let textParkingFree = new Text({
      font: "20" + "px sans-serif",
      text: nLotsLabel,
      fill: textFill,
      stroke: textStroke,
    });
    
    let iconStyle = new Style({
      image: new Circle({
        radius: this.pointFeatureRadius,
        fill: new Fill({
          color: lotColor,
        }),
        stroke: new Stroke({
          color: lotStrokeColor,
          width: strokeWidth,
        }),
      }),
      text: textParkingFree,
    });
    return iconStyle;
  }
  
  generateRandomString(length = 6) {
    let randomChars = 'abcdef0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  
  generateRandomLastUpdate(sY, sM, sD, eY, eM, eD, startHour, endHour): string {
    let start: any;
    let end: any;
    start = new Date(sY, sM, sD);
    end = new Date(eY, eM, eD);
    
    let date = new Date(+start + Math.random() * (end - start));
    // tslint:disable-next-line: no-bitwise
    let hour = startHour + Math.random() * (endHour - startHour) | 0;
    date.setHours(hour);
    return date.toUTCString();
  }
  
  generateRandomNumberMaxMin(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1))).toString();
  }
  
  generateRandomNumberMaxMin_toNumber(max, min) {
    return (Math.trunc((Math.random() * (max - min) + 1)));
  }
}

function addNow(time): string {
  return new Date(new Date().getTime() + time).toISOString();
}