import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-mgmt',
  templateUrl: './branch-mgmt.component.html',
  styleUrls: ['./branch-mgmt.component.scss']
})
export class BranchMgmtComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
