import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisplaysService {
  
  constructor(private http: HttpClient) { }
  
  //Retrieve All Display
  retrieveAllDisplays() {
    const headers = new HttpHeaders({ 'accept': 'application/json'});
    return this.http.get(environment.apiUri + "displays", { headers} );
  }
  
  //Retrieve Display by Display Id
  retrieveDisplay(id) {
    return this.http.get(environment.apiUri + "displays/" + id).toPromise();
  }

  //Retrieve Display by Parking Id
  retrieveDisplayByParkingId(id) {
    return this.http.get(environment.apiUri + "displays/parkings/" + id);
  }
  
  //Create display
  createDisplay(body) {
    return this.http.post(environment.apiUri + "displays", body).toPromise();
  }
  
  //Update Display
  updateDisplay(id, body) {
    return this.http.put(environment.apiUri + "displays/" + id, body)
  }
  
  //Update Display
  deleteDisplay(id) {
    return this.http.delete(environment.apiUri + "displays/" + id).toPromise();
  }

  //Retrieve lots by display
  retrieveLotsDisplay(id) {
    return this.http.get(environment.apiUri + "displays/" + id + "/lots");
  }
  
  //Retrieve lots by display
  updateDisplayLots(id, body) {
    return this.http.patch(environment.apiUri + "displays/" + id + "/lots", body);
  }
}
