import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ImagesService } from 'src/app/_services/images.service';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})

export class UploadComponent implements OnInit {
  
  selectedFile = null;
  accessableParkings = [];
  parkingSelected = "";
  confirmationMessage: SafeHtml;
  isUserAllowedToUpload: boolean = false;
  
  addParkingImageForm: UntypedFormGroup;
  parkingNameAdd = new UntypedFormControl();
  sectionNameAdd = new UntypedFormControl();
  radiusLotAdd = new UntypedFormControl();
  radiusAreaAdd = new UntypedFormControl();
  radiusLotMobileAdd = new UntypedFormControl();
  clusterDistanceAdd = new UntypedFormControl();
  
  imageId: string = '';
  disableUpload: boolean = true;
  
  constructor(
    public http: HttpClient,
    public router: Router,
    private loc: Location,
    private sanitized: DomSanitizer,
    public form: UntypedFormBuilder,
    public auth: AuthenticationService,
    public imagesService: ImagesService,
    private toastrService: ToastrService,
  ) { 
    this.addParkingImageForm = form.group({
      parkingNameAdd: this.parkingNameAdd,
      sectionNameAdd: this.sectionNameAdd,
      radiusLotAdd: this.radiusLotAdd,
      radiusAreaAdd: this.radiusAreaAdd,
      radiusLotMobileAdd: this.radiusLotMobileAdd,
      clusterDistanceAdd: this.clusterDistanceAdd
    });
  }
  
  async ngOnInit() {
    this.isUserAllowedToUpload = await this.auth.isOwner();
    if(this.isUserAllowedToUpload) {
      this.http.get(environment.apiUri+"parkings").subscribe(res=>{
        for (const [key, value] of Object.entries(res)) {
          this.accessableParkings.push(value.id);
        }
        this.parkingSelected = this.accessableParkings[0]
      });
      
      this.addParkingImageForm.get('sectionNameAdd').setValue('0');
      this.addParkingImageForm.get('radiusLotAdd').setValue(9);
      this.addParkingImageForm.get('radiusAreaAdd').setValue(56);
      this.addParkingImageForm.get('radiusLotMobileAdd').setValue(9);
      this.addParkingImageForm.get('clusterDistanceAdd').setValue(20);
      
      this.addParkingImageForm.get('parkingNameAdd').valueChanges.subscribe(change=>{
        this.imageId = this.parkingNameAdd.value + '-' + this.sectionNameAdd.value;
      })
      
      this.addParkingImageForm.get('sectionNameAdd').valueChanges.subscribe(change=>{
        this.imageId = this.parkingNameAdd.value + '-' + this.sectionNameAdd.value;
      })
      
      setTimeout(()=>this.addParkingImageForm.controls.parkingNameAdd.setValue(this.parkingSelected), 1000)
    } else {
      this.toast('error', 'You do not have permission to access this page.', 'Access Denied')
      setTimeout(()=>{this.loc.back();},3000);
    }
  }
  
  onFileSelected(event) {
    if(event.length > 0) {
      this.disableUpload = false;
      this.selectedFile = event[0];
    } else {
      this.disableUpload = true;
    }
  }
  
  addNewImage() {
    const formData = new FormData();
    let image = this.selectedFile;
    if(image) {
      var blob = new Blob([image], {type: "image/jpeg"});
      formData.append('id', this.imageId);
      formData.append('parkingId ', this.parkingNameAdd.value)
      formData.append('radiusLot', this.radiusLotAdd.value);
      formData.append('radiusArea', this.radiusAreaAdd.value);
      formData.append('radiusLotMobile', this.radiusLotMobileAdd.value);
      formData.append('clusterDistance', this.clusterDistanceAdd.value);
      formData.append('file', blob);
      this.imagesService.uploadImage(formData).subscribe(res =>{
        if (res.ok) {
          this.confirmationMessage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-success"><span class="rb-ic rb-ic-alert-success-filled"></span>Upload Successfull for Image '+this.imageId+' of the parking '+this.parkingNameAdd.value+'</div>');
        } else {
          this.confirmationMessage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-error"><span class="rb-ic rb-ic-alert-error-filled"></span>Upload Failed: Server replied with status message '+ res.statusText + '</div>');
        }
      });
    } else {
      this.confirmationMessage = this.sanitized.bypassSecurityTrustHtml('<div class="rb-callout rb-callout-warning"><span class="rb-ic rb-ic-alert-warning-filled"></span>No image is selected</div>');
      throw new Error('No image is selected');
    }
  }
  
  //Toast function
  toast(type: string, id: string, message: string) {
    
    switch (type) {
      case 'success':
        this.toastrService.success(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'warning':
        this.toastrService.warning(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'error':
        this.toastrService.error(id, message, {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      case 'info':
        this.toastrService.info(id, message, {
          positionClass: 'toast-bottom-center',
          progressBar: false,
          closeButton: false,
          tapToDismiss: true,
          timeOut: 4000
        });
        break;
        
      default:
        break;
    }
  }
  
}
