import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthenticationComponent } from './authentication/authentication.component';

const AUTHENTICATION_ROUTES: Routes = [
  {
    path: "",
    component: AuthenticationComponent,
    children: [
      { path: "login", component: LoginComponent, data: { title: "Login" } },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(AUTHENTICATION_ROUTES)
  ],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
