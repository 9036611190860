import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';

import { AuthenticationModule } from '../authentication/authentication.module';
import { BranchMgmtModule } from '../branch-mgmt/branch-mgmt.module';
import { ParkingMgmtModule } from '../parking-mgmt/parking-mgmt.module';

import { HomeComponent } from './home/home.component';
import { LayoutComponent } from '../_shared/components/layout/layout.component';
import { ParkingDropdownComponent } from '../_shared/components/parking-dropdown/parking-dropdown.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RbUiComponentsModule, FormFieldsModule, TabPanelModule } from '@inst-iot/bosch-angular-ui-components';
import { MaterialModule } from '../_shared/modules/material.module';

@NgModule({
  declarations: [
    HomeComponent,
    LayoutComponent,
    ParkingDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule,
    AuthenticationModule,
    BranchMgmtModule,
    ParkingMgmtModule,
    NgbModule,
    RbUiComponentsModule,
    FormFieldsModule,
    TabPanelModule,
    MaterialModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
