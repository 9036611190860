export const environment = {
  production: false,
  appUri:"https://smart-parking-app-d.bosch.tech/",
  apiUri:"https://smart-parking-app-d.bosch.tech/api/",

  ciamIssuer: 'https://stage.singlekey-id.com/auth',
  ciamClientId: 'BFA546D2-D3B6-446C-95DE-E9B70D5A5637',
  ciamScope: 'openid email profile',
  ciamClientSecret: 'vt-nKZgrQiy-LuoT000DDaz11xRqepL7JROtuz28',
  ciamAccountPage:"https://stage.singlekey-id.com/myprofile/",
  ciamChangeEmailUri:"https://qa.identity-myprofile.bosch.tech/ui/web/changeemail",
  ciamDeregistrationUri:"https://stage.singlekey-id.com/auth/api/v1/UserDeletion",
  ciamRegistrationUri:"https://qa.identity-myprofile.bosch.tech/ui/web/registration",
  ciamChangePassword:"https://qa.identity-myprofile.bosch.tech/ui/web/forgotpassword",
  skidStyleId: {"style_id": "c_bsch_sp"},

  azureIssuer: 'https://login.microsoftonline.com/0ae51e19-07c8-4e4b-bb6d-648ee58410f4/v2.0',
  azureClientId: '90018714-a67c-4581-bdff-14874c77b261',
  azureScope: 'openid email profile api://90018714-a67c-4581-bdff-14874c77b261/api.user.read',
  azureSecretCode: '6nM8Q~7.AkRGuzYcrmrXNzwp9l2x2OQM7yupIbVY',
  azureLoginParam: "?response_type=code&scope=openid email profile api://90018714-a67c-4581-bdff-14874c77b261/api.user.read&client_id=90018714-a67c-4581-bdff-14874c77b261&code_challenge_method=S256&redirect_uri=https://smart-parking-app-d.bosch.tech",

  redirectUri: 'https://smart-parking-app-d.bosch.tech/home',
  //redirectUri: 'http://localhost:4200/home',
  responseType: 'code',
  postLogoutRedirectUri: 'https://smart-parking-app-d.bosch.tech/login',

};
