import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  router: any;

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

        if ([401, 403].includes(err.status)) {
          this.authenticationService.removeToken();
          this.authenticationService.authenticationFlow();
        }
        //const error = err.error?.message || err.statusText;
        console.error(err);
        return throwError(err);
    }));
  }
}