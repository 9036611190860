import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import jwt_decode from "jwt-decode";
import { User } from "../_shared/models/user";
import { Token } from "../authentication/_shared/models/token";
import { UserService } from "./user.service";
import { JwksValidationHandler, OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { authConfigAAD, authConfigSKID } from "../authentication/_helpers/auth-config";

const boschMailPattern = '([A-Z-a-z]{3}[0-9]{1}[A-Z-a-z]{2,3}@bosch.com$)'

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(
    JSON.parse(sessionStorage.getItem("user"))
  );
  mail: string;
  pattern: RegExp;
  azureTokenUri: string;
  azureLoginUri: string;
  azureLogoutUri: string;
  state: string;
  nonce: string;
  userName: string;
  public code_challenge: any;
  public code_verifier: any;

  constructor(
    public userService: UserService,
    private authStorage: OAuthStorage,
    private oauthService: OAuthService,
    private router: Router,
  ) {
    this.user = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("user"))
    );
  }

    // Observable string sources
    private homeComponent = new Subject<any>();
  
    // Observable string streams
    homeComponentCalled$ = this.homeComponent.asObservable();
  
    // Service message commands
    callLoginOrRegister() {
      this.homeComponent.next();
    }

  public configureAuthentication(issuer) {
    if (issuer === "AAD") {
      this.oauthService.configure(authConfigAAD);

    } else if (issuer === "SKID") {
      this.oauthService.configure(authConfigSKID);
    }
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocument();
  }

  public async authenticationFlow() {
    this.setAuthConfiguration();
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          this.callLoginOrRegister()
        } else {
          this.oauthService.initCodeFlow('', this.mail);
        }
      })
      .catch(err =>
        console.log(err));
  }

  public setAuthConfiguration() {
    this.mail = localStorage.getItem('mail')
    if (!this.mail) {
      this.router.navigate(["login"]);
    } else {
      this.pattern = new RegExp(boschMailPattern)
      if (this.pattern.test(this.mail)) {
        this.configureAuthentication("AAD");
        localStorage.setItem('type', "bosch");
      } else {
        this.configureAuthentication("SKID");
        localStorage.setItem('type', "skid");
      }
    }
  }

  public async doLogin() {
    let status = await this.getUserOrRegister()
    if(status === 404){
      return status;
    }
    var href = sessionStorage.getItem("href");
    if (href !== null) {
      window.open(sessionStorage.getItem("href"), "_self");
      sessionStorage.removeItem("href");
    } else {
      this.router.navigate(["branch"]);
    }
  }

  getToken(): Token {
    let token: Token;
    token = {
      id_token: this.authStorage.getItem('id_token'),
      access_token: this.authStorage.getItem('access_token'),
      expires_at: this.authStorage.getItem('expires_at'),
      token_type: '',
      refresh_token: this.authStorage.getItem('refresh_token')
    }

    return token;
  }

  async getUserOrRegister() {
    try {
      await this.userService.retrieveUser()
        .then(
          (data) => {
            console.log("user retrive");
            sessionStorage.setItem("user", JSON.stringify(data));
            this.user.next(data);
          })
    } catch (error) {
      console.log(error);

      if (error.status === 404) {
        return error.status;
      }
    }
  }

  public async registerUser() {
    var token = this.getToken();
    var decodedToken = jwt_decode(token.id_token);
    console.log("TOKEN ", decodedToken);
    this.userName = decodedToken["preferred_username"];
    this.userService.addNewUser(this.userName)
    this.callLoginOrRegister()
  }

  getUser(): User {
    return this.user.value;
  }

  logout() {
    localStorage.removeItem('mail');
    localStorage.removeItem('type');
    sessionStorage.removeItem('user');
    this.oauthService.logOut();
  }

  removeToken() {
    this.authStorage.removeItem('access_token');
    this.authStorage.removeItem('access_token_stored_at');
    this.authStorage.removeItem('refresh_token');
    this.authStorage.removeItem('id_token');
    this.authStorage.removeItem('id_token_claims_obj');
    this.authStorage.removeItem('id_token_expires_at');
    this.authStorage.removeItem('id_token_stored_at');
    console.log("Remove expired token");
  }

  isAuthenticated(): boolean {
    if (JSON.parse(sessionStorage.getItem("user")) && this.authStorage.getItem('access_token')) {
      return true;
    } else {
      console.log("not authenticated");
      return false;
    }
  }

  isAdmin(): Promise<boolean> {
    return this.userService.checkIsAdmin().then((data: boolean) => data);
  }

  isOwner(): Promise<boolean> {
    return this.userService.checkIsOwner().then((data: boolean) => data);
  }

  generateStateParameter(): string {
    var state = this.generateRandomString();
    sessionStorage.setItem("state", JSON.stringify(state));
    this.state = state;
    return state;
  }

  generateNonceParameter(): string {
    var nonce = this.generateRandomString();
    sessionStorage.setItem("nonce", JSON.stringify(nonce));
    this.nonce = nonce;
    return nonce;
  }

  generateRandomString(length = 6): string {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

}
