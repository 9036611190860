import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/_services/analytics.service';

import Chart from 'chart.js/auto';

const { DateTime } = require("luxon");

@Component({
  selector: 'app-analytics-durations',
  templateUrl: './analytics-durations.component.html',
  styleUrls: ['./analytics-durations.component.scss']
})
export class AnalyticsDurationsComponent implements OnInit {
  
  public parkingId: string;
  
  // CHART declarations
  public dailyChartDurations: Chart;
  public ctxDaily: any = [];
  public dataset: any = [[], [], [], [], []];
  public database: any = [];
  public count1: any = 0;
  public count2: any = 0;
  public count3: any = 0;
  public count4: any = 0;
  public count5: any = 0;
  public count6: any = 0;
  
  // DatePicker
  @Input() myGroup: UntypedFormGroup;
  @Input() minDate = new Date();
  @Input() maxDate = new Date();
  public maxCheckDate = new Date();
  public yesterday = new Date();
  
  // API variables
  public dailyDataDurationsObj: any = {};
  
  // Variable declarations
  public mapLoaded = true;
  public showError:boolean = false;
  
  //Select Parking / Group
  @Input() lotsGroups: any;
  @Input() parkings: any;
  groupList: any = [];
  parkingList: any = [];
  
  constructor(
    private analytics: AnalyticsService,
    private route: ActivatedRoute) { }
  
  async ngOnInit() {
    this.createChart();
    this.yesterday.setFullYear(this.myGroup.get("date").value.getFullYear(), this.myGroup.get("date").value.getMonth(), this.myGroup.get("date").value.getDate() - 1);
    this.dataComputation(this.yesterday, this.yesterday, '');
    
    this.maxCheckDate = this.maxDate;
    
    this.myGroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(change  => {
      if(change.date !== null && change.endDate !== null) {
        if(change.date === this.maxCheckDate) {
          this.dataComputation(this.yesterday, this.yesterday, change.selected)
        } else {
          this.dataComputation(change.date, change.endDate, change.selected);
        }
      }
    });
  }
  
  createChart() {
    // Create chart DAILY DURATIONS
    this.ctxDaily = document.getElementById("dailyDurations");
    this.dailyChartDurations = new Chart("dailyDurations", {
      type: "bar",
      data: {
        labels: ["12am-4am","4am-8am","8am-12pm","12pm-4pm","4pm-8pm","8pm-12am"],
        datasets: [
          {
            label: "0-30min",
            backgroundColor: "#005691",
            borderColor: "#005691",
            data: this.database[0]
          },
          {
            label: "30min-3hrs",
            backgroundColor: "#76b81b",
            borderColor: "#005691",
            data: this.database[1]
          },
          {
            label: "3hrs-9hrs",
            backgroundColor: "#ffcc00",
            borderColor: "#005691",
            data: this.database[2]
          },
          {
            label: "9hrs-24hrs",
            backgroundColor: "#12eaea",
            borderColor: "#005691",
            data: this.database[3]
          },
          {
            label: "24hrs+",
            backgroundColor: "#e83b1e",
            borderColor: "#005691",
            data: this.database[4]
          },
        ],
      },
      options: {
        
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: 
            {
            stacked: true,
            },
          y: 
            {
              stacked: true,
              title: {
                display: true,
                text: "Number of Cars",
              },
            }
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
          },
        }
      },
    });
    
    this.mapLoaded = true;
  }
  
  async dataComputation(genericDate: Date, endDate: Date, selected: any) {
    // DAY COMPUTATION CHART
    this.computeChart([]);
    this.mapLoaded = false;
    let offset = (DateTime.fromJSDate(this.myGroup.get("date").value).setZone('Europe/Rome').offset)/60;
    
    var startDayDate = DateTime.fromISO(genericDate.toISOString()).setZone('Europe/Rome').plus({days: 1}).toUTC().startOf('day').toISO();
    var endDayDate = DateTime.fromISO(endDate.toISOString()).setZone('Europe/Rome').plus({days: 2}).toUTC().startOf('day').toISO();
    
    if(this.parkings !== undefined) {
      this.groupList = [];
      for (let i=0; i<this.parkings.length; i++) {
        this.groupList.push(this.parkings[i].id)
      }
    }
    
    if(this.lotsGroups !== undefined) {
      this.parkingList = []
      for(let i=0; i<this.lotsGroups.length; i++) {
        this.parkingList.push(this.lotsGroups[i].name)
      }
    }
    
    this.parkingId = this.route.snapshot.params.id;
    
    if(this.parkingId !== undefined) {
      await this.analytics.getDailyDuration(this.parkingId, endDayDate, startDayDate).then((x: any) => {
        if(x.body.durations.length === 0) {
          this.showError = true;
          this.dailyDataDurationsObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataDurationsObj = x.body;
        }
      });
    } else if (this.groupList.indexOf(selected) !== -1) {
      this.parkingId = selected;
      await this.analytics.getDailyDuration(this.parkingId, endDayDate, startDayDate).then((x: any) => {
        if(x.body.durations.length === 0) {
          this.showError = true;
          this.dailyDataDurationsObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataDurationsObj = x.body;
        }
      });
    } else if (this.parkingList.indexOf(selected) !== -1 ){
      let groupId = '';
      for (let i = 0; i<this.lotsGroups.length; i++) {
        if(this.lotsGroups[i].name == selected) {
          groupId = this.lotsGroups[i].id
        }
      }
      await this.analytics.getDailyDurationGroup(startDayDate, endDayDate, groupId).then((x: any) => {
        if(x.body.durations.length === 0) {
          this.showError = true;
          this.dailyDataDurationsObj = x.body;
        } else {
          this.showError = false;
          this.dailyDataDurationsObj = x.body;
        }
      });
    }
    
    this.dataset = [[], [], [], [], []];
    for (let j=0; j < this.dailyDataDurationsObj?.durations?.length; j++) {
        if (this.dailyDataDurationsObj.durations[j].minutes < 30) {
          this.dataset[0].push(this.dailyDataDurationsObj.durations[j]);
        }
        else if (this.dailyDataDurationsObj.durations[j].minutes >= 30 && this.dailyDataDurationsObj.durations[j].minutes < 180) {
          this.dataset[1].push(this.dailyDataDurationsObj.durations[j]);
        }
        else if (this.dailyDataDurationsObj.durations[j].minutes >= 180 && this.dailyDataDurationsObj.durations[j].minutes < 540) {
          this.dataset[2].push(this.dailyDataDurationsObj.durations[j]);
        }
        else if (this.dailyDataDurationsObj.durations[j].minutes >= 540 && this.dailyDataDurationsObj.durations[j].minutes < 1440) {
          this.dataset[3].push(this.dailyDataDurationsObj.durations[j]);
        }
        else if (this.dailyDataDurationsObj.durations[j].minutes >= 1440) {
          this.dataset[4].push(this.dailyDataDurationsObj.durations[j]);
      }
    }
    
    this.database = [];
    for (let i=0; i < this.dataset.length; i++) {
      this.count1 = 0;
      this.count2 = 0;
      this.count3 = 0;
      this.count4 = 0;
      this.count5 = 0;
      this.count6 = 0;
      for (let j=0; j < this.dataset[i].length; j++) {
        if(DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 4) {
          this.count1++;
        }
        else if(DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour >= 4 && DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 8) {
          this.count2++;
        }
        else if (DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour >= 8 && DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 12) {
          this.count3++;
        }
        else if (DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour >= 12 && DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 16) {
          this.count4++;
        }
        else if (DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour >= 16 && DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 20) {
          this.count5++;
        }
        else if (DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour >= 20 && DateTime.fromISO(this.dataset[i][j].start).plus({hours: offset}).toUTC().toObject().hour < 24) {
          this.count6++;
        }
        this.database[i]=[this.count1, this.count2, this.count3, this.count4, this.count5, this.count6];
      }
    }
    
    console.log("START DAY DURATION ", startDayDate)
    console.log("END DAY DURATION ", endDayDate)
    
    // UPDATE CHART
    this.computeChart(this.database)
    
  }
  
  computeChart(database) {
    // DAY CHART UPDATE
    this.dailyChartDurations.data.datasets[0].data = database[0];
    this.dailyChartDurations.data.datasets[1].data = database[1];
    this.dailyChartDurations.data.datasets[2].data = database[2];
    this.dailyChartDurations.data.datasets[3].data = database[3];
    this.dailyChartDurations.data.datasets[4].data = database[4];
    
    this.dailyChartDurations.update();
    
    this.mapLoaded = true;
  }
  
}
