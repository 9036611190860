import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { BreadcrumbsModule, RbUiComponentsModule, FormFieldsModule, ModalModule, TabPanelModule } from '@inst-iot/bosch-angular-ui-components';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SecurePipe } from './_helpers/secure.pipe';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LoginComponent,
    AuthenticationComponent,
    SecurePipe
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    BreadcrumbsModule.forRoot(),
    RbUiComponentsModule,
    FormFieldsModule,
    TabPanelModule,
    ModalModule,
    FormsModule
  ]
})
export class AuthenticationModule { }
