import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardGuard as AuthGuard } from '../authentication/_guards/auth-guard.guard'

import { LayoutComponent } from '../_shared/components/layout/layout.component';
import { HomeComponent } from './home/home.component';
import { AnalyticsComponent } from '../_shared/components/analytics-mgmt/analytics/analytics.component';
import { PrivacyPolicyComponent } from '../_shared/components/privacy-policy/privacy-policy.component';
import { PageNotFoundComponent } from '../_shared/components/page-not-found/page-not-found.component';
import { OssLicensesComponent } from '../_shared/components/oss-licenses/oss-licenses.component';

const routes: Routes = [

  {
    path: '', component: LayoutComponent, data: {title: 'Home'},
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full'},
      { path: 'home', component: HomeComponent, data: {title: ''}},
      { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard]},
      { path: 'privacy-policy', component: PrivacyPolicyComponent},
      { path: 'OSS-licenses', component: OssLicensesComponent},
      { path: '', loadChildren: () => import('../branch-mgmt/branch-mgmt.module').then(m => m.BranchMgmtModule),data: {title: ''}},
      { path: '', loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule),data: {title: ''}},
      { path: '', loadChildren: () => import('../parking-mgmt/parking-mgmt.module').then(m => m.ParkingMgmtModule),data: {title: ''}},
      { path: '', loadChildren: () => import('../user-mgmt/user-mgmt.module').then(m => m.UserMgmtModule),data: {title: ''} },
      {path: '**', pathMatch: 'full', component: PageNotFoundComponent },
    ]
  }

];



@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
