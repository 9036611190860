import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { Feature, View } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { Cluster, Vector } from 'ol/source';
import { Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import Map from 'ol/Map';
import * as olExtent from "ol/extent";
import ZoomToExtent from 'ol/control/ZoomToExtent';

import { ImagesService } from 'src/app/_services/images.service';
import { StyleService } from 'src/app/_services/style.service';

@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.scss']
})
export class MapDisplayComponent implements OnInit {
  
  @Input() display;
  
  id: string;
  section: string = "";
  parkImagesId: string;
  parsubs: Subscription;
  mapLoaded:boolean = false;
  imagesInfo: any;
  extent: any;
  features: any;
  vectorSource: VectorSource = new Vector({});
  vectorLayer: VectorLayer;
  map: Map;
  x: number;
  y: number;
  center: any;
  
  constructor(
    private route: ActivatedRoute,
    public imagesService: ImagesService,
    public style: StyleService,
  ) {
    this.features = [];
  }
  
  async ngOnChanges() {
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par:any) => {
      this.section = par.section;
      this.parkImagesId = this.id + '-' + this.section;
    });
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(info => {
      this.imagesInfo = info as any;
      this.x = this.display.x
      this.y = this.display.y
    });
    
    var extentHeight = this.imagesInfo.y_multiplier;
    this.center = [this.x, (extentHeight - this.y)];
    
    if(!this.vectorSource.isEmpty()) {
      this.features = [];
      this.vectorSource.clear();
      const pos = [this.x, (extentHeight - this.y)];
      const singleFeature = new Feature({
        geometry: new Point(pos),
      });
      this.features.push(singleFeature);
      this.vectorSource.addFeatures(this.features);
      
      //Set Map Center Dynamically
      this.map.getView().setCenter(this.center);
    }  
  }
  
  async ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.parsubs = this.route.queryParams.subscribe((par:any) => {
      this.section = par.section;
      this.parkImagesId = this.id + '-' + this.section;
    });
    
    await this.imagesService.retriveImagesInfo(this.parkImagesId).then(info => {
      this.imagesInfo = info as any;
      this.x = this.display.x
      this.y = this.display.y
    });
    
    var extentHeight = this.imagesInfo.y_multiplier;
    var extentWidth = this.imagesInfo.x_multiplier;
    this.extent = [0, 0, extentWidth, extentHeight];
    var projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: this.extent,
    });
    //Setup a Vector Source
    this.vectorSource = new VectorSource({
      features: [],
    });
    
    //Setup a Vector Layer
    this.vectorLayer = new VectorLayer({
      source: new Cluster({
        distance: 50,
        source: this.vectorSource,
      }),
      style: this.styleObjectDisplay,
    });
    
    var sourceStaticImage = new Static({
      imageLoadFunction: (function (image, src) {
        this.imagesService.retriveImages(src)
          .subscribe(data => {
            let reader = new FileReader();
            reader.addEventListener("loadend", () => {
              image.getImage().src = reader.result;
            }, false);
            if (data) {
              reader.readAsDataURL(data);
            }
          }, error => {
            console.log(error);
          });
      }).bind(this),
      url: this.parkImagesId,
      projection: projection,
      imageExtent: this.extent
    })
    
    this.map = new Map({
      layers: [
        new ImageLayer({
          source: sourceStaticImage
        }),
      ],
      target: "map-layer",
      view: new View({
        projection: projection,
        center: [this.x, (extentHeight - this.y)],
        maxZoom: 6,
        zoom: 2,
        extent: [-(0.1*extentWidth), -(0.1*extentHeight), extentWidth+(0.1*extentWidth), extentHeight+(0.1*extentHeight)],
        showFullExtent: true
      })
    });
    
    // Function to compute dynamically the height of the map
    this.style.computeHeightList();
    this.map.updateSize();
    
    // Zoom Control Button
    var zoom = document.createElement("span");
    zoom.innerHTML = '<span class="rb-ic rb-ic-arrows-expand-object"></span>';
    var zoomControl = new ZoomToExtent({
      extent: [0, 0, extentWidth, extentHeight],
      label: zoom,
    });
    
    this.map.addControl(zoomControl);
    this.map.once("rendercomplete", (event) => {
      this.mapLoaded = true;
      this.map.addLayer(this.vectorLayer);
    });
    
    this.features = [];
    
    const pos = [this.x, (extentHeight - this.y)];
    const singleFeature = new Feature({
      geometry: new Point(pos),
    });
    this.features.push(singleFeature);
    this.vectorSource.addFeatures(this.features);
  }
  
  styleObjectDisplay = (display) => {
    
    var displayColor = '#BFC0C2';
    var displayStrokeColor = '#000000';
    var strokeWidth = 6;
    var colorText = "#FFFFFF";
    var colorTextStroke = "#FFFFFF";
    var font = (this.map.getView().getZoom()*12).toString() + "px 'Codystar', cursive";
    
    if(this.display.status == "ok") {
      displayColor = '#70BF54';
    } else if (this.display.status == "alert") {
      displayColor = '#FCAF17';
    } else {
      displayColor = '#BFC0C2';
    }
    
    //Text fill style
    var textFill = new Fill({
      color: colorText
    });
    
    //Text stroke style
    var textStroke = new Stroke({
      color: colorTextStroke,
      width: 1,
    });
    
    var textDisplayCode = new Text({
      font: font,
      text: this.display.code,
      fill: textFill,
      stroke: textStroke,
    });
    
    var iconStyleDisplay = new Style({
      image: new RegularShape({
        fill: new Fill({
          color: displayColor,
        }),
        stroke: new Stroke({
          color: displayStrokeColor,
          width: strokeWidth,
        }),
        radius: this.map.getView().getZoom() * (20 / Math.SQRT2),
        radius2: this.map.getView().getZoom() * 20,
        points: 4,
        angle: 0,
        scale: [1, 0.5],
      }),
      text: textDisplayCode,
    });
    return iconStyleDisplay;
    
  }
  
}