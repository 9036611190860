import { Component, HostListener, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import 'ol/ol.css';
import { Collection, Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';

import { Parking } from 'src/app/_shared/models/parking';
import { DisplayGetModel } from 'src/app/_shared/models/display';

import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ParkingService } from 'src/app/_services/parking.service';
import { DisplaysService } from 'src/app/_services/displays.service';
import { UserService } from 'src/app/_services/user.service';
import { StyleService } from 'src/app/_services/style.service';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})

export class ParkingComponent implements OnInit, AfterViewInit {
  @ViewChild('parkingdrawer', {static: false}) parkingdrawer: any;
  isExpanded: boolean;
  
  id: string;
  parking: Parking;
  sections: any[];
  areas: any[];
  view: string;
  sec: any;
  params: any;
  userRole: any;
  parkingSubscription: Subscription;
  allDisplays: string[] = [];
  
  //Variables to enable group management functions
  displaybutton: boolean = true;
  displayselector: boolean = false;
  
  
  selectedFeatures: Collection<Feature<Geometry>>;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private style: StyleService,
    private park: ParkingService,
    public displayService: DisplaysService,
    public userService: UserService,
    public auth: AuthenticationService) {
    this.parking = new Parking();
  }
  
  ngOnDestroy() {
    this.parkingSubscription.unsubscribe();
  }
  
  ngAfterViewInit() {
    if(window.innerWidth < 1024) {
      this.parkingdrawer.mode = 'over';
      this.parkingdrawer.disableClose = false;
      this.parkingdrawer.opened = false;
      this.isExpanded = true;
    } else {
      this.parkingdrawer.mode = 'side';
      this.parkingdrawer.disableClose = true;
      this.parkingdrawer.opened = true;
      this.isExpanded = false;
    }
  }
  
  public async ngOnInit() {
    this.parkingSubscription = this.park.currentParkingId.subscribe(parkId => this.id = parkId);
    this.id = this.route.snapshot.params.id;
    this.park.getParkingId(this.id);
    
    await this.userService.userCanEditByParkingID(this.id).then(
      (data) => {
        this.userRole = data['role'];
      },
      () => {
        this.userRole = null;
      }
    )
    
    await this.park.retriveParking(this.id);
    this.park.parking.subscribe(datapark => {
      this.parking = datapark;
      this.sections = this.parking.section;
      this.sections = this.sections.sort((a, b) => a.name - b.name);
    });
    
    this.style.computeMinHeight();
    
    this.route.queryParams.subscribe(par => {
      this.params = par;
      /* if (this.params.section == null && this.params.view !== 'floors' && this.params.view !== 'analytics' && this.params.view !== 'sensor-status') {
        this.router.navigate([], { queryParams: { section: this.sections[0].name }, queryParamsHandling: 'merge' });
      }
      if (this.params.view == null) {
        this.router.navigate([], { queryParams: { view: "map" }, queryParamsHandling: 'merge' });
      } */
      this.view = this.params.view;
      if(this.params.section == undefined) {
        this.sec = this.sections[0].name
      } else {
        this.sec = this.params.section;
      }
      setTimeout(()=>{this.style.computeMinHeight()},500);
    });
    
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        window.location.reload();
      }
    });

    this.parking.section.forEach((value)=>{
      value.display_id.forEach((displayId)=>{
        this.allDisplays.push(displayId);
      })
    })
    
  }
  
  show(): any {
    this.route.queryParams.subscribe(par => {
      return par;
    });
  }
  
  go(name) {
    const currentRoute = "/parking/" + this.parking.id;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'list', section: name }, queryParamsHandling: 'merge'}); // navigate to same route
    });
  }
  
  goDisplay(name) {
    const currentRoute = "/parking/" + this.parking.id;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'display-list', section: name }, queryParamsHandling: 'merge'}); // navigate to same route
    });
  }
  
  reloadMap(name) {
    const currentRoute = "/parking/" + this.parking.id;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'map', section: name }}); // navigate to same route
    });
  }
  
  reloadHeatmap(name) {
    const currentRoute = "/parking/" + this.parking.id;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'heatmap', section: name }, queryParamsHandling: 'merge'}); // navigate to same route
    });
  }
  
  loadEdit(name) {
    const currentRoute = "/parking/" + this.parking.id;
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute], { queryParams: { view: 'edit', section: name }, queryParamsHandling: 'merge'}); // navigate to same route
    });
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if(window.innerWidth < 1024) {
      this.parkingdrawer.mode = 'over';
      this.parkingdrawer.disableClose = false;
      this.parkingdrawer.opened = false;
      this.isExpanded = true;
    } else {
      this.parkingdrawer.mode = 'side';
      this.parkingdrawer.disableClose = true;
      this.parkingdrawer.opened = true;
      this.isExpanded = false;
    }
  }
  
  //Manage Parking Space Groups: enable functions in the sidenav
  displaygroupsmenu() {
    this.displaybutton = true;
    this.displayselector = false;
  }
  
  addItem(newItem: any) {
    this.selectedFeatures = newItem;
  }
}